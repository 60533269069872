.zauber
  color $black
  background #F6DC79 url('../img/zauber/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #E89947
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #E89947
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #E89947
  .portfolio-case-link
    color: #E89947
    stroke: #E89947
  .portfolio-image-wrap
    position relative
    flex(row, nowrap, center, center)
    height 100%
    padding 0 24px
    img
      width auto
@media (min-width: 550px)
  .zauber
    padding-right 75px
    background $white url('../img/zauber/bg.png') top center\/cover no-repeat
    .portfolio-image-wrap
      flex(row, nowrap, flex-start, center)
