.katris
  color #232323
  background #FFF9F1 url('../img/katris/bg.png') no-repeat top center\/cover
  h2:hover
    cursor pointer
    color #92B7C9
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px #92B7C9
  .portfolio-page-about
    max-width 700px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #5A5A5A
  .portfolio-page-link
    background-color #92B7C9
  .portfolio-case-link
    color: #92B7C9
    stroke: #92B7C9
  .portfolio-image-wrap
    flex(row, nowrap, center, flex-end)
    img
      display block
      width 100%
@media (max-width 550px)
  .katris
    background-color: #FFF9F1
    .portfolio-image-wrap
      flex(row, nowrap, center, center)
      position relative
      height 100%
      img
        display block
        width 100%
        box-shadow 32px 32px 47px rgba(0, 0, 0, 0.25);
