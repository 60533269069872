.detail-winfood
  padding-bottom 150px
  color #332C2C
  background $white
  height initial
  .detail-image-wrap
    padding-top 50px
    position relative
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #77b23d
  .detail-page-about,
  .detail-page-table td
    color #494949
  .detail-page-link
    background-color #77b23d
    color $white
    transition all .2s ease-out
    &:hover
      background-color: #7d9d30
  .detail-styles-container
    width 100%
    max-width 700px
  .detail-row
    flex()
  .detail-styles-colors
    flex(row, wrap, space-between, center)
    width 100%
    max-width 350px
    padding-top 40px
    margin: 0 -15px
  .styles-colors-item
    flex(column, nowrap, space-between, center)
    height 120px
    margin 0 15px 25px
    div
      width 84px
      height 84px
      border-radius 50%
    span
      color #332c2c
      fonts(18px, 27px)
  .detail-styles-ui
    position relative
    width 100%
    flex(row, nowrap, space-between, center)
    padding-top 20px
  .detail-ui-font
    padding-top 40px
    padding-left 40px
  .detail-font-example
    display block
    margin-bottom 20px
  .detail-font-name
    display block
    font-family: 'Circle', 'Arial', sans-serif
    fonts(47px, 1.1, bold)
    color: #03193E
  .detail-font-name-2
    display block
    fonts(30px, 1.1, bold)
  .detail-font-name-3
    display block
    fonts(16px, 1.1, normal)
  .detail-ui-kit
    width 55%
    padding-top 50px
  .detail-ui-buttons,
  .detail-ui-links,
  .detail-ui-checkboxes
    flex(column, wrap, space-between)
  .detail-ui-buttons
    min-width 400px
  .row
    flex(row, nowrap, space-between, center);
  .period-column
    flex(column);
    width: 40%;
    .row
      justify-content: space-around;
  .period-header
    margin-bottom: 20px;
    fonts(20px, 24px, 900);
  .period-label
    cursor: pointer;
    position: relative;
    // width: 100%;
    margin: 10px 20px 10px 0;
    padding-left: 55px;
    fonts(16px, 19px);
    &:before
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -3px;
      left: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 3px solid $yellow;
  .period-radio
    position: absolute;
    left: -9999px;
    visibility: hidden;
    &:checked + label:after
      content: '';
      position: absolute;
      top: 6px;
      left: 9px;
      background: #ff4c00;
      border-radius: 50%;
      width: 7px;
      height: 7px;
  .period-price
    display: block;
    flex-shrink: 0;
    width: 120px;
    fonts(16px, 19px, 700)
.kcal-btn
  margin-top 20px
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 60px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  letter-spacing: 0.035em;
  text-decoration: none;
  color: #03193e;
  border-radius: 30px;
  border 3px solid #ffc803
  transition: all 0.2s ease-out;
.kcal-btn.active
  background: #ffc803

.winfood-colors-1
  background #FFC803
.winfood-colors-2
  background #77B23D
.winfood-colors-3
  background #03193E
.winfood-colors-4
  background #FF4C00
.winfood-colors-5
  background #2C6DD0
.winfood-colors-6
  background #05B286

@media (max-width 1400px)
  .detail-winfood
    padding-left 60px
    padding-right 60px
    .detail-font-example
      font-size 132px
    .detail-styles-ui
      min-width 520px
    .detail-ui-buttons
      justify-content center
      min-width 100px
    .detail-ui-links
      width 360px
      position absolute
      top 105%
      left 0
    .detail-ui-checkboxes
      width 360px
      position absolute
      top 125%
      left 0
