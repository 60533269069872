/* Variables */
$gray = #717171
$l-gray = #d4d4d4
$red = #fe0000
$white = #ffffff
$black = #332C2C

$dark = #2c2c3e
$violet = #7c79e5
$purple = #522762

$d-violet = #3b36bd
$yellow = #ffe500

$lamp-bg = #eaf1f9
$tourskazka-bg = #2c2d37
$nabiss = #8CAF3B

/* Mixins */

flex($dir = row, $wrap = wrap, $justify = flex-start, $align = flex-start)
	display: flex
	flex-flow: $dir $wrap
	justify-content: $justify
	align-items: $align

fonts($size = 14px, $height = 19px, $weight = 400)
	font-size: $size
	line-height: $height
	font-weight: $weight
