.detail-zauber
  padding-bottom 150px
  color $black
  .detail-image-wrap
    text-align center
    img
      width auto
      object-fit: cover
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #E89947
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #E89947
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#E89947, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: linear-gradient(172.14deg, rgba(246, 220, 121, 0.2) 18.43%, rgba(255, 255, 255, 0.2) 87.66%);
    .col
      display block
  .result-header
    color $black
  .result-text
    color #E89947
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 180px
