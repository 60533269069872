.zerkala
  padding-right 0
  color $white
  background #020202
  grid-template-columns 690px 1fr
  h2:hover
    cursor pointer
    color #009DE0
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #009DE0
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #009DE0
  .portfolio-case-link
    color: #009DE0
    stroke: #009DE0
  .portfolio-content-wrap
    max-width: 695px
  .portfolio-image-wrap
    flex(row, nowrap, flex-end, flex-end)
    width 100%
    padding-top 0
    img
      display block
      width 100%
