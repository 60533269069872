.atrium
  color $black
  background url('../img/atrium/bg-m.png') top center\/contain no-repeat, linear-gradient(280.46deg, rgba(0, 0, 0, 0.22) 9.17%, rgba(255, 255, 255, 0) 95.7%);
  h2:hover
    cursor pointer
    color #EE362A
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #EE362A
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #EE362A
  .portfolio-case-link
    color: #EE362A
    stroke: #EE362A
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width 100%
@media (min-width: 550px)
  .atrium
    padding-right 80px
    background #C4C4C4 url('../img/atrium/bg.png') top center\/cover no-repeat
