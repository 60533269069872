.detail-womendress
  padding-bottom 150px
  color $white
  background: #E8A0A3
  .detail-image-wrap
    padding-top 100px
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #C12A7B
  .detail-page-about,
  .detail-page-table td
    color $white
  .detail-page-link
    background-color #C12A7B
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#C12A7B, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
