.detail-electric
  padding-bottom 150px
  color $black
  background: url('../img/electric/electric-bg-big.png') no-repeat top right
  .detail-image-wrap
    padding-top 100px
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #FF4C00
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #FF4C00
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#FF4C00, 0.8)
  .detail-styles
    color $white
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
