.alfaplan
  color $black
  background url('../img/alfaplan-bg.png') no-repeat 0 0\/auto 100%
  h2:hover
    cursor pointer
    color #C70202
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px #C70202
  .portfolio-page-about
    max-width 530px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #6a6769
  .portfolio-page-link
    background-color #C70202
  .portfolio-case-link
    color: #C70202
    stroke: #C70202
  .portfolio-image-wrap
    top 5%
@media (min-width 550px)
  .alfaplan
    background url('../img/alfaplan-bg.png') no-repeat -50px 0/auto 100%
@media (min-width 960px)
  .alfaplan
    background: url(../img/alfaplan-bg.png) no-repeat 0 0\/auto 100%;
@media (min-width 1100px)
  .alfaplan
    background: url(../img/alfaplan-bg.png) no-repeat 0 0/100% 100%;
