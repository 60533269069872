.nabiss
  color #332C2C
  background $white
  h2:hover
    cursor pointer
    color $nabiss
  .tags-item
    a, span
      box-shadow 0 2px 0 0px $nabiss
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color $nabiss
  .portfolio-case-link
    color: $nabiss
    stroke: $nabiss
  .portfolio-image-wrap
    top 10%
    left -7%
    width 100%
@media (min-width 960px)
  .nabiss
    background $white url('../img/nabiss.png') right 0 no-repeat
