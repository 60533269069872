.detail-butik
  padding-bottom 150px
  color $white
  background #0f0f0f url('../img/butik/butik-bg-big.png') no-repeat top center\/contain
  height initial
  .detail-image-wrap
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #707070
  .detail-page-about,
  .detail-page-table td
    color $white
  .detail-page-link
    background-color $white
    color $black
    transition all .2s ease-out
    &:hover
      background-color: rgba($white, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
  .result-block
    background-color $white
  .result-header
    color $black
  .result-text
    color #8C8C8C
    strong
      color $black
