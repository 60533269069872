.setinik
  color $black
  background $white
  h2:hover
    cursor pointer
    color #D61903
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #D61903
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #D61903
  .portfolio-case-link
    color: #D61903
    stroke: #D61903
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .setinik
    padding-right 100px
    background $white url('../img/setinik/bg.png') top center\/cover no-repeat
