.detail-page.detail-restochef
  padding-bottom 100px
.detail-restochef
  color #332C2C
  background $white
  height initial
  .detail-image-wrap
    padding-top 120px
    text-align center
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #C1A0CD
  .detail-page-about,
  .detail-page-table td
    color #A4A4A4
  .detail-page-link
    background-color #C1A0CD
    color $white
    transition .2s all ease-out
    &:hover
      background-color #A180AD
  .detail-font
    color #522762
    fonts(125px, 144px, bold)
  .detail-styles
    max-width 740px
  .detail-styles-colors
    flex(row, nowrap, space-between, center)
    padding-top 40px
  .styles-colors-item
    flex(column, nowrap, space-between, center)
    height 145px
    & > div
      flex(row, nowrap, center, center)
      width 88px
      height 88px
      border: 1.5px solid #CCCCCC
      border-radius 50%
      div
        width 53px
        height 53px
        border-radius 50%
        transition all .15s ease
      &:hover
        & div
          width 84px
          height 84px
    span
      color #a4a4a4
  .detail-styles-ui
    width 100%
    padding 40px 0
    flex(row, nowrap, space-between, center)
  .detail-ui-forms
    width 100%
    max-width: 290px
  .detail-ui-forms + .catalog-filter
    margin-left 20px
  .catalog-filter
    max-width 320px
    width 100%
    margin-bottom 40px
    padding 34px
    border-radius 30px
    box-shadow 0px 4px 20px rgba(0, 0, 0, 0.15)
  .filter-item-title
    align-self: flex-start
    position: relative
    display: inline-block
    padding-right: 12px
    margin-bottom: 6px
    fonts(14px, 21px, 600)
    cursor: pointer
    &.hidden
      &::after
        transform: translateY(-50%) rotate(-90deg)
      & + .filter-item
        display: none
    &::after
      content: ''
      position: absolute
      top: 50%
      right: 0
      z-index: 1
      transform: translateY(-50%)
      display: block
      width: 6px
      height: 3px
      background: url('./../img/filter-arrow.svg') 50% 50%/6px 3px no-repeat
    &:nth-of-type(n+2)
      margin-top: 15px
    &:hover
      text-decoration: underline
  .filter-checkbox-wrap
    margin-top: 10px
  .filter-item
    &.column-block
      flex(column, nowrap)
      .site-checkbox-label
        display: block
    &.scrollable
      max-height: 175px

  .noUi-range-handle
    position: absolute
    left: 15px
    top: -8px
    width: 15px
    height: 15px
    border-radius: 50%
    outline: none
    cursor: pointer
    background-color: $white
    box-shadow: 2px 2px 7px rgba(82, 39, 98, 0.7)
  .noUi-range-target
    position: relative
    width: 100%
    height: 1px
    margin: 40px 0 30px
    background-color: $purple
  .range-min-output, .range-max-output
    position: absolute
    top: -30px
    width: 30px
    margin-left: -20px
    color: $black
    fonts(12px, 18px, 300)
  .range-max-output
    margin-left: -25px

  .site-checkbox
    display: none
    &:checked + label
      color: $purple
      &::after
        opacity: 1
  .site-checkbox-label
    position: relative
    display: inline-block
    padding: 7px 10px 7px 26px
    color: #ababab
    fonts(14px, 21px)
    cursor: pointer
    &::before
      content: ''
      position: absolute
      top: 50%
      left: 0
      z-index: 1
      transform: translateY(-50%)
      display: block
      width: 15px
      height: 15px
      border: 1px solid $purple
      border-radius: 2px
    &::after
      content: ''
      position: absolute
      top: 50%
      left: 0
      z-index: 2
      transform: translateY(-50%)
      display: block
      width: 15px
      height: 15px
      border-radius: 2px
      background: url('./../img/checkbox-checked.svg') 50% 50%/ 8px 6px no-repeat
      opacity: 0
    &:hover::after
      opacity: 0.3

  .site-button
    display: inline-block
    padding: 10px 30px
    border: none
    outline: none
    border-radius: 24px
    background-color: #2d2d2d
    color: $white
    fonts(14px, 21px)
    letter-spacing 0.05em
    text-decoration: none
    transition: all 0.3s ease
    &:hover
      background-color: $black
      cursor: pointer
    &.reverse
      padding: 9px 30px
      border: 1px solid $black
      background-color: transparent
      color: $black
      &:hover
        background-color: $black
        color: $white
  .popup-body
    position: relative
    max-width: 370px
    min-width: 300px
    margin-left 25px
    padding: 40px 25px
    border-radius: 29px
    background: $white
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)
    line-height: 23px
    text-align: center
    p
      fonts(16px, 23px)
    .site-button
      fonts(14px, 21px)
      &:nth-of-type(n+3)
        margin-left: 25px
  .popup-close
    position: absolute
    top: 15px
    right: 24px
    z-index: 2
    padding-right: 11px
    border: none
    outline: none
    background: none
    box-shadow: none
    fonts(11px, 16px)
    text-align: right
    cursor: pointer
    &::before, &::after
      content: ''
      position: absolute
      top: 50%
      right: 0
      z-index: 1
      display: block
      width: 7px
      height: 1px
      background: $dark
    &::before
      transform: translateY(-50%) rotate(-45deg)
    &::after
      transform: translateY(-50%) rotate(45deg)
  .site-button
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

.restochef-colors-purple div
  background-color #C1A0CD
.restochef-colors-purple-dark div
  background-color #522762
.restochef-colors-yellow div
  background-color #FFD12E
.restochef-colors-dark div
  background-color #060307
@media (max-width 1400px)
  .detail-restochef
    padding-left 60px
    padding-right 60px
    .detail-page-wrap
      grid-template-columns 0.8fr 1fr
@media (max-width 1200px)
  .detail-restochef
    .detail-page-wrap
      grid-template-columns repeat(1, 75%)
    .detail-styles-color
    .detail-styles-ui
      justify-content flex-start
@media (min-width 1600px)
  .detail-restochef
    .detail-styles
      padding-left 50px
    .detail-styles-colors
      max-width 80%
    .popup-body
      margin-left 40px
