.detail-domdacha
  padding-bottom 150px
  color $black
  .detail-image-wrap
    text-align center

    img
      width auto

      object-fit: cover
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #E2BA85
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #E2BA85
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#E2BA85, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: linear-gradient(180deg, #FCF7F0 0%, #FFFFFF 100%);
    .col
      display block
  .result-header
    color $black
  .result-text
    color #E2BA85
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 120px
