.terra
  color #332C2C
  background $white
  padding-right 44px
  h2:hover
    cursor pointer
    color #A11B1C
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #A11B1C
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #A11B1C
  .portfolio-case-link
    color: #A11B1C
    stroke: #A11B1C
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (max-width: 550px)
 .terra
   .portfolio-image-wrap
     position relative
     height 100%
     align-items center
     padding 0
