.tarasov
  color $black
  background $white
  max-height: none
  height auto
  h2:hover
    cursor pointer
    color #DB3C13
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #DB3C13
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #DB3C13
  .portfolio-case-link
    color: #DB3C13
    stroke: #DB3C13
  .portfolio-image-wrap
    position relative
    flex(row, nowrap, center, center)
    padding-top 50px
    picture
      width 100%
    img
      width auto
@media (min-width: 550px)
  .tarasov
    padding-right 10px
    .portfolio-image-wrap
      position relative
      flex(row, nowrap, flex-end, center)
      padding-top 0
      picture
        width auto
      img
        width auto
