.detail-massa
  padding-bottom 0
  color $black
  background: linear-gradient(282.07deg, rgba(0, 0, 0, 0.22) 9.17%, rgba(255, 255, 255, 0) 95.7%);
  .detail-image-wrap
    flex(row, nowrap, flex-end)
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 100px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #1A61AC
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #1A61AC
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#1A61AC, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .result-block
    background $white
  .result-header
    color $black
  .result-text
    strong
      color #1A61AC
  .seo-block
    padding-top 120px
    padding-bottom 180px
