.detail-cozythings
  padding-bottom 150px
  color $white
  background radial-gradient(131.1% 138.21% at 57.23% 31.11%, #C6C2C0 3.66%, #9C8F8A 26.05%, #695E5B 68.05%);
  height initial
  .detail-leaf-down
    color rgba($white, 0.7)
    &::after
      background url('../img/leaf-mouse-white.svg') 0 0 no-repeat
  .detail-image-wrap
    position relative
    text-align center
    img
      width auto
  .detail-page-wrap
    min-height 78vh
    grid-gap 0
  .detail-content-wrap
    max-width: 800px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #B5968C
  .detail-page-about,
  .detail-page-table td
    color $white
  .detail-page-about
    max-width: 600px
  .detail-page-link
    background-color #B5968C
    color $white
    transition all .2s ease-out
    &:hover
      background-color: #a5867C
  .detail-styles
    padding-left:calc(8.33333% + 40px);
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
@media (max-width 1400px)
  .detail-cozythings
    padding-left 60px
    padding-right 60px
