.womendress
  color $white
  background #EAA2A5 url('../img/womendress/bg-m.png') no-repeat bottom center\/contain
  h2:hover
    cursor pointer
    color #C12A7B
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #C12A7B
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #C12A7B
  .portfolio-case-link
    color: #C12A7B
    stroke: #C12A7B
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, flex-end)
    img
      width auto
      display none
@media (min-width: 550px)
  .womendress
    padding-right 50px
    background #EAA2A5
    .portfolio-image-wrap
      img
        display block
