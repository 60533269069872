.souz
  color $black
  background linear-gradient(239.68deg, #FFFFFF 0%, #D3D3D3 72.97%)
  h2:hover
    cursor pointer
    color #0083DE
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px #0083DE
  .portfolio-page-about
    max-width 750px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #626262
  .portfolio-page-link
    background-color #0083DE
  .portfolio-case-link
    color: #0083DE
    stroke: #0083DE
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
