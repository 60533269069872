.epiim
  color $white
  background url('../img/epiim/epiim-bg-m.png') top right\/contain no-repeat, linear-gradient(293.2deg, #143D5E 12.56%, #01223D 86.84%);
  h2:hover
    cursor pointer
    color #EFC149
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #EFC149
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #EFC149
  .portfolio-case-link
    color: #EFC149
    stroke: #EFC149
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (min-width: 550px)
  .epiim
    background url('../img/epiim/epiim-bg.png') top center\/cover no-repeat, linear-gradient(293.2deg, #143D5E 12.56%, #01223D 86.84%);
