.autojack
  color $black
  background linear-gradient(109.44deg, #FFFFFF 28.79%, #DDDDDD 51.17%, #A3A3A3 99.24%);
  h2:hover
    cursor pointer
    color #1A61AC
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #1A61AC
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #1A61AC
  .portfolio-case-link
    color: #1A61AC
    stroke: #1A61AC
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width auto
@media (min-width: 550px)
  .autojack
    padding-right 0
    background linear-gradient(147.9deg, #FFFFFF 40.29%, #DDDDDD 62.65%, #8F8F8F 82.35%);
    .portfolio-image-wrap
      position relative
      height 100%
      flex(row, nowrap, flex-end, flex-end)
