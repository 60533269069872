.greenmay
  color $black
  background: linear-gradient(180deg, #FFFEFD 0%, #FBF7E9 100%);
  height auto
  max-height: none
  h2:hover
    cursor pointer
    color #8A0C34
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #8A0C34
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #8A0C34
  .portfolio-case-link
    color: #8A0C34
    stroke: #8A0C34
  .portfolio-image-wrap
    position relative
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width 100%
@media (min-width: 550px)
  .greenmay
    background url('../img/greenmay/bg.png') no-repeat top center\/cover
    padding-right 0
    .portfolio-image-wrap
      img
        display none
