.detail-monolit
  padding-bottom 150px
  color $black
  background linear-gradient(90deg, #FFC91E 0%, #FFC91E 50%, #484848 50%);
  .detail-image-wrap
    padding-top 50px
    overflow visible
    margin-bottom -200px
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #FFFFFF
  .detail-page-wrap
    position relative
  .detail-page-about,
  .detail-page-table td
    color $black
  .detail-page-link
    background-color #484848
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#484848, 0.8)
  .detail-styles
    color $white
    padding-left 50px
  .detail-styles-container
    position relative
    z-index: 1
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
