.detail-royal
  color #332c2c
  background $white
  height initial
  padding-bottom 160px
  padding-right 0
  .detail-leaf-down
    color $black
    &::after
      background url('../img/leaf-mouse.svg') 0 0 no-repeat
  .detail-image-wrap
    padding-right 0
    text-align center
    img
      width 100%
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-top 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #1F4E8D
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #1F4E8D
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #0F3E7D
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 780px
    padding-right 80px
    padding-top 35px
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    max-width: 560px
    padding-bottom 80px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, center)
    color #A4A4A4
    fonts(14px, 16px, normal)
  .royal-color
    margin-bottom 33px

  .detail-ui-kit
    // padding-top 50px
    width 100%
    flex(row, nowrap, space-around, flex-start)
    max-width: 550px
  .ui-kit-col
    margin-right 5px
  .ui-kit-arrows
    flex(row, nowrap, space-between)
    width 120px
    padding-bottom 29px
  .ui-kit-dots
    flex(row, nowrap, space-between, flex-start)
    width 120px
    span
      width 17px
      height 17px
      background-color: #fbf4f4
      box-shadow 0px 0px 5px #FDE9D6,
                 inset 0px 4px 4px rgba(0, 0, 0, 0.25)
      border-radius 50%
    span.active
      background-color: #00B8CA
  .site-button
    cursor pointer
    position relative
    display block
    width 205px
    margin-bottom 19px
    padding 10px 0
    text-align center
    color $white
    font-family 'Arial', sans-serif
    text-decoration none
    fonts(14px, 16px, bold)
    background: #429288;
    box-sizing: border-box;
    text-shadow: 0.5px 1px 1px rgba(51, 51, 51, 0.8)
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), inset 0px -5px 10px rgba(0, 0, 0, 0.15)
  .site-button-blue
    background: linear-gradient(180deg, #00C0F7 0%, #00C2F9 0.01%, #00B9EF 35.42%, #018CB7 82.81%)
  .site-button-yellow
    background linear-gradient(180deg, #E7B15A 0%, #E8B25A 0.01%, #DBA755 35.42%, #AE7E3E 82.81%)
  .site-button-hover
    &::before
      content ''
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      background: rgba(28, 27, 73, 0.22)
  .ui-kit-search
    margin-top 38px
    display block
    width 205px
@media (max-width 1400px)
  .detail-royal
    padding-right 0
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
