.dsk
  color $black
  background #FCFFEA
  h2:hover
    cursor pointer
    color #FF0006
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FF0006
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #FF0006
  .portfolio-case-link
    color: #FF0006
    stroke: #FF0006
  .portfolio-image-wrap
    position relative
    width 100%
    height 100%
    flex(row, nowrap, center, cneter)
    picture
      width 100%
    img
      display block
      width 100%
@media (min-width: 550px)
  .dsk
    padding-right 50px
    background #FCFFEA url('../img/dsk/bg.png') top center\/cover no-repeat
    .portfolio-image-wrap
      flex(row, nowrap, flex-start, flex-end)
      img
        width auto
