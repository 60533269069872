.royal
  color #332C2C
  background $white url('../img/royal-tracery-1.png') no-repeat 0 0\/100% 100%
  h2:hover
    color #1F4E8D
  .tags-item
    a, span
      color inherit
      text-decoration: none
      box-shadow 0 2px 0 0px #00B8CA
  .portfolio-page-about
    max-width 750px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #5A5A5A
  .portfolio-page-link
    background-color #00B8CA
  .portfolio-case-link
    color: #00B8CA
    stroke: #00B8CA
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    width 100%
    max-height: 700px
  .portfolio-image-decor
    position absolute
    width 450px
    height auto
    z-index: -1
    right -150px
    top 100px
@media (max-width 550px)
  .royal
    background $white url('../img/royal-tracery-1.png') no-repeat -950px -50px
    .portfolio-image-wrap
      padding-top 50px
    .portfolio-image-decor
      display none
