.winfood
  color #332C2C
  background $white
  h2:hover
    cursor pointer
    color #77b23d
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #77b23d
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #77b23d
  .portfolio-case-link
    color: #77b23d
    stroke: #77b23d
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (min-width 960px)
  .winfood
    background $white url('../img/winfood/winfood-bg.png') center center\/cover no-repeat
    .portfolio-image-wrap
      top 0
      margin-bottom -100px
