.davydov
  color $white
  background #000000 radial-gradient(64.7% 56.48% at 53.14% 31.89%, #434242 0%, rgba(0, 0, 0, 0) 100%);
  h2:hover
    cursor pointer
    color #F4C142
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #F4C142
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #F4C142
  .portfolio-case-link
    color: #F4C142
    stroke: #F4C142
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (min-width 550px)
  .davydov
    background: radial-gradient(49.93% 121.42% at 85.21% 73.66%, #4D4D4D 0%, #000000 100%)
