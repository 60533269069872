.detail-konstruktiv
  color #332c2c
  background $white url('../img/konstruktiv/bg-big.png') no-repeat top center\/cover
  height initial
  padding-top 100px
  padding-bottom 160px
  padding-right 0
  .detail-image-wrap
    padding-bottom 50px
    img
      width auto
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-top 0px
    margin-bottom -40px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #154E9F
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #154E9F
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #053e8f
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 780px
    padding-right 80px
    padding-top 35px
  .detail-styles-row
    flex(row, nowrap, space-between)
    width 100%
    margin-bottom 20px
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    max-width: 560px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, flex-start)
    color #000000
    fonts(18px, 27px, normal)
  .konstruktiv-color
    width 150px
    height 95px
    margin-bottom 40px
  .konstruktiv-blue
    background-color #154E9F
    box-shadow 13px 13px 0 0 rgba(#154E9F, 0.4)
  .konstruktiv-orange
    background-color #FF6600
    box-shadow 13px 13px 0 0 rgba(#FF6600, 0.4)
  .detail-ui-font
    flex(column, nowrap, center)
    min-width 270px
    fonts(32px, 41px, bold)
    margin-left 80px
  .detail-font-name
    display block
    padding 10px 0
    &.cera
      font-family 'Cera Pro', sans-serif
    &.ptsans
      font-family: 'PT Sans Caption', sans-serif
  .detail-ui-kit
    width 100%
    max-width 680px
    padding-top 60px
  .detail-ui-input
    flex(row, nowrap, flex-start, center)
    width 300px
    height 88px
    padding-left 20px
    color #b3b3b3
    background none
    border 1px solid #B3B3B3
    font-family: 'Montserrat';
    font-weight: normal;
    fonts(18px, 22px)
    letter-spacing: 0.03em;
    &.active
      color #154E9f
      border 2px solid #154E9f
      font-weight: 600
  .site-button
    align-self center
    cursor pointer
    position relative
    display block
    width 197px
    padding 10px 0
    color $white
    font-family 'Cera Pro', sans-serif
    fonts(15px, 18px, bold)
    letter-spacing 0.03em
    text-align center
    text-decoration none
    background: #154E9F;
    box-shadow: -8px 7px 0 #FFAA47
  .site-button-hover
    background-color #003378
  .detail-ui-caption
    display block
    margin-top 80px
    margin-bottom 15px
    color $black
    font-family 'Cera Pro'
    fonts(16px, 1.4, 500)
    letter-spacing: 0.03em
  .detail-ui-tabs
    flex(row, nowrap, space-between, center)
    width 100%
    height 52px
    max-width 660px
    padding 0 19px
    color #808080
    font-family 'Cera Pro'
    fonts(16px, 1.4, 500)
    letter-spacing: 0.03em
    border 1px solid #acacac
  .ui-tabs-button
    cursor pointer
    height 32px
    padding 3px 20px 5px
    &.active
      color #1E2024
      background-color: rgba(20, 78, 158, 0.21);
@media (max-width 1400px)
  .detail-konstruktiv
    padding-left 60px
    padding-right 0
    .detail-image-wrap
      padding-right 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-buttons
      min-width 100px
