.detail-mediflex
  padding-bottom 150px
  color $white
  background #00549C
  height initial
  .detail-image-wrap
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #80A9CE
  .detail-page-wrap
    padding-top 50px
  .detail-page-about,
  .detail-page-table td
    color $white
  .detail-page-link
    background-color #80A9CE
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#80A9CE, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
  .result-block
    background-color $white
  .result-header
    color $black
  .result-text
    color #00549C
    strong
      color $black
  .seo-block
    padding-top 120px
