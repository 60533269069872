.aviator
  color #332C2C
  background $white url('../img/aviator/aviator-bg.jpg') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #DD1F3D
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #DD1F3D
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #DD1F3D
  .portfolio-case-link
    color: #DD1F3D
    stroke: #DD1F3D
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (max-width 550px)
  .aviator
    background $white url('../img/aviator/aviator-bg-m.jpg') top center\/cover no-repeat
