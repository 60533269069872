.detail-atrium
  padding-bottom 0
  color $black
  .detail-image-wrap
    min-height: 95vh
    img
      width auto
      display block
      height 100%
      object-fit: cover
  .detail-page-wrap
    padding-bottom 150px
    padding-top 70px
    background: linear-gradient(300deg, #D5D5D5 10%, rgba(215, 215, 215, 0.79) 43.04%, rgba(255, 255, 255, 0) 80%);
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #EE362A
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #EE362A
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#EE362A, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
