.portfolio-header
  background none
.white-button::before,
.white-button::after
  background #332c2c
.white-button:hover
  &::before,
  &::after
    background rgba(#332c2c, 0.5)
.portfolio-button
  flex(row, nowrap, center, center)
  position fixed
  left 0
  bottom 0
  width 100%
  height 60px
  // background-color #77b23d
  border-radius 30px 30px 0 0
  z-index 3
  transition all .6s ease-out
.portfolio-button-link
  color $white
  fonts(17px, 26px, 800)
  text-transform uppercase
  text-decoration none
  transition all .6s ease-out
.portfolio-page
  position relative
  z-index 2
  flex(column, nowrap, space-between, center)
  height 100vh
  max-height: 650px;
  padding-bottom 90px
  color $dark
  &:first-of-type
    margin-top -58px
  h2
    display none
    cursor pointer
    fonts(24px, 35px, 800)
  .portfolio-mobile-header
    display block
@media (max-width 550px)
  .portfolio-page
    padding 0
    padding-bottom 90px
    .portfolio-content-wrap
      padding 0 24px
.portfolio-image-wrap
  flex(row, nowrap, center, flex-start)
  width 100%
  height auto
  position relative
  top 0
  left 0
  z-index 1
  img
    max-width 100%
    width 100%
    height auto
.portfolio-content-wrap
  position relative
  z-index 2
.tags-list
  flex(row, wrap, flex-start, flex-start)
.tags-item
  margin-right 20px
  margin-bottom 8px
  a, span
    fonts(14px, 21px, 900)
    color inherit
    text-decoration none
.portfolio-page-about
  display none
  fonts(14px, 21px, 400)
.portfolio-page-table
  display none
  color inherit
  fonts(14px, 21px, 400)
  th
    text-align left
    vertical-align top
    min-width 135px
    font-weight 600
.portfolio-page-link
  position absolute
  bottom 0
  left 0
  display none
  width: 342px;
  height: 160px;
  border-radius: 0px 104px 0px 0px;
  // margin-top 50px
  fonts(18px, 26px, 800)
  text-transform uppercase
  text-decoration none
  cursor: pointer;
  z-index: 3;
  border: none;
  color: #fff;
  transition: all 0.6s ease-out;
  &:hover
    filter: brightness(85%);
.portfolio-case-link
  position absolute
  bottom 0
  left 360px
  display none
  width 342px
  height 160px
  color: #fff
  stroke: #fff
  border: none
  z-index: 3;
  fonts(18px, 26px, 800)
  text-transform uppercase
  text-decoration none
  &:hover
    svg
      transform: translateX(20px)
  svg
    margin-left 5px
    margin-bottom: 1px;
    flex-shrink: 0
    flex-grow: 0
    transition: all .2s ease-out
    path
      stroke: inherit
@media (min-width 550px)
  .portfolio-page
    max-height 568px
    overflow: hidden
    flex(row, nowrap, flex-start,center)
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    position relative
    order 2
    img
      display block
  .portfolio-button
    display none
  .portfolio-page-link
    flex(row, nowrap, center, center)
@media (min-width 1023px)
  .portfolio-header
    position fixed
    z-index 5
  .portfolio-page
    position relative
    max-height initial
    display grid
    grid-template-columns repeat(2, 1fr)
    justify-content space-between
    align-items flex-start
    padding-bottom 0
    height initial
    min-height initial
    &:first-of-type
      margin-top 0
    h2, h2 > a
      display block
      fonts(41px, 56px, 800)
      margin-top 0
      margin-bottom 19px
      color inherit
      text-decoration none
    .portfolio-mobile-header
      display none

  .portfolio-page-link
    margin-top 0
    transition: all .3s ease-out
  .portfolio-case-link
    flex(row, nowrap, center, center)
  .portfolio-content-wrap
    order 1
    padding-top 50px
    padding-bottom 160px
    min-width: 690px
    height 100%
  .portfolio-image-wrap
    position relative
    width auto
    height 100%
    display flex
    z-index 1
    order 2
  .portfolio-page-about,
  .portfolio-page-table
    display block
    max-width: 600px
  .portfolio-page-table
    margin-bottom 60px
  .portfolio-page-about
    margin 45px 0 30px
    a, span
      fonts(14px, 21px, 800)
      color inherit
      text-decoration none
@media (min-width 1400px)
  .portfolio-page
    min-height 750px
@media (min-width 1605px)
  .portfolio-page
    padding-top 0
  .portfolio-content-wrap
    padding-top 100px
    padding-bottom 160px
