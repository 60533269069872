// Pros Module

.pros
  position relative
  flex(column, nowrap)
  padding-top: 40px
  padding-bottom: 46px
  z-index 4
  background-color $white
.pros-item
  position: relative
  flex(column, nowrap)
  padding-bottom: 16px
  &:nth-of-type(n+2)
    margin-top: 46px
  &::after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    display: block
    width: 34px
    height: 3px
    background: $red
.pros-name
  fonts(14px, 21px, 800)
.pros-value
  color: $red
  fonts(53px, 1, 800)
.pros-projects
  display inline-block
  width 82px
@media (min-width 550px)
  .pros
    flex(row, nowrap, space-between, flex-end)
    padding-top 30px
    padding-bottom 0
    box-shadow inset 0 -3px 0 $l-gray
  .pros-item
    &:nth-of-type(n+2)
      margin-top: 0

@media (min-width 800px)
  .pros
    flex(row, nowrap, flex-start, flex-end)
    background none
  .pros-item:not(:last-child)
    margin-right 50px
@media (min-width 1050px)
  .pros-item:not(:last-child)
    margin-right 120px
@media (min-width 1800px)
  .pros-item:not(:last-child)
    margin-right 285px
