.maria
  color #332C2C
  background #d4d5d5 url('../img/maria/maria-bg-m.png') top right\/contain no-repeat
  h2:hover
    cursor pointer
    color #880364
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #880364
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #880364
  .portfolio-case-link
    color: #880364
    stroke: #880364
  .portfolio-content-wrap
    height auto
    // background-color: #d4d5d5
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (min-width: 550px)
  .maria
    background #e0e0e2 url('../img/maria/maria-bg.png') top right no-repeat
    .portfolio-content-wrap
      height 100%
      max-width: 550px
      min-width: auto
      background-color #e0e0e2
      box-shadow 95px 0 50px #e0e0e2, -155px 0 0 #e0e0e2
