.detail-nabiss
  color #332C2C
  background $white
  height initial
  .detail-image-wrap
    position relative
    background none
    background-attachment fixed
  .detail-image-parallax
    position absolute
    top 0
    left 0
  .tags-item
    a, span
      box-shadow 0 4px 0 0 $nabiss
  .detail-page-about,
  .detail-page-table td
    color #494949
  .detail-page-link
    background-color #8CAF3B
    color $white
    transition all .2s ease-out
    &:hover
      background-color: #7d9d30
  .detail-styles-container
    width 100%
    max-width 760px
  .detail-styles-colors
    flex(row, nowrap, space-between, center)
    padding-top 40px
  .styles-colors-item
    flex(column, nowrap, space-between, center)
    height 175px
    div
      width 120px
      height 120px
      border-radius 50%
    span
      color #a4a4a4
  .detail-styles-ui
    position relative
    width 100%
    flex(row, nowrap, space-between, center)
  .detail-font-example
    display block
    font-family 'Proxima Nova', sans-serif
    color: rgba(198, 198, 198, 0.2)
    fonts(194px, 236px, 800)
  .detail-font-name
    font-family: 'Proxima Nova', sans-serif
    fonts(24px, 29px, 600)
  .detail-ui-kit
    width 55%
    padding-top 50px
  .detail-ui-buttons,
  .detail-ui-links,
  .detail-ui-checkboxes
    flex(row, wrap, space-between)
  .detail-ui-buttons
    min-width 400px
  .site-button
    display: inline-block
    width 190px
    // margin 0 20px
    font-family 'Proxima Nova', sans-serif
    margin-bottom 60px
    padding: 11px 10px 13px
    border: none
    text-align center
    background-color: #a1c058
    box-shadow: 4px 4px 20px rgba(161,192,88,0.5)
    color: #fff
    fonts(16px, 19px, 600)
    text-decoration: none
  .site-button-hover
    background-color #7D9D30
    text-decoration: none
  .block-link
    position: relative
    display: inline-block
    margin-bottom 40px
    padding: 14px 0
    color #1F272D
    fonts(16px, 19px, normal)
    font-family 'Proxima Nova', sans-serif
    text-decoration: none
    &::after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      display: block
      width: 0%
      height: 2px
      background-color: #a1c058
      transition: width 0.3s ease
  .block-link.active-page::after
    width: 100%
  .detail-ui-checkbox
    position relative
    display block
    margin-top 20px
    padding-left 30px
    color #8F9396
    font-family 'Proxima Nova', sans-serif
    font(12px, 16px, normal)
    &::before
      content ''
      position absolute
      top -2px
      left 0
      width 22px
      height 22px
      border 1px solid #8F9396
  .ui-checkbox-checked::after
    content ''
    position absolute
    top 5px
    left 7px
    width 10px
    height 10px
    background-color #A1C058
    box-shadow 2px 2px 10px rgba(161, 192, 88, 0.5)
.nabiss-colors-olive
  background #8CAF3B
  box-shadow 4px 4px 50px #A1BD60
.nabiss-colors-gray
  background #C6C6C6
  box-shadow: 4px 4px 50px #C6C6C6
.nabiss-colors-dark
  background #1F272D
  box-shadow: 4px 4px 50px #1F272D

@media (max-width 1400px)
  .detail-nabiss
    padding-left 60px
    padding-right 60px
    .detail-font-example
      font-size 132px
    .detail-styles-ui
      min-width 520px
    .detail-ui-buttons
      justify-content center
      min-width 100px
    .detail-ui-links
      width 360px
      position absolute
      top 105%
      left 0
    .detail-ui-checkboxes
      width 360px
      position absolute
      top 125%
      left 0
