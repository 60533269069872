.detail-fly
  color #332c2c
  background $white url('../img/fly-big.png') no-repeat 0 0\/contain
  height initial
  padding-top 50px
  padding-bottom 160px
  padding-right 0
  .detail-leaf-down
    color $white
    &::after
      background url('../img/leaf-mouse-white.svg') 0 0 no-repeat
  .detail-image-wrap
    padding-right 50px
    text-align right
    img
      width auto
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, 1fr))
    grid-gap 0px
    padding-top 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #1F4E8D
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #A9D1FF
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #0F3E7D
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 780px
    padding-right 80px
    padding-top 35px
  .detail-styles-row
    flex(row, nowrap, space-between)
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    // max-width: 560px
    padding-bottom 80px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, center)
    color #A4A4A4
    fonts(14px, 16px, normal)
  .fly-color
    margin-bottom 54px
  .detail-ui-kit
    padding-top 50px
    width 100%
    flex(row, wrap, space-between, flex-start)
  .detail-ui-font
    font-family 'PT Sans', sans-serif
    fonts(98px, 127px, bold)
  .detail-font-name
    display block
    color #000000
  .detail-font-name:first-child
    color rgba(169, 209, 255, 0.4)
  .detail-ui-basket
    margin-top 20px
  .detail-ui-buttons
    padding-top 50px
    flex(row, nowrap, space-between)
    width 250px
  .detail-ui-col
    flex(column, nowrap, center, center)
    width 110px
    span
      color #a4a4a4
      fonts(14px, 21px, normnal)
      text-align center
@media (max-width 1400px)
  .detail-fly
    padding-left 60px
    padding-right 0
    .detail-image-wrap
      padding-right 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
