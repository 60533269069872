.detail-hartdiesel
  padding-bottom 150px
  color $black
  .detail-image-wrap
    padding-top 50px
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #2F99C9
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #2F99C9
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#2F99C9, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
