.sintez
  color $black
  background #DDDDDD url('../img/sintez-bg.png') no-repeat -80px 0 \/contain
  h2:hover
    cursor pointer
    color #E36D27
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px #E36D27
  .portfolio-page-about
    max-width 750px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #626262
  .portfolio-page-link
    background-color #E36D27
  .portfolio-case-link
    color: #E36D27
    stroke: #E36D27
  .portfolio-image-wrap
    justify-content center
    width 70%
    top 10%
    left auto
    right 0
    img
      max-height: 550px
@media (min-width 550px)
  .sintez
    background-position 60% 70%
    .portfolio-image-wrap
      justify-content flex-end
      margin-bottom -50px
@media (min-width 960px)
  .sintez
    background-position 100% 0
    background-size 50%
