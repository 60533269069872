.detail-maria
  background radial-gradient(74.21% 63.17% at 50% 36.83%, #FFFFFF 0%, #F9F9F9 53.19%, #D5D5D5 100%);
  padding-bottom 150px
  color $black
  .detail-image-wrap
    padding-top 100px
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #880364
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #880364
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#880364, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
