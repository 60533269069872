.spectr
  color $white
  background: linear-gradient(180deg, #C25665 0%, #9058A8 100%);
  h2:hover
    cursor pointer
    color #66429E
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #66429E
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #66429E
  .portfolio-case-link
    color: #66429E
    stroke: #66429E
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, flex-end, flex-end)
    img
      width auto
@media (min-width: 550px)
  .spectr
    padding-right 70px
    background: linear-gradient(90deg, #C45663 0%, #8F58A9 97.97%);
