.detail-ttk
  padding-bottom 150px
  color $black
  .detail-image-wrap
    background url('../img/ttk/bg-big.png') no-repeat top center\/cover
    padding-top 150px
    img
      width auto
  .detail-page-wrap
    padding-top 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #FE6602
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #FE6602
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#FE6602, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
