// Slogan Module.

.slogan
  position: relative
  padding-top: 0
  padding-bottom: 195px
  margin-top: 15px
  overflow: hidden
  min-height calc(100vh - 58px)

.slogan-name
  color: $red
  fonts(18px, 27px, 800)

.slogan-title
  max-width 260px
  margin: 20px 0 50px
  color: $black
  fonts(42px, 1.02, 800)
  letter-spacing: 0.01em

.slogan-link
  color: $black
  fonts(14px, 21px, 800)
  text-decoration: none
  &:hover
    color $red
.slogan-image-wrap
  position: absolute
  bottom: 320px
  right: 0
  z-index: 1
  img
    vertical-align bottom

.slogan-text
  position: relative
  z-index: 2

@media (min-width: 550px)
  .slogan
    margin-top: 0
    padding-bottom 40px
  .slogan-title
    max-width: 260px
    font-size: 32px
  .slogan-image-wrap
    right 0
    bottom 3px

@media (min-width 750px)
  .slogan
    overflow visible
    min-height initial
  .slogan-title
    max-width 600px

@media (min-width 800px)
  .slogan-image-wrap
    z-index 3

@media (min-width 1150px)
  .slogan-title
    fonts(53px, 1.02, 800)
    max-width 100%
  .slogan-link-container
    position absolute
    top 25px
    right 320px
    z-index 99
  .slogan-text
    margin-top -30px
    z-index: 4
  .slogan-image-wrap
    right -60px
    bottom -34px
    z-index 3
  .slogan-link
    display: block;
    transform-origin: 50% 150px;
    animation: circle 2s linear infinite;
    span
      animation: inner-circle 2s linear infinite;
      transform-origin: 50% 153px;
      display: block;

@media (min-width 1650px)
  .slogan-link-container
    top 70%
    right 505px
  .slogan-link
    font-size: 24px
  .slogan-image-wrap
    right 0
    bottom -50px
@media (min-height 750px)
  .slogan-text
    margin-top 30px

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes inner-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
