.lenklimat
  color $black
  background $white
  h2:hover
    cursor pointer
    color #C56A4E
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #C56A4E
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #C56A4E
  .portfolio-case-link
    color: #C56A4E
    stroke: #C56A4E
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, flex-start)
    img
      width auto
@media (min-width: 550px)
  .lenklimat
    padding-right 150px
    background $white url('../img/lenklimat/bg.png') top right\/contain no-repeat
    .portfolio-image-wrap
      position relative
      height 100%
      flex(row, nowrap, center, center)
      img
        width auto
