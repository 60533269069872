.detail-ksmoto
  padding-bottom 150px
  color $black
  .detail-image-wrap
    text-align center
    img
      width auto
      object-fit: cover
  .detail-content-wrap
    max-width: 100%
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #FECC08
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #FECC08
    color $black
    transition all .2s ease-out
    &:hover
      background-color: rgba(#FECC08, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: radial-gradient(100% 100% at 33.54% 0%, #ECECEC 0%, #FFFFFF 100%);
    .col
      display block
  .result-header
    color $black
  .result-text
    color #FECC08
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 120px
