// Base styles.
html
  width 100%
  height: 100%
  &.popup-is-active
    overflow: hidden
body
  max-width: 1920px
  min-height: 100%
  margin 0 auto
  background-color: $white
  color: $black
  fonts()
  // letter-spacing: 0.03em
  font-family: 'Circe', sans-serif
  box-shadow 0 0 15px 0 rgba(#000000, 0.2)
  img
    max-width: 100%
  *
    box-sizing: border-box
  &.popup-is-active
    overflow: hidden

.page-block
  padding: 18px 0 53px
  .page-title
    margin-bottom: 37px

.page-title
  margin: 0
  fonts(32px, 43px, 700)

.page-subtitle
  margin: 0
  fonts(32px, 43px, 600)

.block-title
  margin: 0
  fonts(24px, 32px, 700)

.block-subtitle
  margin: 0
  fonts(18px, 24px, 700)

.shop-title
  fonts(18px, 24px, 600)

.upper-title
  fonts(14px, 19px, 600)
  text-transform: uppercase

.content-zone
  fonts(14px, 21px)
  p
    margin: 0

// Global state styles

.cancel-list
  list-style: none
  margin: 0
  padding: 0

.site-input
  display: inline-block
  max-width: 100%
  padding: 11px 0
  border: 2px solid #626262
  border-left: none
  border-right: none
  border-top: none
  background: transparent
  color: #626262
  fonts(18px, 27px)
.is-invalid
  border-color: #f03333;
  box-shadow: 0 12px 10px -5px rgba(#f03333, 0.1);
