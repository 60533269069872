.detail-greenmay
  padding-bottom 150px
  color $black
  background: linear-gradient(277.22deg, #F9F8F5 -20.22%, #F9F3DE -20.21%, #FCFAF2 28.14%, #FFFFFF 91.74%);
  .detail-image-wrap
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 0
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #8A0C34
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #8A0C34
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#8A0C34, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
