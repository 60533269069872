.detail-caleo
  padding-bottom 150px
  color $black
  .detail-image-wrap
    padding-bottom 100px
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #FCEB7E
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #FCEB7E
    color #9F0203
    transition all .2s ease-out
    &:hover
      background-color: rgba(#FCEB7E, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
