.detail-pnsk
  padding-bottom 150px
  color $black
  .detail-image-wrap
    background-color #525459
    min-height: auto
    img
      width auto
      display block
      object-fit: cover
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #58C5FA
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #58C5FA
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#58C5FA, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: #525459
    .col
      display block
  .result-header
    color $white
  .result-text
    color #58C5FA
    strong
      color $white
  .seo-block
    padding-top 120px
    padding-bottom 120px
