.butik
  padding-right 0
  color $white
  background $black url('../img/butik/butik-bg.jpg') no-repeat 0 0\/cover
  h2:hover
    cursor pointer
    color #707070
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #707070
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #707070
  .portfolio-case-link
    color: #707070
    stroke: #707070
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 0
    max-height: 650px
    img
      object-fit: contain
      max-width: none
      width: auto
      height: auto
@media (max-width: 550px)
  .butik
    background $black url('../img/butik/butik-bg.jpg') no-repeat center center\/cover
    .portfolio-image-wrap
      img
        max-width: 100%
        height auto
        margin-right: -350px
