// Footer styles.

.site-footer
  position relative
  padding: 53px 0 0
  background: #f6f6f6;
  z-index 4
.footer-title
  margin: 0 0 28px
  fonts(24px, 35px, 800)

.contacts, .footer-services
  margin-bottom: 50px
  li
    padding: 10px 0
    fonts(18px, 23px, 700)
  a
    color: $black
    text-decoration: none
    &:hover
      text-decoration: underline
  &.header-contacts
    margin: 0


.footer-seo-title
  margin: 0 0 12px
  color: $gray
  fonts(18px, 27px, 800)

.footer-seo-content
  color: $gray

.callback-form
  margin: 90px 0 75px
  .site-input
    width: 100%

.callback-form-label
  display: inline-block
  width: 100%
  margin-bottom: 38px
  fonts(18px, 27px, 800)

@media (min-width 568px)
  .footer-list-container
    display grid
    grid-template-columns repeat(2, minmax(160px, 240px))
  .callback-form
    max-width 400px


@media (min-width 800px)
  .footer-row
    flex(row, nowrap, space-between, center)
  .footer-list-container
    grid-template-columns: repeat(2, minmax(160px, 180px))
    grid-gap: 15px
    justify-content space-evenly
  .footer-seo
    max-width 50%

@media (min-width 1050px)
  .site-footer
    padding-top 35px
  .footer-list-container
    grid-gap 82px
  .callback-form
    display none

@media (min-width 1800px)
  .footer-list-container
    grid-template-columns repeat(2, minmax(160px, 415px))
  .footer-seo
    max-width 45%
