.ttk
  color $white
  background #16488a
  h2:hover
    cursor pointer
    color #FE6602
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FE6602
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #FE6602
  .portfolio-case-link
    color: #FE6602
    stroke: #FE6602
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .ttk
    padding-right 50px
    background #16488a url('../img/ttk/bg.png') top right\/contain no-repeat
