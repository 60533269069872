.massa
  color $black
  background linear-gradient(280.46deg, rgba(0, 0, 0, 0.22) 9.17%, rgba(255, 255, 255, 0) 95.7%);
  height auto
  max-height: none
  h2:hover
    cursor pointer
    color #0065B3
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #0065B3
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #0065B3
  .portfolio-case-link
    color: #0065B3
    stroke: #0065B3
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width auto
@media (min-width: 550px)
  .massa
    padding-right 80px
    background url('../img/massa/bg.png') top right\/contain no-repeat, linear-gradient(280deg, rgba(0, 0, 0, 0.22) 9.17%, rgba(255, 255, 255, 0) 95%);
