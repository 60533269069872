.erasnab
  color #332C2C
  background url('../img/erasnab/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #244082
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #244082
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #244082
  .portfolio-case-link
    color: #244082
    stroke: #244082
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (min-width: 550px)
  .erasnab
    background url('../img/erasnab/bg.png') top right\/cover no-repeat
