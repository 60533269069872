.karton
  color #332C2C
  background $white
  h2:hover
    cursor pointer
    color #FF4C00
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FF4C00
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #FF4C00
  .portfolio-case-link
    color: #FF4C00
    stroke: #FF4C00
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
