.pitergran
  color $white
  background $white url('../img/pitergran/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #019CFF
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #019CFF
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #019CFF
  .portfolio-case-link
    color: #019CFF
    stroke: #019CFF
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width 100%
@media (min-width: 550px)
  .pitergran
    background url('../img/pitergran/bg.png') top center\/cover no-repeat
