.sloplast
  padding-right 0
  // grid-template-columns: repeat(2, auto)
  color #332C2C
  background $white url('../img/sloplast/sloplast-bg-m.png') no-repeat top left
  h2:hover
    cursor pointer
    color #0054D2
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #0054D2
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  // .portfolio-content-wrap
  //   min-width: 600px;
  .portfolio-page-link
    background-color #0054D2
  .portfolio-case-link
    color: #0054D2
    stroke: #0054D2
.sloplast
  .portfolio-image-wrap
    width 100%
    flex(row, nowrap, center, center)
    padding-top 50px
    // max-height: 750px
    overflow hidden
    img
      width 100%
      margin-bottom 0
      margin-right 0

@media (min-width: 1024px)
  .sloplast
    background $white url('../img/sloplast/sloplast-bg.png') no-repeat bottom right
    .portfolio-image-wrap
      width 100%
      flex(row, nowrap, center, center)
      padding-top 0
      max-height: 750px
      overflow hidden
      img
        width 100%
        margin-bottom -300px
