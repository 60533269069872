.detail-nasledie
  padding-bottom 150px
  color $black
  .detail-image-wrap
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #EE2C35
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #EE2C35
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#EE2C35, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
