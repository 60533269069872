.eleveolt
  color $black
  background $white url('../img/eleveolt/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #2F99C9
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #2F99C9
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #2F99C9
  .portfolio-case-link
    color: #2F99C9
    stroke: #2F99C9
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .eleveolt
    padding-right 75px
    background url('../img/eleveolt/bg.png') top right\/cover no-repeat
