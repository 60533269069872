.teatr
  color $white
  background #141F31 url('../img/teatr/teatr-bg.jpg') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #199E7F
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #199E7F
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #199E7F
  .portfolio-case-link
    color: #199E7F
    stroke: #199E7F
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (max-width: 550px)
  .teatr
    .portfolio-image-wrap
      position relative
      height 100%
