.restochef
  color $white
  background $black
  h2:hover
    cursor pointer
    color $yellow
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px $yellow
  .portfolio-page-link
    background: $yellow
    color #000000
  .portfolio-case-link
    color: $yellow
    stroke: $yellow
@media (min-width 960px)
  .restochef
    .portfolio-content-wrap
      width 95%
    background $black url('../img/restochef.png') 150% 0\/auto 100% no-repeat
    .portfolio-image-wrap
      align-items center
      img
        margin-bottom -100px
@media (min-width 1100px)
  .restochef
    background $black url('../img/restochef.png') 150% 0\/auto 100% no-repeat
@media (min-width 1200px)
  .restochef
    background $black url('../img/restochef.png') 130% 0\/auto 100% no-repeat
@media (min-width 1400px)
  .restochef
    background $black url('../img/restochef.png') 125% 0\/auto 100% no-repeat
@media (min-width 1555px)
  .restochef
    background $black url('../img/restochef.png') 100% 0\/auto 100% no-repeat
    .portfolio-image-wrap
      margin-right 0
      img
        margin-bottom -270px
