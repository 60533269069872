.astra
  color #332C2C
  background #F6F2EF url('../img/astra/astra-bg.png') top center\/contain no-repeat
  h2:hover
    cursor pointer
    color #B74600
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #B74600
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #B74600
  .portfolio-case-link
    color: #B74600
    stroke: #B74600
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
  // .portfolio-content-wrap
  //   position relative
  //   background: #F6F2EF
  //   &:after
  //     content ''
  //     position absolute
  //     top 0
  //     left 100%
  //     width 280px
  //     height 100%
  //     background linear-gradient(90deg, #F6F2EF 8.49%, rgba(246, 242, 239, 0) 96.74%);
@media (min-width: 550px)
  .astra
    background #F6F2EF url('../img/astra/astra-bg.png') top right\/auto 100% no-repeat
    .portfolio-content-wrap
      position relative
      background: #F6F2EF
      height 100%
      flex(column, nowrap, center, flex-start)
      &:after
        content ''
        position absolute
        top 0
        left 100%
        width 280px
        height 100%
        background linear-gradient(90deg, #F6F2EF 8.49%, rgba(246, 242, 239, 0) 96.74%);
@media (max-width: 1024px)
  .astra
    padding-left 0
    .portfolio-content-wrap
      padding-left 20px
