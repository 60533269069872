.sertek
  color $black
  background #ffffff
  max-height: none
  h2:hover
    cursor pointer
    color #2B8FC2
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #2B8FC2
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #2B8FC2
  .portfolio-case-link
    color: #2B8FC2
    stroke: #2B8FC2
  .portfolio-image-wrap
    // max-height: 300px
    position static
    width 100%
    // height 100%
    flex(row, nowrap, center, flex-start)
    picture
      height 100%
      max-height: 100%
    img
      width 100%
      height 100%
      object-fit: contain;
@media (min-width 550px)
  .sertek
    padding-right 50px
    .portfolio-image-wrap
      max-height: none;
      width 100%
      // height 100%
      flex(row, nowrap, center, flex-start)
      picture
        height 100%
        max-height: 100%
      img
        width 100%
        height 100%
        object-fit: contain;
