.flotilion
  color $white
  background #7E8C93 url('../img/flotilion/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #FED301
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FED301
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #FED301
  .portfolio-case-link
    color: #FED301
    stroke: #FED301
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, flex-start, center)
    img
      width auto
@media (min-width: 550px)
  .flotilion
    padding-right 50px
    background #7E8C93 url('../img/flotilion/bg.png') top right\/contain no-repeat
