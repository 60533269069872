.detail-alfaplan
  color #332C2C
  background url('../img/alfaplan/bg-big.png') no-repeat top right, linear-gradient(243.4deg, rgba(196, 196, 196, 0) 4.5%, rgba(196, 196, 196, 0.446027) 27.76%, rgba(196, 196, 196, 0.71) 47.52%, rgba(196, 196, 196, 0.518928) 61.14%, rgba(196, 196, 196, 0) 94.53%);
  height initial
  padding-top 100px
  padding-bottom 160px
  padding-right 0
  .detail-image-wrap
    text-align center
    img
      width auto
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-bottom 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #BB100E
  .detail-page-about,
  .detail-page-table td
    max-width 700px
    color #7D7D7D
  .detail-page-link
    background-color #BB100E
    color $white
    transition all .2s ease-out
    &:hover
      background-color: #313131
  .detail-styles-container
    flex(row, nowrap, space-between, center)
    width 100%
    max-width 780px
    padding-right 80px
  .detail-styles-colors
    display grid
    grid-template-columns repeat(2, 93px)
    grid-template-rows auto
    max-width: 430px
  .styles-colors-item
    flex(column, nowrap, flex-end, center)
    width 93px
    height 93px
    color $white
    fonts(14px, 16px, normal)
  .alfaplan-light-gray
    background-color: #a5afc0
  .alfaplan-blue
    background-color: #006bd4
  .alfaplan-red
    background-color: #ff0006
  .alfaplan-dark-gray
    background-color: #313131
  .detail-styles-fonts
    fonts(53px, 61px, normal)
    padding-left 20px
  .styles-fonts-avenir
    font-family 'Avenir Next', sans-serif
    margin-bottom 25px
  .styles-fonts-gotham
    font-family 'Gotham Pro', sans-serif
    font-weight: bold
  .styles-fonts-example
    padding-right 45px
  .detail-ui-kit
    padding-top 50px
    width 100%
    max-width 430px
    flex(row, nowrap, space-between, flex-start)
  .detail-ui-forms
    padding-top 100px
  .catalog-filter
    max-width 100%
    width 822px
    height 150px
    // background-color #f6f7f9
    padding 0 36px 0 33px
    margin-right 35px
  .catalog-filter-caption
    display block
    font-family 'Gotham Pro', sans-serif
    fonts(14px, 1, bold)
    text-transform uppercase
    padding-top 30px
    margin-bottom 60px
  .range-input
    margin: 20px auto;
    height 7px
    position relative
  .range-min,
  .range-max {
    position: absolute;
    top: 25px;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
  }
  .range-min {
    left: 0;
  }
  .range-max {
    right: -20px;
  }
  .noUi-connects {
    background: #c9c9c9;
  }
  .noUi-connect {
    background: #2855a8;
  }
  .noUi-target {
    border: none;
  }
  .noUi-horizontal {
    height: 7px;
  }
  .noUi-horizontal .noUi-handle {
    top: -8px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.27);
    cursor: pointer;
  }
  .noUi-handle::after,
  .noUi-handle::before {
    display: none;
  }
  .range-min-output,
  .range-max-output {
    position: absolute;
    top: -43px;
    transform: translateX(-33%);
    padding: 5px 8px;
    margin-left: -93px;
    background: #fff;
    box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.17);
    color: #000;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
  }
  .range-min-output::after,
  .range-max-output::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 0;
    height: 0;
    border: 6px solid #fff;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  .range-max-output {
    transform: translateX(-33%);
    margin-left: -94px;
  }
  .detail-styles-ui
    position relative
    flex(row, nowrap, space-between, flex-end)
    width 822px
    max-width 100%
    margin-top 71px
    color $black
  .alfaplan-checkbox-container
    flex(row, nowrap, space-between, center)
    width 100%
    max-width: 151px
    span
      display block
      width 90px
      font-family 'Gotham Pro', sans-serif
      fonts(13px, 1.1, bold)
  .alfaplan-checkbox
    cursor pointer
    position relative
    width 41px
    height 41px
    border 1px solid #BFCBD0
    &::before
      content ''
      position absolute
      width 100%
      height 100%
      background url('../img/alfaplan-checkbox.svg') center center no-repeat
  .alfaplan-select-container
      margin 0 5px
  .alfaplan-select-container > span
    font-family 'Gotham Pro', sans-serif
    fonts(14px, 1.1, bold)
  .alfaplan-select
    cursor pointer
    user-select none
    -moz-user-select none
    position relative
    flex(row, nowrap, flex-start, center)
    width 260px
    height 41px
    margin-top 10px
    padding-left 17px
    font-family 'Avenir Next'
    fonts(15px, 17px)
    border 1px solid #bfcbd0
    &::before
      content: ''
      position absolute
      right 21px
      width 12px
      height 8px
      background url('../img/alfaplan-select.svg') 0 0 no-repeat
  .alfaplan-search
    cursor pointer
    position relative
    flex(row, nowrap, center, center)
    width 190px
    height 41px
    color $white
    background-color: #fe0006
    span
      position relative
      display block
      padding-left 18px
      font-family: 'Avenir Next'
      color $white
      font(15px, 17px)
      &::before
        content ''
        position absolute
        left 0
        top 50%
        transform translateY(-50%)
        width 14px
        height 14px
        background url('../img/alfaplan-search.svg') 0 0 no-repeat
  .result-block
    padding-bottom 120px
    padding-top 120px
    background $white
    .col
      display block
  .result-header
    color $black
  .result-text
    color #BB100E
    strong
      color $black
  .seo-block
    margin-bottom -150px
    padding-top 120px
    padding-bottom 200px
    background: linear-gradient(217.01deg, rgba(196, 196, 196, 0) 4.5%, rgba(196, 196, 196, 0.446027) 27.76%, rgba(196, 196, 196, 0.71) 47.52%, rgba(196, 196, 196, 0.518928) 61.14%, rgba(196, 196, 196, 0) 94.53%);

@media (max-width 1400px)
  .detail-alfaplan
    .detail-page-wrap
      grid-gap 50px
    .detail-font-example
      font-size 132px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      justify-content center
      min-width 100px
