.header
  position relative
  z-index 9
  flex(row, nowrap, space-between, center)
  padding: 12px 13px
  &.popup-is-active
    padding 12px 0
    position: fixed
    top: 0
    left: 0
    z-index: 10
    flex-wrap: wrap
    width: 100%
    height: 100%
    max-height: 100%
    background: $black
    .header-menu-button
      z-index: 11
      // margin-left 13px
      &::before, &::after
        top: 50%
        left: 0
        transform: translateY(-50%) rotate(45deg)
        background: $l-gray
      &::after
        top: 50%
        left: 0
        width: 100%
        transform: translateY(-50%) rotate(-45deg)
        background: $l-gray
      &:focus
        border none
        outline none
        box-shadow none
    .header-logo
      z-index: 11
  &.modal-is-active
    padding 12px 0
    position: fixed
    top: 0
    left: 0
    z-index: 10
    flex-wrap: wrap
    width: 100%
    height: 100%
    max-height: 100%
    background: $black
    .modal-callback-close
      z-index: 11
      &::before, &::after
        top: 50%
        left: 0
        transform: translateY(-50%) rotate(45deg)
        background: $black
      &::after
        top: 50%
        left: 0
        width: 100%
        transform: translateY(-50%) rotate(-45deg)
        background: $black
      &:focus
        border none
        outline none
        box-shadow none
.header-logo
  position: relative
  flex(row, nowrap, center, center)
  width: 34px
  height: 34px
  margin-right: 17px
  z-index 1
  svg
    max-width: 100%
    height: auto

.header-menu-button
  position: relative
  width: 34px
  height: 24px
  margin-left 13px
  background: none
  border: none
  cursor: pointer
  z-index 1
  &:focus
    border none
    outline none
    box-shadow none
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    transform: rotate(0deg)
    width: 100%
    height: 4px
    border-radius: 10px
    background: #332c2c
    transition: all 0.3s ease
  &::after
    content: ''
    position: absolute
    top: 12px
    right: 0
    transform: rotate(0deg)
    width: 50%
    height: 4px
    border-radius: 10px
    background: #332c2c
    transition: all 0.3s ease

.modal-callback-close
  position: absolute;
  top 23px
  left 6px
  width: 34px
  height: 24px
  margin-left 13px
  background: none
  border: none
  cursor: pointer
  z-index 1
  &:focus
    border none
    outline none
    box-shadow none
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    transform: rotate(0deg)
    width: 100%
    height: 4px
    border-radius: 10px
    background: #332c2c
    transition: all 0.3s ease
  &::after
    content: ''
    position: absolute
    top: 12px
    right: 0
    transform: rotate(0deg)
    width: 50%
    height: 4px
    border-radius: 10px
    background: #332c2c
    transition: all 0.3s ease

.modal-callback-form
  z-index: 10
  flex(column, wrap, space-around)
  display none
  position absolute
  top 0
  left 0
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width 100vw
  padding-left 23px
  color $black
  background #f6f6f6 url('../img/modal-bg-small.png') right bottom no-repeat
  h2
    fonts(18px, 27px, 900)
  a
    color $black
    fonts(18px, 27px, normal)
    text-decoration none
    &:hover
      color $red
  form
    padding-bottom 160px
    .site-input
      width 80%
      max-width 1130px
.modal-is-active
  .modal-callback-form
    display flex
.modal-form-label
  display block
  fonts(18px, 27px, 900)
.modal-form-submit
  cursor pointer
  position absolute
  width 100%
  height 60px
  z-index 3
  left 0
  bottom 0
  border none
  border-radius 30px 30px 0 0
  background $red
  color $white
  fonts(17px, 26px, 800)
  text-transform uppercase
  text-decoration none
  transition all .6s ease-out
  &:hover
    background #ce0000
.modal-thanks
  display none
  div
    position fixed
    z-index: 9999
    top 50%
    left 50%
    transform translate(-50%, -50%)
    flex(column, nowrap, center, center)
    width 100%
    max-width: 320px
    min-height 240px
    padding 30px 0
    background: $white
    box-shadow 0 4px 20px rgba($black, 0.5)
    border-radius: 40px
    svg
      margin 20px auto 20px
    span
      display block
      fonts(24px, 1.2, bold)
@media (min-width 550px)
  .modal-form-submit
    width 302px
    height 120px
    border-radius 0px 84px 0px 0px
  .modal-form-label
    margin-bottom 40px
@media (max-height 500px)
  .modal-callback-form
    form
      padding-bottom 60px
  .modal-form-label
    margin-bottom 0
  .modal-form-submit
    width 100%
    height 60px
    border-radius 30px 30px 0 0
@media (min-width 1050px)
  .header
    flex(column, nowrap, flex-start, flex-start)
    align-content flex-start
    padding-bottom 0
    &.popup-is-active
      flex-wrap nowrap
  .header-logo
    order 1
  .header-menu-button
    order 2
    margin-top 30px
  .header.popup-is-active
      padding 12px 13px
    .header-menu-button
      margin-left 0
  .modal-callback-form
    padding-left 60px
    background-image url('../img/modal-bg-big.png')
    background-size contain
    h2
      fonts(38px, 57px, 900)
    a
      fonts(38px, 57px, normal)
  .modal-form-label
    fonts(38px, 57px, 900)
@media (min-width 1300px)
  .modal-callback-form
    padding-top 20px
    padding-left 100px
  .modal-form-submit
    width 342px
    height 160px
    border-radius 0px 104px 0px 0px
