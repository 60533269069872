.detail-souz
  color #332c2c
  background $white
  height initial
  padding-top 100px
  padding-bottom 160px
  padding-right 0
  .detail-leaf-down
    padding-top 40px
    color $black
    &::after
      background url('../img/leaf-mouse.svg') 0 0 no-repeat
  .detail-image-wrap
    margin 0 auto
    margin-top -50px
    padding-left 180px
    padding-right 180px
    padding-bottom 30px
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-top 0px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #0083DE
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #0083DE
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #0073ce
  .detail-styles-container
    flex(row, wrap)
    width 100%
    max-width 780px
    padding-right 80px
    padding-top 35px
  .detail-styles-row
    flex(row, nowrap, space-between)
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    padding-bottom 80px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, center)
    color $black
    fonts(14px, 16px, normal)
  .souz-color
    margin-bottom 28px
  .detail-ui-font
    flex(row, nowrap, flex-start, center)
  .detail-font-name
    padding-right 70px
    fonts(24px, 31px, normal)
    img
      display block
      margin-bottom 20px
  .detail-font-example
    display block
    max-width 450px
    font-family 'PT Sans', sans-serif
    fonts(24px, 1.67, normal)
    color $black
    white-space pre-wrap
@media (max-width 1400px)
  .detail-souz
    padding-left 60px
    padding-right 0
    .detail-image-wrap
      padding-right 60px
      padding-left 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
