.detail-tourskazka
  color #332C2C
  background: linear-gradient(180.84deg, #FFFEFE 65.98%, #CBCBCB 111.19%);
  height initial
  .detail-leaf-down
    color $black
    &::after
      background url('../img/leaf-mouse.svg') 0 0 no-repeat
  .detail-image-wrap
    padding-top 0
    img
      width 100%
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #2C2D37
  .detail-page-about,
  .detail-page-table td
    color #A4A4A4
  .detail-page-link
    background-color #2C2D37
    color $white
    transition: all .2s ease-out
    &:hover
      background-color: #4C4D57
  .detail-styles-container
    display grid
    grid-template-columns repeat(2, auto)
    grid-gap 100px 40px
  .detail-styles-colors
    max-width 290px
    font-family: 'Open Sans', sans-serif
    flex(row, nowrap, space-between, center)
    padding-top 40px
  .styles-colors-item
    flex(column, nowrap, space-between, center)
    height 145px
    & > div
      flex(row, nowrap, center, center)
      width 91px
      height 91px
      border-radius 50%
    span
      color #2C2D37
  .detail-styles-fonts
    flex(column, nowrap, center, flex-start)
    fonts(24px, 33px)
  .styles-fonts-example
    display inline-block
    width 40px
    color #ECDFB3
  .styles-fonts-days
    font-family 'Days', sans-serif
    .styles-fonts-name
      text-transform uppercase
  .styles-fonts-opensans
    font-family 'Open Sans', sans-serif
    font-weight bold
  .detail-styles-ui
    font-family: 'Open Sans', sans-serif
    width 100%
    padding 40px 0
    flex(row, nowrap)
  .tourskazka-form
    width 100%
    max-width 275px
    font-family: 'Open Sans', sans-serif
    flex(column)
    padding 35px 20px
    background-color #8FC029
    label
      color $white
      padding-bottom 5px
      fonts(13px, 18px, bold)
      &:not(:first-child)
        padding-top 15px
    input
      width 100%
      background-color $white
      height 48px
      color #757575
      padding 0 20px
      fonts(13px, 18px)
      border-radius 30px
      border none
      &:focus
        border none
        outline none
        box-shadow none
  .detail-styles-buttons
    flex(column, nowrap, center, flex-start)
  .site-button
    background: #8fc029
    border: 0
    color: #ffffff
    text-decoration: none
    text-transform: uppercase
    text-align: center
    width: 260px
    height: 60px
    line-height: 60px
    font-family: 'Open Sans', 'Arial', sans-serif
    font-weight: 700
    font-size: 12px
    display: inline-block
    border-radius: 30px
    cursor: pointer
    transition: all ease 0.2s
    &:not(:last-child)
      margin-bottom 40px
  .site-button-hover
    background-color #73A015
@media (max-width 1400px)
  .detail-tourskazka
    .detail-page-wrap
      grid-template-columns 0.8fr 1fr
@media (max-width 1200px)
  .detail-tourskazka
    .detail-page-wrap
      grid-template-columns repeat(1, 75%)
    .detail-styles-color
    .detail-styles-ui
      justify-content flex-start
@media (min-width 1600px)
  .detail-tourskazka
    .detail-styles
      padding-left 50px
