.domdacha
  color $black
  background #FDF9F3
  h2:hover
    cursor pointer
    color #E2BA85
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #E2BA85
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #E2BA85
  .portfolio-case-link
    color: #E2BA85
    stroke: #E2BA85
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, flex-start)
    picture
      width 100%
    img
      width auto
@media (min-width: 550px)
  .domdacha
    color $white
    padding-left 0
    padding-right 75px
    background url('../img/domdacha/bg.png') top center\/cover no-repeat
    .portfolio-content-wrap
      padding-left 20px
    .portfolio-page-about,
    .portfolio-page-table td
      color $white
    .portfolio-image-wrap
      flex(row, nowrap, center, center)
      picture
        width auto
@media (min-width: 1023px)
  .domdacha
    .portfolio-content-wrap
      background rgba(#000000, 0.2)
@media (min-width: 1050px)
  .domdacha
    padding-left 0
    .portfolio-content-wrap
      padding-left 60px
@media (min-width: 1300px)
  .domdacha
    padding-left 0
    .portfolio-content-wrap
      padding-left 100px
@media (min-width: 1800px)
  .domdacha
    padding-left 0
    .portfolio-content-wrap
      padding-left 145px
