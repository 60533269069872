// Layout styles.

.global-wrap
  flex(column, nowrap, flex-start, stretch)
  min-height: 100vh
  overflow hidden

.main-wrap
  flex-grow: 2

.l-site-size
  width: 100%
  padding: 0 24px

.leaf-down
  position absolute
  top 12px
  left 50%
  transform translateX(-50%)
  color $black
  fonts(14px, 21px, 600)
  text-align center
  z-index 4
  text-transform lowercase
  &::after
    content ""
    position absolute
    width 20px
    height 11px
    bottom -14px
    left 50%
    transform translateX(-50%)
    background url('../img/leaf-arrow-black.svg') 0 0 no-repeat
.detail-leaf-down
  position absolute
  top 12px
  left 50%
  transform translateX(-50%)
  color $black
  fonts(14px, 21px, 600)
  text-align center
  z-index 4
  text-transform lowercase
  &::after
    content ""
    position absolute
    width 25px
    height 40px
    bottom -54px
    left 50%
    transform translateX(-50%)
    background url('../img/leaf-mouse.svg') 0 0 no-repeat
@media (min-width 568px)
  .l-site-size
    padding 0 20px

@media (min-width 1050px)
  .l-site-size
    padding 0 60px
  .leaf-down
    display none
@media (min-width 1300px)
  .l-site-size
    padding 0 100px
@media (min-width 1800px)
  .l-site-size
    padding 0 145px
