.oxyco
  color $black
  background $white url('../img/oxyco/bg-m.png') top center\/contain no-repeat
  h2:hover
    cursor pointer
    color #4179DD
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #4179DD
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #4179DD
  .portfolio-case-link
    color: #4179DD
    stroke: #4179DD
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width 100%
@media (min-width: 550px)
  .oxyco
    padding-right 80px
    background url('../img/oxyco/bg.png') top right\/contain no-repeat, linear-gradient(252.9deg, rgba(255, 253, 253, 0) 3.55%, #DBDBDB 100.9%);
