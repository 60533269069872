.ksmoto
  color $black
  background #F0F0F0 url('../img/ksmoto/bg-m.png') top center\/contain no-repeat
  h2:hover
    cursor pointer
    color #FECC08
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FECC08
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #FECC08
    color $black
  .portfolio-case-link
    color: $black
    stroke: $black
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .ksmoto
    padding-right 75px
    background url('../img/ksmoto/bg.png') top center\/cover no-repeat
