.germes
  color #332C2C
  background $white url('../img/germes/germes-bg.png') top center\/cover no-repeat
  padding-right 50px
  h2:hover
    cursor pointer
    color #A51111
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #A51111
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #A51111
  .portfolio-case-link
    color: #A51111
    stroke: #A51111
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
