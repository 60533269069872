.awm
  color $white
  background #404040 url('../img/awm/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #D9172A
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #D9172A
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #D9172A
  .portfolio-case-link
    color: #D9172A
    stroke: #D9172A
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width auto
@media (min-width: 550px)
  .awm
    padding-right 20px
    background #404040 url('../img/awm/bg.png') top center\/cover no-repeat
