.detail-physio
  padding-bottom 150px
  color $black
  .detail-image-wrap
    text-align center
    img
      width auto
      object-fit: cover
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #497FB4
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #497FB4
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#497FB4, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: linear-gradient(146.05deg, #B6B6B6 -4.8%, rgba(150, 150, 150, 0) 81.95%);
    .col
      display block
  .result-header
    color $black
  .result-text
    color #497FB4
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 180px
