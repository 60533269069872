.detail-teatr
  padding-bottom 150px
  color $white
  background #131e30
  .detail-image-wrap
    padding-top 0
    overflow visible
    img
      width 100%
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #199E7F
  .detail-page-wrap
    position relative
  .detail-page-about,
  .detail-page-table td
    color $black
  .detail-page-link
    background-color #199E7F
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#199E7F, 0.8)
  .detail-styles-container
    position relative
    z-index: 1
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
