.detail-spectr
  color $black
  padding-bottom 150px
  .detail-image-wrap
    flex(row, nowrap, center, flex-end)
    text-align center
    background: linear-gradient(116.88deg, #C45663 1.95%, #8F58A9 101.35%);
    img
      width auto
      display block
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #66429E
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #66429E
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#66429E, 0.8)
  .result-block
    padding-top 120px
    background: linear-gradient(154.43deg, #BB5670 27.39%, #9258A9 108.88%);
    margin-bottom -150px
    .col
      display block
  .result-header
    color $white
  .result-text
    color #66429E
    strong
      color $white
  .seo-block
    padding-top 120px
    padding-bottom 70px
