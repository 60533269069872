.rniz
  color $white
  background #1e232d url('../img/rniz/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #CC2F80
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #CC2F80
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #CC2F80
  .portfolio-case-link
    color: #CC2F80
    stroke: #CC2F80
  .portfolio-image-wrap
    padding 0 20px
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .rniz
    .portfolio-image-wrap
      padding 0
      img
        display none
    padding-right 50px
    background #1e232d url('../img/rniz/bg.png') top center\/cover no-repeat
