.detail-sintez
  color #332c2c
  background #DDDDDD
  height initial
  padding-top 100px
  padding-bottom 160px
  padding-right 0
  .detail-leaf-down
    color $black
    &::after
      background url('../img/leaf-mouse.svg') 0 0 no-repeat
  .detail-image-wrap
    padding-right 150px
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-top 0px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #FF5F00
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #FF5F00
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #df4f00
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 780px
    padding-right 80px
    padding-top 35px
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    max-width: 560px
    padding-bottom 80px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, flex-start)
    color #000000
    fonts(14px, 16px, normal)
  .sintez-color
    width 145px
    height 137px
    margin-bottom 20px
  .sintez-color-1
    svg *
      fill #ff5f00
  .sintez-color-2
    svg *
      fill #5bb12c
  .sintez-color-3
    svg *
      fill #000000
  .sintez-color-row
    flex(row, nowrap, space-between)
    width 115px
    margin-bottom 3px
    transition all 0.3s ease-out
  .sintez-color-row:first-child
    transform translateX(0)
  .sintez-color-row:nth-child(2)
    transform translateX(29px)
  .sintez-color-row:nth-child(3)
    transform translateX(0)
  .sintez-color-row:last-child
    transform translateX(29px)
  .sintez-color:hover
    .sintez-color-row:first-child
      transform translateX(29px)
    .sintez-color-row:nth-child(2)
      transform translateX(0)
    .sintez-color-row:nth-child(3)
      transform translateX(29px)
    .sintez-color-row:last-child
      transform translateX(0)
  .detail-font-name
    display block
    width 100%
    font-family 'Gotham Pro', sans-serif
    fonts(56px, 1.2, bold)
    padding-bottom 20px
  .detail-font-example
    display block
    max-width 700px
    font-family 'Gotham Pro', sans-serif
    fonts(24px, 40px, normal)
    white-space pre-wrap
@media (max-width 1400px)
  .detail-sintez
    padding-left 60px
    padding-right 0
    .detail-image-wrap
      padding-right 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
