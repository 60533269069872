.hartdiesel
  color $black
  background #EDEDED
  h2:hover
    cursor pointer
    color #005DAD
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #005DAD
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #005DAD
  .portfolio-case-link
    color: #005DAD
    stroke: #005DAD
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .hartdiesel
    padding-right 75px
