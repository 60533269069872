.fly
  color #332C2C
  background url('../img/fly-bg-small.png') no-repeat 0 0
  background-size contain
  h2:hover
    cursor pointer
    color #A9D1FF
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px #A9D1FF
  .portfolio-page-about
    max-width 530px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #5a5a5a
  .portfolio-page-link
    background-color #A9D1FF
  .portfolio-case-link
    color: #A9D1FF
    stroke: #A9D1FF
  .portfolio-image-wrap
    top 10%
    left 0
    width 100%
@media (min-width 550px)
  .fly
    background url('../img/fly-bg.jpg') no-repeat 0 0
    background-size cover
