.detail-autojack
  padding-top 100px
  padding-bottom 150px
  color $black
  background: linear-gradient(122.65deg, #FFFFFF 28.79%, #DDDDDD 51.17%, #A3A3A3 99.24%);
  .detail-image-wrap
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 0
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #1A61AC
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #1A61AC
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#1A61AC, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
