.detail-azbuka
  color $black
  padding-bottom 150px
  .detail-image-wrap
    text-align center
    img
      width 100%
      height 100vh
      object-fit cover
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #DA8339
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #DA8339
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#DA8339, 0.8)
  .result-block
    padding-top 120px
    background: linear-gradient(180deg, #FCF7F0 0%, #FFFFFF 100%);
    .col
      display block
  .result-header
    color $black
  .result-text
    color #DA8339
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 180px
