.imperator
  color $black
  background $white
  h2:hover
    cursor pointer
    color #9F6F55
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #9F6F55
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #9F6F55
  .portfolio-case-link
    color: #9F6F55
    stroke: #9F6F55
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, flex-end, flex-end)
    padding-top 0
    img
      margin-top -200px
      display block
      width 100%
      object-fit cover
@media (max-width 550px)
  .imperator
    padding 0
    background #efefef
    .portfolio-image-wrap
      flex(row, nowrap, flex-start, flex-end)
      height 100%
      width 100%
      position relative
      picture
        width 100%
      img
        margin 0
        display block
        width 100%
    .portfolio-content-wrap
      padding 0 24px 90px
      background $white
