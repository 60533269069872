.dent
  color $white
  background url('../img/dent/bg-m.jpg') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #D61311
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #D61311
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #D61311
  .portfolio-case-link
    color: #D61311
    stroke: #D61311
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (min-width: 550px)
  .dent
    background url('../img/dent/bg.png') top center\/cover no-repeat
