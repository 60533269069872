.azbuka
  color $black
  background #FEF8EE
  h2:hover
    cursor pointer
    color #DA8339
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #DA8339
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #DA8339
  .portfolio-case-link
    color: #DA8339
    stroke: #DA8339
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, flex-end, flex-start)
    img
      width auto
@media (min-width: 550px)
  .azbuka
    padding-right 340px
    background url('../img/azbuka/bg.png') top center\/cover no-repeat
    .portfolio-image-wrap
      position relative
      height 100%
      flex(row, nowrap, flex-end, center)
      img
        width auto
