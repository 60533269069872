.linero
  padding-right 0
  color $white
  background url('../img/linero/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #C70202
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #C70202
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #C70202
  .portfolio-case-link
    color: #C70202
    stroke: #C70202
@media (min-width: 550px)
  .linero
    background url('../img/linero/bg.png') top right\/cover no-repeat
    .portfolio-image-wrap
      flex(row, nowrap, flex-end, flex-end)
      padding-top 50px
      img
        display block
        width auto
