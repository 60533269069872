.detail-oxyco
  padding-bottom 150px
  color $black
  background: url('../img/oxyco/bg-big.png') no-repeat top center\/100% auto, linear-gradient(180.15deg, rgba(196, 196, 196, 0) 31.27%, #DDDDDD 100%);
  .detail-image-wrap
    padding-top 150px
    img
      width auto
  .detail-page-wrap
    padding-top 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #4179DD
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #4179DD
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#4179DD, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
