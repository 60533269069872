.konstruktiv
  padding-right 24px
  color $black
  background $white url('../img/konstruktiv/bg.png') top right\/contain no-repeat
  h2:hover
    cursor pointer
    color #FF6600
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FF6600
  .portfolio-page-about
    max-width 750px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #626262
  .portfolio-page-link
    background-color #FF6600
  .portfolio-case-link
    color: #FF6600
    stroke: #FF6600
  .portfolio-image-wrap
    flex(row, nowrap, center, flex-end)
    img
      display block
      width auto
@media (min-width 550px)
  .konstruktiv
    .portfolio-content-wrap
      height 100%
      background transparent linear-gradient(90deg, #fff 0%, rgb(255,255,255) 55%, rgba(255,255,255,0) 100%)
@media (max-width 550px)
  .konstruktiv
    background $white url('../img/konstruktiv/bg-m.png') top center\/cover no-repeat
    .portfolio-content-wrap
      flex(column, nowrap, center)
      background transparent
    .portfolio-image-wrap
      height 100%
      position static
      flex(row, nowrap, center, center)
