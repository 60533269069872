.baltstroy
  color $white
  background: linear-gradient(2.7deg, #386F83 -7.61%, #56BBE1 98.16%)
  h2:hover
    cursor pointer
    color #F1C450
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #F1C450
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #F1C450
  .portfolio-case-link
    color: #F1C450
    stroke: #F1C450
  .portfolio-image-wrap
    position relative
    height 100%
    padding-left 24px
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .baltstroy
    padding-right 0
    background: url('../img/baltstroy/bg.png') top right no-repeat, linear-gradient(2.7deg, #386F83 -7.61%, #56BBE1 98.16%);
    .portfolio-image-wrap
      padding-left 0
