.invest
  color $white
  background $white url('../img/invest/invest-bg.jpg') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #FDC530
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FDC530
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #FDC530
  .portfolio-case-link
    color: #FDC530
    stroke: #FDC530
  .portfolio-image-wrap
    flex(row, nowrap, center, flex-end)
    padding-top 50px
    img
      width auto
