.apteka
  color $black
  background linear-gradient(180deg, #E9E9E9 -4.75%, #FEFEFE 60.18%)
  h2:hover
    cursor pointer
    color #AC0202
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #AC0202
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #AC0202
  .portfolio-case-link
    color: #AC0202
    stroke: #AC0202
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
      flex(row, nowrap, center, center)
    img
      width 100%
@media (min-width: 550px)
  .apteka
    padding-right 75px
    background: linear-gradient(119.84deg, #F5F5F5 36.89%, #EEEEEE 83.01%)
