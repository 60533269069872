.detail-medel
  padding-bottom 150px
  color $black
  background #efefef
  .detail-image-wrap
    background: #93A7AA
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 100px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #1C766E
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #1C766E
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#1C766E, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
