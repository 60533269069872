.medsnab
  color #332C2C
  background url('../img/medsnab/medsnab-bg.png') top center\/cover no-repeat, linear-gradient(298.88deg, #176992 5.78%, #B3E1FF 87.98%);
  h2:hover
    cursor pointer
    color #004699
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #004699
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #004699
  .portfolio-case-link
    color: #004699
    stroke: #004699
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
@media (max-width: 550px)
  .medsnab
    background: url('../img/medsnab/medsnab-bg-m.png') top center\/contain no-repeat, linear-gradient(143.4deg, #CDEBFF 5.43%, #2879A3 75.11%);
