.detail-apteka
  padding-bottom 150px
  color $black
  .detail-image-wrap
    padding-top 95px
    text-align center
    background: linear-gradient(167.29deg, #EAEAEA -4.52%, rgba(196, 196, 196, 0) 73.4%, #FFFFFF 73.4%);
    img
      width auto
      object-fit: cover
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #AC0202
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #AC0202
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#AC0202, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: linear-gradient(149.16deg, #ECECEC -4.26%, #FFFFFF 70.29%);
    .col
      display block
  .result-header
    color $black
  .result-text
    color #AC0202
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 120px
