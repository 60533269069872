.zosterin
  color $white
  background url('../img/zosterin-bubbles-4.png') no-repeat 10% -23px,
             url('../img/zosterin-bubbles-5.png') no-repeat 100% -1px,
             url('../img/zosterin-bubbles-6.png') no-repeat 0 40%,
             url('../img/zosterin-bubbles-7.png') no-repeat 50% 35%,
             url('../img/zosterin-bubbles-8.png') no-repeat 15% 90%,
             url('../img/zosterin-bubbles-9.png') no-repeat 30% 119%,
             url('../img/zosterin-bubbles-10.png') no-repeat 100% 101%,
             linear-gradient(256.09deg, #83DACF 24%, #3BB0A2 57.2%, #0AA28F 93%)
  h2:hover
    color #E2FF00
  .tags-item
    a, span
      color inherit
      text-decoration: none
      box-shadow 0 2px 0 0px #E2FF00
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    color #25AA99
    background-color #e2ff00
  .portfolio-case-link
    color: #e2ff00
    stroke: #e2ff00
  .portfolio-image-wrap
    top 93px
    left -1%
    // width 320px
    transform scale(1.15)
@media (max-width 550px)
  .zosterin
    background url('../img/zosterin-m-bubbles-1.png') no-repeat 0 0,
               url('../img/zosterin-m-bubbles-2.png') no-repeat 100% 60%,
               linear-gradient(255.08deg, #83DACF 6.07%, #3BB0A2 60.53%, #0AA28F 119.27%)
    .tags-item
      a, span
        box-shadow 0 2px 0 0px #5A0089
