.detail-bureau
  color #332c2c
  background $white
  height initial
  padding-bottom 160px
  padding-right 0
  .detail-leaf-down
    color $black
    &::after
      background url('../img/leaf-mouse.svg') 0 0 no-repeat
  .detail-image-wrap
    padding-right 0
    text-align center
    img
      width auto
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-top 80px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #1F4E8D
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #1F4E8D
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #0F3E7D
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 780px
    padding-right 80px
    padding-top 35px
  .detail-styles-row
    flex(row, nowrap, space-between, flex-end)
    width 100%
    margin-bottom 50px
  .detail-styles-colors
    flex(row, nowrap, flex-start, flex-end)
    font-family 'PT Sans'
    fonts(16px, 21px)
    padding-bottom 5px
  .styles-colors-item
    flex(column, nowrap, center, flex-start)
    color $black
    fonts(14px, 16px, normal)
  .bureau-color
    width 105px
    height 32px
    margin-bottom 30px
  .bureau-blue
    height 64px
    background-color #16265C
  .bureau-gray
    background-color: #C4C4C4
  .bureau-lblue
    background-color: #E9F1F9
  .bureau-lgray
    background-color: #f5f5f5
  .detail-ui-kit
    padding-top 50px
    width 100%
    flex(row, nowrap, space-between, flex-start)
  .detail-ui-font
    font-family 'PT Sans'
  .detail-font-example
    display block
    color rgba(#E9F1F9, 0.6)
    fonts(150px, 1, bold)
  .detail-font-name
    fonts(26px, 31px, bold)
  .detail-styles-filter
    width 100%
    padding 33px 0
    // background-color #E8F1F9
  .filter-quantity-wrapper
    font-family 'PT Sans'
    fonts(16px, 21px, bold)
  .detail-form
    font-family 'PT Sans'
    width 100%
    fonts(16px, 21px, normal)
    padding-bottom 56px
  .detail-form-slider
    margin 24px 0 10px
    height 6px
  .detail-form-range
    position absolute
    left -9999px
    width 1px
    height 1px
    background none
    border none
  .noUi-target
    background: #FAFAFA
    border-radius: 0
    border: none
    box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB;
  .noUi-base, .noUi-connects
    background-color #C4C4C4
    border-radius 0
  .noUi-connect
    background: #417BBC
    border-radius: 0
  .noUi-handle-lower
    display none
  .noUi-horizontal .noUi-handle
    cursor pointer
    top -7px
    width 18px
    height 18px
    border-radius 50%
    background: linear-gradient(180deg, #3F78B9 0%, #1F478A 100%)
    box-shadow initial
    border none
    &::before,
    &::after
      display none
  .detail-form-row
    flex(row, nowrap, space-between)
  .filter-taxation
    & > span
      display block
      font-family 'PT Sans'
      fonts(16px, 21px, bold)
      margin-bottom 14px
  .filter-taxation-buttons
    flex(row, nowrap, flex-start, center)
  .filter-button
    display block
    width 125px
    padding 4px 0 5px
    border 1px solid #417BBC
    color $black
    font-family: 'PT Sans';
    fonts(16px, 21px, normal)
    text-align center
    text-decoration none
    &:first-child
      border-radius 4px 0 0 4px
    &:last-child
      border-radius 0 4px 4px 0
    &.active
      color $white
      font-weight: bold
      background linear-gradient(180deg, #417BBC 0%, #1D4588 100%)
@media (max-width 1400px)
  .detail-bureau
    padding-right 0
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
