.rusles
  color #332C2C
  background $white
  padding-right 0
  h2:hover
    cursor pointer
    color #0C753C
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #0C753C
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #0C753C
  .portfolio-case-link
    color: #0C753C
    stroke: #0C753C
  .portfolio-image-wrap
    flex(row, nowrap, center, flex-end)
    padding-top 50px
    img
      width auto
      margin-left -150px
@media (max-width 550px)
  .rusles
    padding 0
    .portfolio-image-wrap
      flex(row, nowrap, flex-start, flex-end)
      height 100%
      width 100%
      position relative
      picture
        width 100%
      img
        margin 0
        display block
        width 100%
    .portfolio-content-wrap
      padding 0 24px 90px
      background #f0f0f0
      // height 100%
