.detail-medsnab
  padding-bottom 150px
  color $black
  background linear-gradient(136.15deg, #CDEBFF 4.81%, #A3D5F4 24.4%, #64A4C8 53.63%, #2879A3 82.67%);
  .detail-image-wrap
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 $white
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color $white
    color #136690
    transition all .2s ease-out
    &:hover
      background-color: rgba($white, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
