.roig
  color $white
  background #08215A url('../img/roig/bg-m.png') top center\/cover no-repeat
  h2:hover
    cursor pointer
    color #B90D00
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #B90D00
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #B90D00
  .portfolio-case-link
    color: #B90D00
    stroke: #B90D00
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, flex-end)
    padding-bottom 100px
    img
      width auto
@media (min-width: 550px)
  .roig
    padding-right 75px
    background #08215A url('../img/roig/bg.png') top center\/cover no-repeat
