.detail-kallista
  padding-bottom 150px
  color $black
  background linear-gradient(180deg, #E5EEEE 8.93%, #88B7BB 100%);
  height initial
  .detail-image-wrap
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #669092
  .detail-page-wrap
    padding-top 50px
    padding-bottom 50px
  .detail-page-about,
  .detail-page-table td
    color $black
  .detail-page-link
    background-color #669092
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#669092, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
  .result-block
    background-color $white
  .result-header
    color $black
  .result-text
    color #669092
    strong
      color $black
  .seo-block
    padding-top 120px
