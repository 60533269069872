.detail-rusles
  padding-bottom 0
  color $black
  background #FFFFFF
  height initial
  .detail-image-wrap
    flex(column, nowrap, flex-end, center)
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #0C753C
  .detail-page-wrap
    padding-top 50px
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #0C753C
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#0C753C, 0.8)
  .detail-page-wrap
    padding-top 100px
    background #f8f8f8
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
  .result-block
    background-color $white
  .result-header
    color $black
  .result-text
    color #0C753C
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 180px
