.kallista
  color $black
  grid-template-columns: 700px 1fr;
  background linear-gradient(359.72deg, #9ABEC0 0.52%, rgba(210, 224, 225, 0) 156.76%);
  h2:hover
    cursor pointer
    color #3E7D80
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #3E7D80
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #3E7D80
  .portfolio-case-link
    color: #3E7D80
    stroke: #3E7D80
  .portfolio-content-wrap
    max-width: 700px
  .portfolio-image-wrap
    padding 0 24px
    position relative
    height 100%
    width 100%
    flex(row, nowrap, center, center)
    img
      display block
      width auto
      height auto
      filter drop-shadow(0px 4px 76px rgba(0, 0, 0, 0.15))
@media (min-width: 550px)
  .kallista
    padding-right 0
    .portfolio-image-wrap
      position relative
      height 100%
      width 100%
      padding 0
      flex(row, nowrap, flex-end, flex-end)
      img
        display block
        width auto
        height auto
