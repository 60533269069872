.skmera
  color $black
  background $white url('../img/skmera/bg-m.png') top left\/100% auto no-repeat
  h2:hover
    cursor pointer
    color #005294
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #005294
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #005294
  .portfolio-case-link
    color: #005294
    stroke: #005294
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .skmera
    padding-right 75px
    background $white url('../img/skmera/bg.png') top left\/cover no-repeat
    .portfolio-image-wrap
      flex(row, nowrap, center, flex-start)
      padding-top 100px
