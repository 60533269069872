.caleo
  color $white
  background #9F0203
  h2:hover
    cursor pointer
    color #F9EA87
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #F9EA87
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #F9EA87
    color: #9F0203
  .portfolio-case-link
    color: #F9EA87
    stroke: #F9EA87
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
      object-fit: cover;
      height 100%
@media (min-width 550px)
  .caleo
    padding-right 0
    .portfolio-image-wrap
      padding-top 0
      flex(row, nowrap, center, stretch)
@media (max-width 550px)
  .caleo
    .portfolio-image-wrap
      position relative
      height 100%
      align-items: center;
      padding 60px 0 0
      picture
        width 100%
      img
        width 100%
