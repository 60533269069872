.detail-setinik
  padding-bottom 150px
  color $black
  background: url('../img/setinik/bg-big.png') no-repeat top center
  .detail-image-wrap
    img
      width auto
  .detail-page-wrap
    padding-top 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #D61903
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #D61903
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#D61903, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
