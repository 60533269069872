.detail-zosterin
  color $white
  background url('../img/zosterin-bubbles-1.png') no-repeat right 0,
    url('../img/zosterin-bubbles-2.png') no-repeat left 30%,
    url('../img/zosterin-bubbles-3.png') no-repeat right 100%,
    linear-gradient(218.85deg, #3BB0A2 10.26%, #0AA18F 94.88%)
  height initial
  padding-top 40px
  padding-bottom 160px
  padding-right 0
  .detail-leaf-down
    color $white
    &::after
      background url('../img/leaf-mouse-white.svg') 0 0 no-repeat
  .detail-image-wrap
    padding-left 100px
    padding-right 145px
  .detail-page-wrap
    min-height 70vh
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #5A0089
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #E6E6E6
  .detail-page-link
    background-color #5A0089
    color #ffffff
    transition all .2s ease-out
    &:hover
      background-color: #D2EE00
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 660px
    padding-right 80px
    padding-top 35px
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    // max-width: 416px
    padding-bottom 80px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, center)
    color $white
    fonts(14px, 16px, normal)
  .zosterin-color
    margin-bottom 30px
    width 100px
    height 100px
    border-radius: 30px;
  .zosterin-blue-chill
    background #429288
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.5  );
  .zosterin-purple
    background #822181
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.5);
  .zosterin-green
    background #22832d
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.5)
  .detail-ui-kit
    padding-top 50px
    width 100%
    flex(row, nowrap, space-between, flex-start)
  .detail-ui-icons
    flex(row, nowrap, space-between, center)
    width 100%
    height 100px
    padding 0 36px
    background-color $white
    border-radius 30px
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.5)
  .detail-ui-buttons
    flex(column, nowrap, space-between, flex-start)
    height 100px
    padding-left 10px
  .site-button
    width 273px
    padding 5px 0
    text-align center
    color $white
    text-decoration none
    font-family 'PT Sans', sans-serif
    fonts(20px, 26px)
    background: #429288;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
  .site-button-hover
    background: #68a8a0
@media (max-width 1400px)
  .detail-zosterin
    padding-left 60px
    padding-right 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
