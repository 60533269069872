.panoil
  color $white
  background #000000
  h2:hover
    cursor pointer
    color #3B4B9B
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #3B4B9B
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #3B4B9B
  .portfolio-case-link
    color: #3B4B9B
    stroke: #3B4B9B
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, flex-end, center)
    img
      width auto
@media (min-width: 550px)
  .panoil
    padding-right 20px
