.monolit
  color #332C2C
  background #FFC91E
  h2:hover
    cursor pointer
    color #484848
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #FFFFFF
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color #484848
  .portfolio-case-link
    color: #484848
    stroke: #484848
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
      margin-bottom -50px
@media (max-width 550px)
  .monolit
    padding 0
    .portfolio-image-wrap
      padding 70px 15px 0
    .portfolio-content-wrap
      background #484848
      color $white
      padding 0 24px 90px
      .tags-item
        a, span
          box-shadow 0 2px 0 0px #FFD140
@media (min-width 550px)
  .monolit
    background linear-gradient(90deg, #FFC91E 0%, #FFC91E 72%, #484848 72%);
