.lamp
  color #232323
  background $lamp-bg url('../img/lamplandia-lamp-2.png') 45px 0\/50px auto no-repeat
  h2:hover
    cursor pointer
    color $violet
  .tags-item
    a, span
      box-shadow 0 2px 0 0px $violet
  .portfolio-page-about,
  .portfolio-page-table td
    color #494949
  .portfolio-page-link
    background-color $violet
  .portfolio-case-link
    color: $violet
    stroke: $violet
  .portfolio-image-wrap
    img
      transform: scale(1.2);
@media (min-width 960px)
  .lamp
    background url('../img/lamplandia.png') right bottom no-repeat,
               url('../img/lamplandia-lamp.png') 60% -100px no-repeat,
               $lamp-bg
    .portfolio-image-wrap
      align-items center
      img
        transform scale(1)
