.nasledie
  color $black
  background #F6FDFF
  h2:hover
    cursor pointer
    color #EE2C35
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #EE2C35
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #EE2C35
  .portfolio-case-link
    color: #EE2C35
    stroke: #EE2C35
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .nasledie
    padding-right 75px
    background url('../img/nasledie/bg.png') top right no-repeat
