.mediflex
  color $white
  background #00549C url('../img/mediflex/mediflex-bg.png') no-repeat top right
  h2:hover
    cursor pointer
    color #80A9CE
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #80A9CE
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #80A9CE
  .portfolio-case-link
    color: #80A9CE
    stroke: #80A9CE
  .portfolio-image-wrap
    flex(row, nowrap, center, center)
    padding-top 50px
    img
      width auto
