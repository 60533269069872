.detail-dent
  padding-bottom 0
  color $black
  background #FFFFFF url('../img/dent/bg-big.jpg') no-repeat 0 0\/contain
  height initial
  .detail-image-wrap
    flex(column, nowrap, center, flex-start)
    padding-left 50px
    img
      width auto
      max-width: 60%
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #D61311
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #D61311
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#D61311, 0.8)
  .detail-page-wrap
    padding-top 100px
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
  .result-block
    background linear-gradient(76.5deg, #98979C -4.25%, #DBDBDC 95.35%);
  .result-header
    color $white
  .result-text
    color #D61311
    strong
      color #D61311
  .seo-block
    padding-top 120px
    padding-bottom 180px
@media (max-width 1440px)
  .detail-dent
    .detail-page-wrap
      padding-top 0
