.baltrem
  color $white
  grid-template-columns: 750px 1fr;
  background #1c1c1c
  h2:hover
    cursor pointer
    color #9F3D0C
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #9F3D0C
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #9F3D0C
  .portfolio-case-link
    color: #9F3D0C
    stroke: #9F3D0C
  .portfolio-image-wrap
    position relative
    height 100%
    max-height: 750px;
    flex(row, nowrap, center, stretch)
    img
      display block
      width 100%
      height 100%
      object-fit cover
@media (min-width 550px)
  .baltrem
    padding-right 0
