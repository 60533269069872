.detail-header
    position fixed
    z-index 5
.detail-image-wrap
  position relative
  text-align center
  min-height 100vh
  img
    max-width 100%
    width 100%
    height auto
.detail-page
  min-width 1000px
  position relative
  height initial
  min-height initial
  padding-bottom 200px
  h2
    fonts(53px, 62px, 800)
    margin-top 0
    margin-bottom 19px
.detail-leaf-down.white
  color $white
  &:after
    background url('../img/leaf-mouse-white.svg') 0 0 no-repeat

.detail-page-wrap
  display grid
  grid-template-columns 1fr 1fr
  grid-gap 100px
  padding: 0 60px
  min-height: 78vh
.detail-content-wrap
  position relative
  z-index 2
  max-width 90%
.tags-list
  flex(row, wrap, flex-start, flex-start)
.tags-item
  margin-right 20px
  a
    fonts(14px, 21px, 400)
    color inherit
    text-decoration none
.detail-page-about
  display block
  margin 60px 0 45px
  fonts(14px, 21px, 500)

.detail-page-table
  margin-bottom 60px
  color inherit
  fonts(14px, 1.5, 500)
  th
    vertical-align top
    text-align left
    min-width 135px
    font-weight: 800
.detail-page-link
  position absolute
  bottom 0
  left 0
  border-radius 0 104px 0 0
  flex(row, nowrap, center, center)
  width 340px
  height 160px
  padding-top 10px
  padding-left 30px
  fonts(18px, 26px, 800)
  text-transform: uppercase
  text-decoration: none
  text-align center
@media (min-width 1300px)
  .detail-page-wrap
    padding 0 100px
@media (min-width 1800px)
  .detail-page-wrap
    padding 0 145px
@media (max-width 1400px)
  .detail-page-link
    width 280px
    height 120px
    padding-left 0

.detail-page-wrap.result-block
  min-height: auto
  padding-top 80px
  padding-bottom 60px
  padding-left 60px
  .col
    flex(column, nowrap, center, start)
@media (min-width 1300px)
  .detail-page-wrap.result-block
    padding-left 100px
@media (min-width 1600px)
  .detail-page-wrap.result-block
    padding-left 260px
.result-header
  display block
  margin-bottom 55px
  fonts(53px, 1.08, 800)
.result-text
  fonts(88px, 1.08, 800)
  strong
    fonts(125px, 1.08, 800)
.detail-page-wrap.seo-block
  padding-top: 120px
  padding-bottom 50px
  min-height: auto
.detail-seo-container
  flex(row, nowrap, center, center)
