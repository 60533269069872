.bioforest
  color $black
  background $white url('../img/bioforest/bg-m.png') top center\/contain no-repeat
  h2:hover
    cursor pointer
    color #1AA21A
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #1AA21A
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #1AA21A
  .portfolio-case-link
    color: #1AA21A
    stroke: #1AA21A
  .portfolio-image-wrap
    padding 0 36px
    position relative
    height 100%
    flex(row, nowrap, flex-end, center)
    img
      width auto
      filter drop-shadow(20px 12px 40px rgba(0, 0, 0, 0.34))
@media (min-width: 550px)
  .bioforest
    padding-right 100px
    background url('../img/bioforest/bg.png') top right\/auto 100% no-repeat
    .portfolio-image-wrap
      padding 0
      img
        filter none
