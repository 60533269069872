@font-face
	font-family: 'Circe'
	font-style: normal
	font-display: swap
	font-weight: 400
	src: url('./../fonts/Circe-Regular.ttf')

@font-face
	font-family: 'Circe'
	font-style: normal
	font-display: swap
	font-weight: 300
	src: url('./../fonts/Circe-Light.ttf')

@font-face
	font-family: 'Circe'
	font-style: normal
	font-display: swap
	font-weight: 200
	src: url('./../fonts/Circe-ExtraLight.ttf')

@font-face
	font-family: 'Circe'
	font-style: normal
	font-display: swap
	font-weight: 100
	src: url('./../fonts/Circe-Thin.ttf')

@font-face
	font-family: 'Circe'
	font-style: normal
	font-display: swap
	font-weight: 700
	src: url('./../fonts/Circe-Bold.ttf')

@font-face
	font-family: 'Circe'
	font-style: normal
	font-display: swap
	font-weight: 800
	src: url('./../fonts/Circe-ExtraBold.ttf')
