.detail-lamp
  color #332C2C
  background url('../img/lamplandia-lamp.png') no-repeat 75% 0,linear-gradient(337deg, #EAF1F9 7%, #FFFFFF 88%);
  height initial
  padding-top 50px
  padding-bottom 160px
  .detail-image-wrap
    padding-top 50px
    position relative
    background none
    text-align center
    img
      width auto
      // max-width: 1400px
  .tags-item
    a, span
      box-shadow 0 4px 0 0 $violet
  .detail-page-about,
  .detail-page-table td
    color #A4A4A4
  .detail-page-link
    background-color $violet
    color $white
    transition all .2s ease-out
    &:hover
      background-color: #2c2c3e
  .detail-styles-container
    width 100%
    max-width 760px
  .detail-styles-colors
    flex(row, nowrap, space-between, center)
    padding-top 40px
    width 100%
    max-width: 430px
  .styles-colors-item
    flex(column, nowrap, space-between, center)
    height 125px
    div
      width 80px
      height 80px
      border-radius 50%
    span
      color #2c2c3e
  .detail-styles-ui
    padding-top 50px
    font-family 'Segoe UI'
    position relative
    width 100%
    flex(row, nowrap, space-between, center)
  .detail-font-example
    display block
    font-family 'Segoe UI', sans-serif
    color: rgba(124, 121, 229, 0.33)
    fonts(194px, 258px, bold)
  .detail-font-name
    font-family: 'Segoe UI', sans-serif
    fonts(24px, 32px, 600)
  .detail-ui-kit
    padding-top 50px
    width 100%
    max-width 430px
    flex(row, nowrap, space-between, flex-start)
  .ui-kit-column
    flex(column, nowrap)
    width 50%
    span
      fonts(16px, 21px, normal)
      margin-bottom 20px
  .ui-kit-caption
    margin-top 20px
  .detail-ui-buttons,
  .detail-ui-links,
  .detail-ui-checkboxes
    flex(column, wrap, space-between)
  .site-button
    display: inline-block
    width 156px
    font-family 'Segoe UI', sans-serif
    margin-bottom 20px
    padding: 11px 10px 13px
    color: #fff
    fonts(16px, 21px, normal)
    text-decoration: none
    text-align center
    background: #2C2C3E;
    border: none
    box-shadow: 5.45455px 5.45455px 27.2727px rgba(0, 0, 0, 0.15);
  .site-button-hover
    background: #7D79E4;
    box-shadow: 5.45455px 5.45455px 27.2727px rgba(0, 0, 0, 0.15);
    text-decoration: none
  .block-link
    position: relative
    display: inline-block
    padding: 0
    color #1F272D
    fonts(16px, 19px, normal)
    font-family 'Segoe UI', sans-serif
    text-decoration: none
  .block-link.active-page
    color #7D79E4
.lamp-colors-dark
  background #2C2C3E
.lamp-colors-blue
  background #7D79E4
.lamp-colors-white
  background $white

@media (max-width 1400px)
  .detail-lamp
    padding-left 60px
    padding-right 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-font-example
      font-size 132px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      justify-content center
      min-width 100px
