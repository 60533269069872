.bus
  background: #29A54D
  background linear-gradient(180deg, #29A54D 29.9%, #FFFFFF 30.1%);
  color $black
  h2:hover
    cursor pointer
    color #F5C017
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #F5C017
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #F5C017
  .portfolio-case-link
    color: #F5C017
    stroke: #F5C017
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, flex-end, center)
    padding-top 0
    img
      width auto
@media (max-width 550px)
  .bus
    .portfolio-content-wrap
      padding 0 24px
@media (min-width 550px)
  .bus
    color $white
    background url('../img/bus/bg.jpg') no-repeat top center\/cover
