.popup-is-active > .menu
  display: block
  overflow-x hidden

.menu
  // position relative
  display: none
  width: 100%
  max-height: 100%
  padding: 80px 32px 0
  color: $l-gray
  overflow: auto

.menu-title
  margin: 0 0 47px
  fonts(42px, 43px, 800)

.menu-wrap
  margin-bottom: 70px

.menu-item
  padding: 11px 0
  fonts(24px, 25px)
  a
    color: $l-gray
    text-decoration: none
    &:hover
      text-decoration: underline
  .bold-item
    font-weight: 700
.header-contacts
  padding-bottom: 80px
  color $black
  a
    color $black
.menu-callback-form
  position relative
  color $black
  width 100vw
  padding-top 80px
  padding-left 32px
  padding 80px 32px 100px
  max-width 820px
  margin: 90px 0 0
  margin-left -32px
  margin-bottom 20px
  background-color $l-gray
  flex-direction column
  justify-content: flex-start
  form
    .site-input
      width: 80%
    .callback-form-label
      display: inline-block
      color $black
      width: 100%
      margin-bottom: 38px
      fonts(24px, 35px, 800)

@media (min-width 550px)
  .menu
    padding-top 50px
  .menu-wrap
    // display grid
    // grid-template-columns 1fr 1fr
    justify-content space-evenly
    column-gap 50px

@media (min-width 1050px)
  .menu
    padding-left 60px
  .popup-is-active
    .menu
      padding-top 0
      order 3
      flex(row, nowrap, space-between, flex-start)
  .menu-callback-form
    width auto
    position absolute
    padding 80px 45px
    padding-top 110px
    height calc(100% + 20px)
    top -100px
    right 0
    overflow-y auto
@media (min-width 1300px)
  .menu-wrap
    padding-right 820px
  // .menu-wrap:first-of-type
    // grid-template-columns 1fr 1fr 1fr
  // .menu-wrap:nth-of-type(2)
    // grid-template-columns 1fr 1fr 1fr 1fr
  .menu-callback-form
    display flex
