.detail-webdush
  padding-bottom 150px
  color $black
  .detail-image-wrap
    text-align center
    img
      width auto
  .detail-content-wrap
    max-width: 100%
  .detail-page-wrap
    padding-top 70px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #0065B3
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #0065B3
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#0065B3, 0.8)
  .result-block
    margin-bottom -150px
    padding-bottom 200px
    padding-top 120px
    background: linear-gradient(180deg, #E8F4FF 0%, #FCFEFF 100%);
  .result-header
    color $black
  .result-text
    color #0065B3
    strong
      color $black
  .seo-block
    padding-top 120px
    padding-bottom 180px
