.detail-tarasov
  padding-bottom 150px
  color $black
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 21.3%, #DDDDDD 70.48%);  .detail-image-wrap
    flex(row, nowrap, center, center)
    text-align center
    img
      width auto
  .detail-page-wrap
    padding-top 0
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #DB3C13
  .detail-page-about,
  .detail-page-table td
    color #6A6769
  .detail-page-link
    background-color #DB3C13
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#DB3C13, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
