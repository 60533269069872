.motodor
  color $white
  background url('../img/motodor/bg-m.png') top center\/cover no-repeat, linear-gradient(180deg, #6C6C6C -10.69%, rgba(103, 103, 103, 0) 162.47%);
  h2:hover
    cursor pointer
    color #ECC765
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #ECC765
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #ECC765
  .portfolio-case-link
    color: #ECC765
    stroke: #ECC765
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .motodor
    padding-right 75px
    background url('../img/motodor/bg.png') top center\/cover no-repeat, linear-gradient(180deg, #33302E 0%, #555451 51.07%, #464645 94.79%);
