.chiphunt
  color $white
  background #444444
  h2:hover
    cursor pointer
    color #B04D33
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #B04D33
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #B04D33
  .portfolio-case-link
    color: #B04D33
    stroke: #B04D33
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, flex-end)
    img
      width auto
@media (min-width: 550px)
  .chiphunt
    padding-right 0
    background #444444 url('../img/chiphunt/bg.png') 100% 45px no-repeat
