@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("./../fonts/Circe-Regular.ttf");
}
@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("./../fonts/Circe-Light.ttf");
}
@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("./../fonts/Circe-ExtraLight.ttf");
}
@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("./../fonts/Circe-Thin.ttf");
}
@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("./../fonts/Circe-Bold.ttf");
}
@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("./../fonts/Circe-ExtraBold.ttf");
}
html {
  width: 100%;
  height: 100%;
}
html.popup-is-active {
  overflow: hidden;
}
body {
  max-width: 1920px;
  min-height: 100%;
  margin: 0 auto;
  background-color: #fff;
  color: #332c2c;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: 'Circe', sans-serif;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
}
body img {
  max-width: 100%;
}
body * {
  box-sizing: border-box;
}
body.popup-is-active {
  overflow: hidden;
}
.page-block {
  padding: 18px 0 53px;
}
.page-block .page-title {
  margin-bottom: 37px;
}
.page-title {
  margin: 0;
  font-size: 32px;
  line-height: 43px;
  font-weight: 700;
}
.page-subtitle {
  margin: 0;
  font-size: 32px;
  line-height: 43px;
  font-weight: 600;
}
.block-title {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.block-subtitle {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.shop-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
.upper-title {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-transform: uppercase;
}
.content-zone {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.content-zone p {
  margin: 0;
}
.cancel-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-input {
  display: inline-block;
  max-width: 100%;
  padding: 11px 0;
  border: 2px solid #626262;
  border-left: none;
  border-right: none;
  border-top: none;
  background: transparent;
  color: #626262;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}
.is-invalid {
  border-color: #f03333;
  box-shadow: 0 12px 10px -5px rgba(240,51,51,0.1);
}
.global-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  overflow: hidden;
}
.main-wrap {
  flex-grow: 2;
}
.l-site-size {
  width: 100%;
  padding: 0 24px;
}
.leaf-down {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: #332c2c;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
  z-index: 4;
  text-transform: lowercase;
}
.leaf-down::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 11px;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  background: url("../img/leaf-arrow-black.svg") 0 0 no-repeat;
}
.detail-leaf-down {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: #332c2c;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
  z-index: 4;
  text-transform: lowercase;
}
.detail-leaf-down::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 40px;
  bottom: -54px;
  left: 50%;
  transform: translateX(-50%);
  background: url("../img/leaf-mouse.svg") 0 0 no-repeat;
}
@media (min-width: 568px) {
  .l-site-size {
    padding: 0 20px;
  }
}
@media (min-width: 1050px) {
  .l-site-size {
    padding: 0 60px;
  }
  .leaf-down {
    display: none;
  }
}
@media (min-width: 1300px) {
  .l-site-size {
    padding: 0 100px;
  }
}
@media (min-width: 1800px) {
  .l-site-size {
    padding: 0 145px;
  }
}
.header {
  position: relative;
  z-index: 9;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 13px;
}
.header.popup-is-active {
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #332c2c;
}
.header.popup-is-active .header-menu-button {
  z-index: 11;
}
.header.popup-is-active .header-menu-button::before,
.header.popup-is-active .header-menu-button::after {
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(45deg);
  background: #d4d4d4;
}
.header.popup-is-active .header-menu-button::after {
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%) rotate(-45deg);
  background: #d4d4d4;
}
.header.popup-is-active .header-menu-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.header.popup-is-active .header-logo {
  z-index: 11;
}
.header.modal-is-active {
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #332c2c;
}
.header.modal-is-active .modal-callback-close {
  z-index: 11;
}
.header.modal-is-active .modal-callback-close::before,
.header.modal-is-active .modal-callback-close::after {
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(45deg);
  background: #332c2c;
}
.header.modal-is-active .modal-callback-close::after {
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%) rotate(-45deg);
  background: #332c2c;
}
.header.modal-is-active .modal-callback-close:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.header-logo {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: 17px;
  z-index: 1;
}
.header-logo svg {
  max-width: 100%;
  height: auto;
}
.header-menu-button {
  position: relative;
  width: 34px;
  height: 24px;
  margin-left: 13px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}
.header-menu-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.header-menu-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0deg);
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background: #332c2c;
  transition: all 0.3s ease;
}
.header-menu-button::after {
  content: '';
  position: absolute;
  top: 12px;
  right: 0;
  transform: rotate(0deg);
  width: 50%;
  height: 4px;
  border-radius: 10px;
  background: #332c2c;
  transition: all 0.3s ease;
}
.modal-callback-close {
  position: absolute;
  top: 23px;
  left: 6px;
  width: 34px;
  height: 24px;
  margin-left: 13px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}
.modal-callback-close:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.modal-callback-close::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0deg);
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background: #332c2c;
  transition: all 0.3s ease;
}
.modal-callback-close::after {
  content: '';
  position: absolute;
  top: 12px;
  right: 0;
  transform: rotate(0deg);
  width: 50%;
  height: 4px;
  border-radius: 10px;
  background: #332c2c;
  transition: all 0.3s ease;
}
.modal-callback-form {
  z-index: 10;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: flex-start;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  padding-left: 23px;
  color: #332c2c;
  background: #f6f6f6 url("../img/modal-bg-small.png") right bottom no-repeat;
}
.modal-callback-form h2 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 900;
}
.modal-callback-form a {
  color: #332c2c;
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
  text-decoration: none;
}
.modal-callback-form a:hover {
  color: #fe0000;
}
.modal-callback-form form {
  padding-bottom: 160px;
}
.modal-callback-form form .site-input {
  width: 80%;
  max-width: 1130px;
}
.modal-is-active .modal-callback-form {
  display: flex;
}
.modal-form-label {
  display: block;
  font-size: 18px;
  line-height: 27px;
  font-weight: 900;
}
.modal-form-submit {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 60px;
  z-index: 3;
  left: 0;
  bottom: 0;
  border: none;
  border-radius: 30px 30px 0 0;
  background: #fe0000;
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.6s ease-out;
}
.modal-form-submit:hover {
  background: #ce0000;
}
.modal-thanks {
  display: none;
}
.modal-thanks div {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  min-height: 240px;
  padding: 30px 0;
  background: #fff;
  box-shadow: 0 4px 20px rgba(51,44,44,0.5);
  border-radius: 40px;
}
.modal-thanks div svg {
  margin: 20px auto 20px;
}
.modal-thanks div span {
  display: block;
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
}
@media (min-width: 550px) {
  .modal-form-submit {
    width: 302px;
    height: 120px;
    border-radius: 0px 84px 0px 0px;
  }
  .modal-form-label {
    margin-bottom: 40px;
  }
}
@media (max-height: 500px) {
  .modal-callback-form form {
    padding-bottom: 60px;
  }
  .modal-form-label {
    margin-bottom: 0;
  }
  .modal-form-submit {
    width: 100%;
    height: 60px;
    border-radius: 30px 30px 0 0;
  }
}
@media (min-width: 1050px) {
  .header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 0;
  }
  .header.popup-is-active {
    flex-wrap: nowrap;
  }
  .header-logo {
    order: 1;
  }
  .header-menu-button {
    order: 2;
    margin-top: 30px;
  }
  .header.popup-is-active {
    padding: 12px 13px;
  }
  .header-menu-button {
    margin-left: 0;
  }
  .modal-callback-form {
    padding-left: 60px;
    background-image: url("../img/modal-bg-big.png");
    background-size: contain;
  }
  .modal-callback-form h2 {
    font-size: 38px;
    line-height: 57px;
    font-weight: 900;
  }
  .modal-callback-form a {
    font-size: 38px;
    line-height: 57px;
    font-weight: normal;
  }
  .modal-form-label {
    font-size: 38px;
    line-height: 57px;
    font-weight: 900;
  }
}
@media (min-width: 1300px) {
  .modal-callback-form {
    padding-top: 20px;
    padding-left: 100px;
  }
  .modal-form-submit {
    width: 342px;
    height: 160px;
    border-radius: 0px 104px 0px 0px;
  }
}
.site-footer {
  position: relative;
  padding: 53px 0 0;
  background: #f6f6f6;
  z-index: 4;
}
.footer-title {
  margin: 0 0 28px;
  font-size: 24px;
  line-height: 35px;
  font-weight: 800;
}
.contacts,
.footer-services {
  margin-bottom: 50px;
}
.contacts li,
.footer-services li {
  padding: 10px 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
}
.contacts a,
.footer-services a {
  color: #332c2c;
  text-decoration: none;
}
.contacts a:hover,
.footer-services a:hover {
  text-decoration: underline;
}
.contacts.header-contacts,
.footer-services.header-contacts {
  margin: 0;
}
.footer-seo-title {
  margin: 0 0 12px;
  color: #717171;
  font-size: 18px;
  line-height: 27px;
  font-weight: 800;
}
.footer-seo-content {
  color: #717171;
}
.callback-form {
  margin: 90px 0 75px;
}
.callback-form .site-input {
  width: 100%;
}
.callback-form-label {
  display: inline-block;
  width: 100%;
  margin-bottom: 38px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 800;
}
@media (min-width: 568px) {
  .footer-list-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(160px, 240px));
  }
  .callback-form {
    max-width: 400px;
  }
}
@media (min-width: 800px) {
  .footer-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .footer-list-container {
    grid-template-columns: repeat(2, minmax(160px, 180px));
    grid-gap: 15px;
    justify-content: space-evenly;
  }
  .footer-seo {
    max-width: 50%;
  }
}
@media (min-width: 1050px) {
  .site-footer {
    padding-top: 35px;
  }
  .footer-list-container {
    grid-gap: 82px;
  }
  .callback-form {
    display: none;
  }
}
@media (min-width: 1800px) {
  .footer-list-container {
    grid-template-columns: repeat(2, minmax(160px, 415px));
  }
  .footer-seo {
    max-width: 45%;
  }
}
.main-page {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 2;
}
.detail-header {
  position: fixed;
  z-index: 5;
}
.detail-image-wrap {
  position: relative;
  text-align: center;
  min-height: 100vh;
}
.detail-image-wrap img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.detail-page {
  min-width: 1000px;
  position: relative;
  height: initial;
  min-height: initial;
  padding-bottom: 200px;
}
.detail-page h2 {
  font-size: 53px;
  line-height: 62px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 19px;
}
.detail-leaf-down.white {
  color: #fff;
}
.detail-leaf-down.white:after {
  background: url("../img/leaf-mouse-white.svg") 0 0 no-repeat;
}
.detail-page-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  padding: 0 60px;
  min-height: 78vh;
}
.detail-content-wrap {
  position: relative;
  z-index: 2;
  max-width: 90%;
}
.tags-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.tags-item {
  margin-right: 20px;
}
.tags-item a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
}
.detail-page-about {
  display: block;
  margin: 60px 0 45px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.detail-page-table {
  margin-bottom: 60px;
  color: inherit;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}
.detail-page-table th {
  vertical-align: top;
  text-align: left;
  min-width: 135px;
  font-weight: 800;
}
.detail-page-link {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 104px 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 160px;
  padding-top: 10px;
  padding-left: 30px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
@media (min-width: 1300px) {
  .detail-page-wrap {
    padding: 0 100px;
  }
}
@media (min-width: 1800px) {
  .detail-page-wrap {
    padding: 0 145px;
  }
}
@media (max-width: 1400px) {
  .detail-page-link {
    width: 280px;
    height: 120px;
    padding-left: 0;
  }
}
.detail-page-wrap.result-block {
  min-height: auto;
  padding-top: 80px;
  padding-bottom: 60px;
  padding-left: 60px;
}
.detail-page-wrap.result-block .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: start;
}
@media (min-width: 1300px) {
  .detail-page-wrap.result-block {
    padding-left: 100px;
  }
}
@media (min-width: 1600px) {
  .detail-page-wrap.result-block {
    padding-left: 260px;
  }
}
.result-header {
  display: block;
  margin-bottom: 55px;
  font-size: 53px;
  line-height: 1.08;
  font-weight: 800;
}
.result-text {
  font-size: 88px;
  line-height: 1.08;
  font-weight: 800;
}
.result-text strong {
  font-size: 125px;
  line-height: 1.08;
  font-weight: 800;
}
.detail-page-wrap.seo-block {
  padding-top: 120px;
  padding-bottom: 50px;
  min-height: auto;
}
.detail-seo-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.portfolio-header {
  background: none;
}
.white-button::before,
.white-button::after {
  background: #332c2c;
}
.white-button:hover::before,
.white-button:hover::after {
  background: rgba(51,44,44,0.5);
}
.portfolio-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-radius: 30px 30px 0 0;
  z-index: 3;
  transition: all 0.6s ease-out;
}
.portfolio-button-link {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.6s ease-out;
}
.portfolio-page {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  max-height: 650px;
  padding-bottom: 90px;
  color: #2c2c3e;
}
.portfolio-page:first-of-type {
  margin-top: -58px;
}
.portfolio-page h2 {
  display: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 35px;
  font-weight: 800;
}
.portfolio-page .portfolio-mobile-header {
  display: block;
}
@media (max-width: 550px) {
  .portfolio-page {
    padding: 0;
    padding-bottom: 90px;
  }
  .portfolio-page .portfolio-content-wrap {
    padding: 0 24px;
  }
}
.portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.portfolio-image-wrap img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.portfolio-content-wrap {
  position: relative;
  z-index: 2;
}
.tags-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.tags-item {
  margin-right: 20px;
  margin-bottom: 8px;
}
.tags-item a,
.tags-item span {
  font-size: 14px;
  line-height: 21px;
  font-weight: 900;
  color: inherit;
  text-decoration: none;
}
.portfolio-page-about {
  display: none;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.portfolio-page-table {
  display: none;
  color: inherit;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.portfolio-page-table th {
  text-align: left;
  vertical-align: top;
  min-width: 135px;
  font-weight: 600;
}
.portfolio-page-link {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  width: 342px;
  height: 160px;
  border-radius: 0px 104px 0px 0px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  z-index: 3;
  border: none;
  color: #fff;
  transition: all 0.6s ease-out;
}
.portfolio-page-link:hover {
  filter: brightness(85%);
}
.portfolio-case-link {
  position: absolute;
  bottom: 0;
  left: 360px;
  display: none;
  width: 342px;
  height: 160px;
  color: #fff;
  stroke: #fff;
  border: none;
  z-index: 3;
  font-size: 18px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
}
.portfolio-case-link:hover svg {
  transform: translateX(20px);
}
.portfolio-case-link svg {
  margin-left: 5px;
  margin-bottom: 1px;
  flex-shrink: 0;
  flex-grow: 0;
  transition: all 0.2s ease-out;
}
.portfolio-case-link svg path {
  stroke: inherit;
}
@media (min-width: 550px) {
  .portfolio-page {
    max-height: 568px;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    order: 2;
  }
  .portfolio-image-wrap img {
    display: block;
  }
  .portfolio-button {
    display: none;
  }
  .portfolio-page-link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1023px) {
  .portfolio-header {
    position: fixed;
    z-index: 5;
  }
  .portfolio-page {
    position: relative;
    max-height: initial;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 0;
    height: initial;
    min-height: initial;
  }
  .portfolio-page:first-of-type {
    margin-top: 0;
  }
  .portfolio-page h2,
  .portfolio-page h2 > a {
    display: block;
    font-size: 41px;
    line-height: 56px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 19px;
    color: inherit;
    text-decoration: none;
  }
  .portfolio-page .portfolio-mobile-header {
    display: none;
  }
  .portfolio-page-link {
    margin-top: 0;
    transition: all 0.3s ease-out;
  }
  .portfolio-case-link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .portfolio-content-wrap {
    order: 1;
    padding-top: 50px;
    padding-bottom: 160px;
    min-width: 690px;
    height: 100%;
  }
  .portfolio-image-wrap {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    z-index: 1;
    order: 2;
  }
  .portfolio-page-about,
  .portfolio-page-table {
    display: block;
    max-width: 600px;
  }
  .portfolio-page-table {
    margin-bottom: 60px;
  }
  .portfolio-page-about {
    margin: 45px 0 30px;
  }
  .portfolio-page-about a,
  .portfolio-page-about span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 800;
    color: inherit;
    text-decoration: none;
  }
}
@media (min-width: 1400px) {
  .portfolio-page {
    min-height: 750px;
  }
}
@media (min-width: 1605px) {
  .portfolio-page {
    padding-top: 0;
  }
  .portfolio-content-wrap {
    padding-top: 100px;
    padding-bottom: 160px;
  }
}
.detail-nabiss {
  color: #332c2c;
  background: #fff;
  height: initial;
}
.detail-nabiss .detail-image-wrap {
  position: relative;
  background: none;
  background-attachment: fixed;
}
.detail-nabiss .detail-image-parallax {
  position: absolute;
  top: 0;
  left: 0;
}
.detail-nabiss .tags-item a,
.detail-nabiss .tags-item span {
  box-shadow: 0 4px 0 0 #8caf3b;
}
.detail-nabiss .detail-page-about,
.detail-nabiss .detail-page-table td {
  color: #494949;
}
.detail-nabiss .detail-page-link {
  background-color: #8caf3b;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-nabiss .detail-page-link:hover {
  background-color: #7d9d30;
}
.detail-nabiss .detail-styles-container {
  width: 100%;
  max-width: 760px;
}
.detail-nabiss .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
}
.detail-nabiss .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 175px;
}
.detail-nabiss .styles-colors-item div {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.detail-nabiss .styles-colors-item span {
  color: #a4a4a4;
}
.detail-nabiss .detail-styles-ui {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.detail-nabiss .detail-font-example {
  display: block;
  font-family: 'Proxima Nova', sans-serif;
  color: rgba(198,198,198,0.2);
  font-size: 194px;
  line-height: 236px;
  font-weight: 800;
}
.detail-nabiss .detail-font-name {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}
.detail-nabiss .detail-ui-kit {
  width: 55%;
  padding-top: 50px;
}
.detail-nabiss .detail-ui-buttons,
.detail-nabiss .detail-ui-links,
.detail-nabiss .detail-ui-checkboxes {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-nabiss .detail-ui-buttons {
  min-width: 400px;
}
.detail-nabiss .site-button {
  display: inline-block;
  width: 190px;
  font-family: 'Proxima Nova', sans-serif;
  margin-bottom: 60px;
  padding: 11px 10px 13px;
  border: none;
  text-align: center;
  background-color: #a1c058;
  box-shadow: 4px 4px 20px rgba(161,192,88,0.5);
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-decoration: none;
}
.detail-nabiss .site-button-hover {
  background-color: #7d9d30;
  text-decoration: none;
}
.detail-nabiss .block-link {
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  padding: 14px 0;
  color: #1f272d;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
  text-decoration: none;
}
.detail-nabiss .block-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0%;
  height: 2px;
  background-color: #a1c058;
  transition: width 0.3s ease;
}
.detail-nabiss .block-link.active-page::after {
  width: 100%;
}
.detail-nabiss .detail-ui-checkbox {
  position: relative;
  display: block;
  margin-top: 20px;
  padding-left: 30px;
  color: #8f9396;
  font-family: 'Proxima Nova', sans-serif;
}
.detail-nabiss .detail-ui-checkbox::before {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #8f9396;
}
.detail-nabiss .ui-checkbox-checked::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 7px;
  width: 10px;
  height: 10px;
  background-color: #a1c058;
  box-shadow: 2px 2px 10px rgba(161,192,88,0.5);
}
.nabiss-colors-olive {
  background: #8caf3b;
  box-shadow: 4px 4px 50px #a1bd60;
}
.nabiss-colors-gray {
  background: #c6c6c6;
  box-shadow: 4px 4px 50px #c6c6c6;
}
.nabiss-colors-dark {
  background: #1f272d;
  box-shadow: 4px 4px 50px #1f272d;
}
@media (max-width: 1400px) {
  .detail-nabiss {
    padding-left: 60px;
    padding-right: 60px;
  }
  .detail-nabiss .detail-font-example {
    font-size: 132px;
  }
  .detail-nabiss .detail-styles-ui {
    min-width: 520px;
  }
  .detail-nabiss .detail-ui-buttons {
    justify-content: center;
    min-width: 100px;
  }
  .detail-nabiss .detail-ui-links {
    width: 360px;
    position: absolute;
    top: 105%;
    left: 0;
  }
  .detail-nabiss .detail-ui-checkboxes {
    width: 360px;
    position: absolute;
    top: 125%;
    left: 0;
  }
}
.detail-page.detail-restochef {
  padding-bottom: 100px;
}
.detail-restochef {
  color: #332c2c;
  background: #fff;
  height: initial;
}
.detail-restochef .detail-image-wrap {
  padding-top: 120px;
  text-align: center;
}
.detail-restochef .detail-image-wrap img {
  width: auto;
}
.detail-restochef .tags-item a,
.detail-restochef .tags-item span {
  box-shadow: 0 2px 0 0 #c1a0cd;
}
.detail-restochef .detail-page-about,
.detail-restochef .detail-page-table td {
  color: #a4a4a4;
}
.detail-restochef .detail-page-link {
  background-color: #c1a0cd;
  color: #fff;
  transition: 0.2s all ease-out;
}
.detail-restochef .detail-page-link:hover {
  background-color: #a180ad;
}
.detail-restochef .detail-font {
  color: #522762;
  font-size: 125px;
  line-height: 144px;
  font-weight: bold;
}
.detail-restochef .detail-styles {
  max-width: 740px;
}
.detail-restochef .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
}
.detail-restochef .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 145px;
}
.detail-restochef .styles-colors-item > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border: 1.5px solid #ccc;
  border-radius: 50%;
}
.detail-restochef .styles-colors-item > div div {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  transition: all 0.15s ease;
}
.detail-restochef .styles-colors-item > div:hover div {
  width: 84px;
  height: 84px;
}
.detail-restochef .styles-colors-item span {
  color: #a4a4a4;
}
.detail-restochef .detail-styles-ui {
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.detail-restochef .detail-ui-forms {
  width: 100%;
  max-width: 290px;
}
.detail-restochef .detail-ui-forms + .catalog-filter {
  margin-left: 20px;
}
.detail-restochef .catalog-filter {
  max-width: 320px;
  width: 100%;
  margin-bottom: 40px;
  padding: 34px;
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(0,0,0,0.15);
}
.detail-restochef .filter-item-title {
  align-self: flex-start;
  position: relative;
  display: inline-block;
  padding-right: 12px;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  cursor: pointer;
}
.detail-restochef .filter-item-title.hidden::after {
  transform: translateY(-50%) rotate(-90deg);
}
.detail-restochef .filter-item-title.hidden + .filter-item {
  display: none;
}
.detail-restochef .filter-item-title::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  display: block;
  width: 6px;
  height: 3px;
  background: url("./../img/filter-arrow.svg") 50% 50%/6px 3px no-repeat;
}
.detail-restochef .filter-item-title:nth-of-type(n+2) {
  margin-top: 15px;
}
.detail-restochef .filter-item-title:hover {
  text-decoration: underline;
}
.detail-restochef .filter-checkbox-wrap {
  margin-top: 10px;
}
.detail-restochef .filter-item.column-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-restochef .filter-item.column-block .site-checkbox-label {
  display: block;
}
.detail-restochef .filter-item.scrollable {
  max-height: 175px;
}
.detail-restochef .noUi-range-handle {
  position: absolute;
  left: 15px;
  top: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 2px 2px 7px rgba(82,39,98,0.7);
}
.detail-restochef .noUi-range-target {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 40px 0 30px;
  background-color: #522762;
}
.detail-restochef .range-min-output,
.detail-restochef .range-max-output {
  position: absolute;
  top: -30px;
  width: 30px;
  margin-left: -20px;
  color: #332c2c;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
}
.detail-restochef .range-max-output {
  margin-left: -25px;
}
.detail-restochef .site-checkbox {
  display: none;
}
.detail-restochef .site-checkbox:checked + label {
  color: #522762;
}
.detail-restochef .site-checkbox:checked + label::after {
  opacity: 1;
}
.detail-restochef .site-checkbox-label {
  position: relative;
  display: inline-block;
  padding: 7px 10px 7px 26px;
  color: #ababab;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  cursor: pointer;
}
.detail-restochef .site-checkbox-label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translateY(-50%);
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid #522762;
  border-radius: 2px;
}
.detail-restochef .site-checkbox-label::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  transform: translateY(-50%);
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background: url("./../img/checkbox-checked.svg") 50% 50%/8px 6px no-repeat;
  opacity: 0;
}
.detail-restochef .site-checkbox-label:hover::after {
  opacity: 0.3;
}
.detail-restochef .site-button {
  display: inline-block;
  padding: 10px 30px;
  border: none;
  outline: none;
  border-radius: 24px;
  background-color: #2d2d2d;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-decoration: none;
  transition: all 0.3s ease;
}
.detail-restochef .site-button:hover {
  background-color: #332c2c;
  cursor: pointer;
}
.detail-restochef .site-button.reverse {
  padding: 9px 30px;
  border: 1px solid #332c2c;
  background-color: transparent;
  color: #332c2c;
}
.detail-restochef .site-button.reverse:hover {
  background-color: #332c2c;
  color: #fff;
}
.detail-restochef .popup-body {
  position: relative;
  max-width: 370px;
  min-width: 300px;
  margin-left: 25px;
  padding: 40px 25px;
  border-radius: 29px;
  background: #fff;
  box-shadow: 4px 4px 20px rgba(0,0,0,0.15);
  line-height: 23px;
  text-align: center;
}
.detail-restochef .popup-body p {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}
.detail-restochef .popup-body .site-button {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-restochef .popup-body .site-button:nth-of-type(n+3) {
  margin-left: 25px;
}
.detail-restochef .popup-close {
  position: absolute;
  top: 15px;
  right: 24px;
  z-index: 2;
  padding-right: 11px;
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  text-align: right;
  cursor: pointer;
}
.detail-restochef .popup-close::before,
.detail-restochef .popup-close::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  display: block;
  width: 7px;
  height: 1px;
  background: #2c2c3e;
}
.detail-restochef .popup-close::before {
  transform: translateY(-50%) rotate(-45deg);
}
.detail-restochef .popup-close::after {
  transform: translateY(-50%) rotate(45deg);
}
.detail-restochef .site-button {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.restochef-colors-purple div {
  background-color: #c1a0cd;
}
.restochef-colors-purple-dark div {
  background-color: #522762;
}
.restochef-colors-yellow div {
  background-color: #ffd12e;
}
.restochef-colors-dark div {
  background-color: #060307;
}
@media (max-width: 1400px) {
  .detail-restochef {
    padding-left: 60px;
    padding-right: 60px;
  }
  .detail-restochef .detail-page-wrap {
    grid-template-columns: 0.8fr 1fr;
  }
}
@media (max-width: 1200px) {
  .detail-restochef .detail-page-wrap {
    grid-template-columns: repeat(1, 75%);
  }
  .detail-restochef .detail-styles-color,
  .detail-restochef .detail-styles-ui {
    justify-content: flex-start;
  }
}
@media (min-width: 1600px) {
  .detail-restochef .detail-styles {
    padding-left: 50px;
  }
  .detail-restochef .detail-styles-colors {
    max-width: 80%;
  }
  .detail-restochef .popup-body {
    margin-left: 40px;
  }
}
.detail-tourskazka {
  color: #332c2c;
  background: linear-gradient(180.84deg, #fffefe 65.98%, #cbcbcb 111.19%);
  height: initial;
}
.detail-tourskazka .detail-leaf-down {
  color: #332c2c;
}
.detail-tourskazka .detail-leaf-down::after {
  background: url("../img/leaf-mouse.svg") 0 0 no-repeat;
}
.detail-tourskazka .detail-image-wrap {
  padding-top: 0;
}
.detail-tourskazka .detail-image-wrap img {
  width: 100%;
}
.detail-tourskazka .tags-item a,
.detail-tourskazka .tags-item span {
  box-shadow: 0 2px 0 0 #2c2d37;
}
.detail-tourskazka .detail-page-about,
.detail-tourskazka .detail-page-table td {
  color: #a4a4a4;
}
.detail-tourskazka .detail-page-link {
  background-color: #2c2d37;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-tourskazka .detail-page-link:hover {
  background-color: #4c4d57;
}
.detail-tourskazka .detail-styles-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 100px 40px;
}
.detail-tourskazka .detail-styles-colors {
  max-width: 290px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
}
.detail-tourskazka .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 145px;
}
.detail-tourskazka .styles-colors-item > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 91px;
  border-radius: 50%;
}
.detail-tourskazka .styles-colors-item span {
  color: #2c2d37;
}
.detail-tourskazka .detail-styles-fonts {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  font-size: 24px;
  line-height: 33px;
  font-weight: 400;
}
.detail-tourskazka .styles-fonts-example {
  display: inline-block;
  width: 40px;
  color: #ecdfb3;
}
.detail-tourskazka .styles-fonts-days {
  font-family: 'Days', sans-serif;
}
.detail-tourskazka .styles-fonts-days .styles-fonts-name {
  text-transform: uppercase;
}
.detail-tourskazka .styles-fonts-opensans {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}
.detail-tourskazka .detail-styles-ui {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-tourskazka .tourskazka-form {
  width: 100%;
  max-width: 275px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 35px 20px;
  background-color: #8fc029;
}
.detail-tourskazka .tourskazka-form label {
  color: #fff;
  padding-bottom: 5px;
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
}
.detail-tourskazka .tourskazka-form label:not(:first-child) {
  padding-top: 15px;
}
.detail-tourskazka .tourskazka-form input {
  width: 100%;
  background-color: #fff;
  height: 48px;
  color: #757575;
  padding: 0 20px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  border-radius: 30px;
  border: none;
}
.detail-tourskazka .tourskazka-form input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.detail-tourskazka .detail-styles-buttons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.detail-tourskazka .site-button {
  background: #8fc029;
  border: 0;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  width: 260px;
  height: 60px;
  line-height: 60px;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  border-radius: 30px;
  cursor: pointer;
  transition: all ease 0.2s;
}
.detail-tourskazka .site-button:not(:last-child) {
  margin-bottom: 40px;
}
.detail-tourskazka .site-button-hover {
  background-color: #73a015;
}
@media (max-width: 1400px) {
  .detail-tourskazka .detail-page-wrap {
    grid-template-columns: 0.8fr 1fr;
  }
}
@media (max-width: 1200px) {
  .detail-tourskazka .detail-page-wrap {
    grid-template-columns: repeat(1, 75%);
  }
  .detail-tourskazka .detail-styles-color,
  .detail-tourskazka .detail-styles-ui {
    justify-content: flex-start;
  }
}
@media (min-width: 1600px) {
  .detail-tourskazka .detail-styles {
    padding-left: 50px;
  }
}
.detail-lamp {
  color: #332c2c;
  background: url("../img/lamplandia-lamp.png") no-repeat 75% 0, linear-gradient(337deg, #eaf1f9 7%, #fff 88%);
  height: initial;
  padding-top: 50px;
  padding-bottom: 160px;
}
.detail-lamp .detail-image-wrap {
  padding-top: 50px;
  position: relative;
  background: none;
  text-align: center;
}
.detail-lamp .detail-image-wrap img {
  width: auto;
}
.detail-lamp .tags-item a,
.detail-lamp .tags-item span {
  box-shadow: 0 4px 0 0 #7c79e5;
}
.detail-lamp .detail-page-about,
.detail-lamp .detail-page-table td {
  color: #a4a4a4;
}
.detail-lamp .detail-page-link {
  background-color: #7c79e5;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-lamp .detail-page-link:hover {
  background-color: #2c2c3e;
}
.detail-lamp .detail-styles-container {
  width: 100%;
  max-width: 760px;
}
.detail-lamp .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  width: 100%;
  max-width: 430px;
}
.detail-lamp .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 125px;
}
.detail-lamp .styles-colors-item div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.detail-lamp .styles-colors-item span {
  color: #2c2c3e;
}
.detail-lamp .detail-styles-ui {
  padding-top: 50px;
  font-family: 'Segoe UI';
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.detail-lamp .detail-font-example {
  display: block;
  font-family: 'Segoe UI', sans-serif;
  color: rgba(124,121,229,0.33);
  font-size: 194px;
  line-height: 258px;
  font-weight: bold;
}
.detail-lamp .detail-font-name {
  font-family: 'Segoe UI', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.detail-lamp .detail-ui-kit {
  padding-top: 50px;
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-lamp .ui-kit-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}
.detail-lamp .ui-kit-column span {
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 20px;
}
.detail-lamp .ui-kit-caption {
  margin-top: 20px;
}
.detail-lamp .detail-ui-buttons,
.detail-lamp .detail-ui-links,
.detail-lamp .detail-ui-checkboxes {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-lamp .site-button {
  display: inline-block;
  width: 156px;
  font-family: 'Segoe UI', sans-serif;
  margin-bottom: 20px;
  padding: 11px 10px 13px;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  background: #2c2c3e;
  border: none;
  box-shadow: 5.45455px 5.45455px 27.2727px rgba(0,0,0,0.15);
}
.detail-lamp .site-button-hover {
  background: #7d79e4;
  box-shadow: 5.45455px 5.45455px 27.2727px rgba(0,0,0,0.15);
  text-decoration: none;
}
.detail-lamp .block-link {
  position: relative;
  display: inline-block;
  padding: 0;
  color: #1f272d;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: none;
}
.detail-lamp .block-link.active-page {
  color: #7d79e4;
}
.lamp-colors-dark {
  background: #2c2c3e;
}
.lamp-colors-blue {
  background: #7d79e4;
}
.lamp-colors-white {
  background: #fff;
}
@media (max-width: 1400px) {
  .detail-lamp {
    padding-left: 60px;
    padding-right: 60px;
  }
  .detail-lamp .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-lamp .detail-font-example {
    font-size: 132px;
  }
  .detail-lamp .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-lamp .detail-ui-buttons {
    justify-content: center;
    min-width: 100px;
  }
}
.detail-alfaplan {
  color: #332c2c;
  background: url("../img/alfaplan/bg-big.png") no-repeat top right, linear-gradient(243.4deg, rgba(196,196,196,0) 4.5%, rgba(196,196,196,0.446) 27.76%, rgba(196,196,196,0.71) 47.52%, rgba(196,196,196,0.519) 61.14%, rgba(196,196,196,0) 94.53%);
  height: initial;
  padding-top: 100px;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-alfaplan .detail-image-wrap {
  text-align: center;
}
.detail-alfaplan .detail-image-wrap img {
  width: auto;
}
.detail-alfaplan .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-bottom: 50px;
}
.detail-alfaplan .tags-item a,
.detail-alfaplan .tags-item span {
  box-shadow: 0 2px 0 0 #bb100e;
}
.detail-alfaplan .detail-page-about,
.detail-alfaplan .detail-page-table td {
  max-width: 700px;
  color: #7d7d7d;
}
.detail-alfaplan .detail-page-link {
  background-color: #bb100e;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-alfaplan .detail-page-link:hover {
  background-color: #313131;
}
.detail-alfaplan .detail-styles-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
}
.detail-alfaplan .detail-styles-colors {
  display: grid;
  grid-template-columns: repeat(2, 93px);
  grid-template-rows: auto;
  max-width: 430px;
}
.detail-alfaplan .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 93px;
  height: 93px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-alfaplan .alfaplan-light-gray {
  background-color: #a5afc0;
}
.detail-alfaplan .alfaplan-blue {
  background-color: #006bd4;
}
.detail-alfaplan .alfaplan-red {
  background-color: #ff0006;
}
.detail-alfaplan .alfaplan-dark-gray {
  background-color: #313131;
}
.detail-alfaplan .detail-styles-fonts {
  font-size: 53px;
  line-height: 61px;
  font-weight: normal;
  padding-left: 20px;
}
.detail-alfaplan .styles-fonts-avenir {
  font-family: 'Avenir Next', sans-serif;
  margin-bottom: 25px;
}
.detail-alfaplan .styles-fonts-gotham {
  font-family: 'Gotham Pro', sans-serif;
  font-weight: bold;
}
.detail-alfaplan .styles-fonts-example {
  padding-right: 45px;
}
.detail-alfaplan .detail-ui-kit {
  padding-top: 50px;
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-alfaplan .detail-ui-forms {
  padding-top: 100px;
}
.detail-alfaplan .catalog-filter {
  max-width: 100%;
  width: 822px;
  height: 150px;
  padding: 0 36px 0 33px;
  margin-right: 35px;
}
.detail-alfaplan .catalog-filter-caption {
  display: block;
  font-family: 'Gotham Pro', sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 30px;
  margin-bottom: 60px;
}
.detail-alfaplan .range-input {
  margin: 20px auto;
  height: 7px;
  position: relative;
}
.detail-alfaplan .range-min,
.detail-alfaplan .range-max {
  position: absolute;
  top: 25px;
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
}
.detail-alfaplan .range-min {
  left: 0;
}
.detail-alfaplan .range-max {
  right: -20px;
}
.detail-alfaplan .noUi-connects {
  background: #c9c9c9;
}
.detail-alfaplan .noUi-connect {
  background: #2855a8;
}
.detail-alfaplan .noUi-target {
  border: none;
}
.detail-alfaplan .noUi-horizontal {
  height: 7px;
}
.detail-alfaplan .noUi-horizontal .noUi-handle {
  top: -8px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.27);
  cursor: pointer;
}
.detail-alfaplan .noUi-handle::after,
.detail-alfaplan .noUi-handle::before {
  display: none;
}
.detail-alfaplan .range-min-output,
.detail-alfaplan .range-max-output {
  position: absolute;
  top: -43px;
  transform: translateX(-33%);
  padding: 5px 8px;
  margin-left: -93px;
  background: #fff;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.17);
  color: #000;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
}
.detail-alfaplan .range-min-output::after,
.detail-alfaplan .range-max-output::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 0;
  height: 0;
  border: 6px solid #fff;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.detail-alfaplan .range-max-output {
  transform: translateX(-33%);
  margin-left: -94px;
}
.detail-alfaplan .detail-styles-ui {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 822px;
  max-width: 100%;
  margin-top: 71px;
  color: #332c2c;
}
.detail-alfaplan .alfaplan-checkbox-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 151px;
}
.detail-alfaplan .alfaplan-checkbox-container span {
  display: block;
  width: 90px;
  font-family: 'Gotham Pro', sans-serif;
  font-size: 13px;
  line-height: 1.1;
  font-weight: bold;
}
.detail-alfaplan .alfaplan-checkbox {
  cursor: pointer;
  position: relative;
  width: 41px;
  height: 41px;
  border: 1px solid #bfcbd0;
}
.detail-alfaplan .alfaplan-checkbox::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../img/alfaplan-checkbox.svg") center center no-repeat;
}
.detail-alfaplan .alfaplan-select-container {
  margin: 0 5px;
}
.detail-alfaplan .alfaplan-select-container > span {
  font-family: 'Gotham Pro', sans-serif;
  font-size: 14px;
  line-height: 1.1;
  font-weight: bold;
}
.detail-alfaplan .alfaplan-select {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 260px;
  height: 41px;
  margin-top: 10px;
  padding-left: 17px;
  font-family: 'Avenir Next';
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  border: 1px solid #bfcbd0;
}
.detail-alfaplan .alfaplan-select::before {
  content: '';
  position: absolute;
  right: 21px;
  width: 12px;
  height: 8px;
  background: url("../img/alfaplan-select.svg") 0 0 no-repeat;
}
.detail-alfaplan .alfaplan-search {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 41px;
  color: #fff;
  background-color: #fe0006;
}
.detail-alfaplan .alfaplan-search span {
  position: relative;
  display: block;
  padding-left: 18px;
  font-family: 'Avenir Next';
  color: #fff;
}
.detail-alfaplan .alfaplan-search span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background: url("../img/alfaplan-search.svg") 0 0 no-repeat;
}
.detail-alfaplan .result-block {
  padding-bottom: 120px;
  padding-top: 120px;
  background: #fff;
}
.detail-alfaplan .result-block .col {
  display: block;
}
.detail-alfaplan .result-header {
  color: #332c2c;
}
.detail-alfaplan .result-text {
  color: #bb100e;
}
.detail-alfaplan .result-text strong {
  color: #332c2c;
}
.detail-alfaplan .seo-block {
  margin-bottom: -150px;
  padding-top: 120px;
  padding-bottom: 200px;
  background: linear-gradient(217.01deg, rgba(196,196,196,0) 4.5%, rgba(196,196,196,0.446) 27.76%, rgba(196,196,196,0.71) 47.52%, rgba(196,196,196,0.519) 61.14%, rgba(196,196,196,0) 94.53%);
}
@media (max-width: 1400px) {
  .detail-alfaplan .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-alfaplan .detail-font-example {
    font-size: 132px;
  }
  .detail-alfaplan .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-alfaplan .detail-ui-buttons {
    justify-content: center;
    min-width: 100px;
  }
}
.detail-zosterin {
  color: #fff;
  background: url("../img/zosterin-bubbles-1.png") no-repeat right 0, url("../img/zosterin-bubbles-2.png") no-repeat left 30%, url("../img/zosterin-bubbles-3.png") no-repeat right 100%, linear-gradient(218.85deg, #3bb0a2 10.26%, #0aa18f 94.88%);
  height: initial;
  padding-top: 40px;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-zosterin .detail-leaf-down {
  color: #fff;
}
.detail-zosterin .detail-leaf-down::after {
  background: url("../img/leaf-mouse-white.svg") 0 0 no-repeat;
}
.detail-zosterin .detail-image-wrap {
  padding-left: 100px;
  padding-right: 145px;
}
.detail-zosterin .detail-page-wrap {
  min-height: 70vh;
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
}
.detail-zosterin .tags-item a,
.detail-zosterin .tags-item span {
  box-shadow: 0 2px 0 0 #5a0089;
}
.detail-zosterin .detail-page-about,
.detail-zosterin .detail-page-table td {
  max-width: 650px;
  color: #e6e6e6;
}
.detail-zosterin .detail-page-link {
  background-color: #5a0089;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-zosterin .detail-page-link:hover {
  background-color: #d2ee00;
}
.detail-zosterin .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 660px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-zosterin .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 80px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-zosterin .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-zosterin .zosterin-color {
  margin-bottom: 30px;
  width: 100px;
  height: 100px;
  border-radius: 30px;
}
.detail-zosterin .zosterin-blue-chill {
  background: #429288;
  border: 1px solid #fff;
  box-sizing: border-box;
  box-shadow: 3px 4px 15px rgba(0,0,0,0.5);
}
.detail-zosterin .zosterin-purple {
  background: #822181;
  box-shadow: 3px 4px 15px rgba(0,0,0,0.5);
}
.detail-zosterin .zosterin-green {
  background: #22832d;
  box-shadow: 3px 4px 15px rgba(0,0,0,0.5);
}
.detail-zosterin .detail-ui-kit {
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-zosterin .detail-ui-icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 36px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 3px 4px 15px rgba(0,0,0,0.5);
}
.detail-zosterin .detail-ui-buttons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
  padding-left: 10px;
}
.detail-zosterin .site-button {
  width: 273px;
  padding: 5px 0;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-family: 'PT Sans', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  background: #429288;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 30px;
}
.detail-zosterin .site-button-hover {
  background: #68a8a0;
}
@media (max-width: 1400px) {
  .detail-zosterin {
    padding-left: 60px;
    padding-right: 60px;
  }
  .detail-zosterin .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-zosterin .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-zosterin .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-royal {
  color: #332c2c;
  background: #fff;
  height: initial;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-royal .detail-leaf-down {
  color: #332c2c;
}
.detail-royal .detail-leaf-down::after {
  background: url("../img/leaf-mouse.svg") 0 0 no-repeat;
}
.detail-royal .detail-image-wrap {
  padding-right: 0;
  text-align: center;
}
.detail-royal .detail-image-wrap img {
  width: 100%;
}
.detail-royal .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-top: 50px;
}
.detail-royal .tags-item a,
.detail-royal .tags-item span {
  box-shadow: 0 2px 0 0 #1f4e8d;
}
.detail-royal .detail-page-about,
.detail-royal .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-royal .detail-page-link {
  background-color: #1f4e8d;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-royal .detail-page-link:hover {
  background-color: #0f3e7d;
}
.detail-royal .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-royal .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 560px;
  padding-bottom: 80px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-royal .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #a4a4a4;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-royal .royal-color {
  margin-bottom: 33px;
}
.detail-royal .detail-ui-kit {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 550px;
}
.detail-royal .ui-kit-col {
  margin-right: 5px;
}
.detail-royal .ui-kit-arrows {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 120px;
  padding-bottom: 29px;
}
.detail-royal .ui-kit-dots {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 120px;
}
.detail-royal .ui-kit-dots span {
  width: 17px;
  height: 17px;
  background-color: #fbf4f4;
  box-shadow: 0px 0px 5px #fde9d6, inset 0px 4px 4px rgba(0,0,0,0.25);
  border-radius: 50%;
}
.detail-royal .ui-kit-dots span.active {
  background-color: #00b8ca;
}
.detail-royal .site-button {
  cursor: pointer;
  position: relative;
  display: block;
  width: 205px;
  margin-bottom: 19px;
  padding: 10px 0;
  text-align: center;
  color: #fff;
  font-family: 'Arial', sans-serif;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  background: #429288;
  box-sizing: border-box;
  text-shadow: 0.5px 1px 1px rgba(51,51,51,0.8);
  box-shadow: 0px 4px 10px rgba(0,0,0,0.15), inset 0px -5px 10px rgba(0,0,0,0.15);
}
.detail-royal .site-button-blue {
  background: linear-gradient(180deg, #00c0f7 0%, #00c2f9 0.01%, #00b9ef 35.42%, #018cb7 82.81%);
}
.detail-royal .site-button-yellow {
  background: linear-gradient(180deg, #e7b15a 0%, #e8b25a 0.01%, #dba755 35.42%, #ae7e3e 82.81%);
}
.detail-royal .site-button-hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28,27,73,0.22);
}
.detail-royal .ui-kit-search {
  margin-top: 38px;
  display: block;
  width: 205px;
}
@media (max-width: 1400px) {
  .detail-royal {
    padding-right: 0;
  }
  .detail-royal .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-royal .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-royal .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-bureau {
  color: #332c2c;
  background: #fff;
  height: initial;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-bureau .detail-leaf-down {
  color: #332c2c;
}
.detail-bureau .detail-leaf-down::after {
  background: url("../img/leaf-mouse.svg") 0 0 no-repeat;
}
.detail-bureau .detail-image-wrap {
  padding-right: 0;
  text-align: center;
}
.detail-bureau .detail-image-wrap img {
  width: auto;
}
.detail-bureau .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-top: 80px;
}
.detail-bureau .tags-item a,
.detail-bureau .tags-item span {
  box-shadow: 0 2px 0 0 #1f4e8d;
}
.detail-bureau .detail-page-about,
.detail-bureau .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-bureau .detail-page-link {
  background-color: #1f4e8d;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-bureau .detail-page-link:hover {
  background-color: #0f3e7d;
}
.detail-bureau .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-bureau .detail-styles-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 50px;
}
.detail-bureau .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: 'PT Sans';
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  padding-bottom: 5px;
}
.detail-bureau .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  color: #332c2c;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-bureau .bureau-color {
  width: 105px;
  height: 32px;
  margin-bottom: 30px;
}
.detail-bureau .bureau-blue {
  height: 64px;
  background-color: #16265c;
}
.detail-bureau .bureau-gray {
  background-color: #c4c4c4;
}
.detail-bureau .bureau-lblue {
  background-color: #e9f1f9;
}
.detail-bureau .bureau-lgray {
  background-color: #f5f5f5;
}
.detail-bureau .detail-ui-kit {
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-bureau .detail-ui-font {
  font-family: 'PT Sans';
}
.detail-bureau .detail-font-example {
  display: block;
  color: rgba(233,241,249,0.6);
  font-size: 150px;
  line-height: 1;
  font-weight: bold;
}
.detail-bureau .detail-font-name {
  font-size: 26px;
  line-height: 31px;
  font-weight: bold;
}
.detail-bureau .detail-styles-filter {
  width: 100%;
  padding: 33px 0;
}
.detail-bureau .filter-quantity-wrapper {
  font-family: 'PT Sans';
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
}
.detail-bureau .detail-form {
  font-family: 'PT Sans';
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  padding-bottom: 56px;
}
.detail-bureau .detail-form-slider {
  margin: 24px 0 10px;
  height: 6px;
}
.detail-bureau .detail-form-range {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  background: none;
  border: none;
}
.detail-bureau .noUi-target {
  background: #fafafa;
  border-radius: 0;
  border: none;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}
.detail-bureau .noUi-base,
.detail-bureau .noUi-connects {
  background-color: #c4c4c4;
  border-radius: 0;
}
.detail-bureau .noUi-connect {
  background: #417bbc;
  border-radius: 0;
}
.detail-bureau .noUi-handle-lower {
  display: none;
}
.detail-bureau .noUi-horizontal .noUi-handle {
  cursor: pointer;
  top: -7px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(180deg, #3f78b9 0%, #1f478a 100%);
  box-shadow: initial;
  border: none;
}
.detail-bureau .noUi-horizontal .noUi-handle::before,
.detail-bureau .noUi-horizontal .noUi-handle::after {
  display: none;
}
.detail-bureau .detail-form-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-bureau .filter-taxation > span {
  display: block;
  font-family: 'PT Sans';
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 14px;
}
.detail-bureau .filter-taxation-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.detail-bureau .filter-button {
  display: block;
  width: 125px;
  padding: 4px 0 5px;
  border: 1px solid #417bbc;
  color: #332c2c;
  font-family: 'PT Sans';
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
}
.detail-bureau .filter-button:first-child {
  border-radius: 4px 0 0 4px;
}
.detail-bureau .filter-button:last-child {
  border-radius: 0 4px 4px 0;
}
.detail-bureau .filter-button.active {
  color: #fff;
  font-weight: bold;
  background: linear-gradient(180deg, #417bbc 0%, #1d4588 100%);
}
@media (max-width: 1400px) {
  .detail-bureau {
    padding-right: 0;
  }
  .detail-bureau .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-bureau .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-bureau .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-fly {
  color: #332c2c;
  background: #fff url("../img/fly-big.png") no-repeat 0 0/contain;
  height: initial;
  padding-top: 50px;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-fly .detail-leaf-down {
  color: #fff;
}
.detail-fly .detail-leaf-down::after {
  background: url("../img/leaf-mouse-white.svg") 0 0 no-repeat;
}
.detail-fly .detail-image-wrap {
  padding-right: 50px;
  text-align: right;
}
.detail-fly .detail-image-wrap img {
  width: auto;
}
.detail-fly .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 0px;
  padding-top: 50px;
}
.detail-fly .tags-item a,
.detail-fly .tags-item span {
  box-shadow: 0 2px 0 0 #1f4e8d;
}
.detail-fly .detail-page-about,
.detail-fly .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-fly .detail-page-link {
  background-color: #a9d1ff;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-fly .detail-page-link:hover {
  background-color: #0f3e7d;
}
.detail-fly .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-fly .detail-styles-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-fly .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 80px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-fly .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #a4a4a4;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-fly .fly-color {
  margin-bottom: 54px;
}
.detail-fly .detail-ui-kit {
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-fly .detail-ui-font {
  font-family: 'PT Sans', sans-serif;
  font-size: 98px;
  line-height: 127px;
  font-weight: bold;
}
.detail-fly .detail-font-name {
  display: block;
  color: #000;
}
.detail-fly .detail-font-name:first-child {
  color: rgba(169,209,255,0.4);
}
.detail-fly .detail-ui-basket {
  margin-top: 20px;
}
.detail-fly .detail-ui-buttons {
  padding-top: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 250px;
}
.detail-fly .detail-ui-col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 110px;
}
.detail-fly .detail-ui-col span {
  color: #a4a4a4;
  font-size: 14px;
  line-height: 21px;
  font-weight: normnal;
  text-align: center;
}
@media (max-width: 1400px) {
  .detail-fly {
    padding-left: 60px;
    padding-right: 0;
  }
  .detail-fly .detail-image-wrap {
    padding-right: 60px;
  }
  .detail-fly .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-fly .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-fly .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-sintez {
  color: #332c2c;
  background: #ddd;
  height: initial;
  padding-top: 100px;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-sintez .detail-leaf-down {
  color: #332c2c;
}
.detail-sintez .detail-leaf-down::after {
  background: url("../img/leaf-mouse.svg") 0 0 no-repeat;
}
.detail-sintez .detail-image-wrap {
  padding-right: 150px;
}
.detail-sintez .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-top: 0px;
}
.detail-sintez .tags-item a,
.detail-sintez .tags-item span {
  box-shadow: 0 2px 0 0 #ff5f00;
}
.detail-sintez .detail-page-about,
.detail-sintez .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-sintez .detail-page-link {
  background-color: #ff5f00;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-sintez .detail-page-link:hover {
  background-color: #df4f00;
}
.detail-sintez .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-sintez .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 560px;
  padding-bottom: 80px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-sintez .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-sintez .sintez-color {
  width: 145px;
  height: 137px;
  margin-bottom: 20px;
}
.detail-sintez .sintez-color-1 svg * {
  fill: #ff5f00;
}
.detail-sintez .sintez-color-2 svg * {
  fill: #5bb12c;
}
.detail-sintez .sintez-color-3 svg * {
  fill: #000;
}
.detail-sintez .sintez-color-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 115px;
  margin-bottom: 3px;
  transition: all 0.3s ease-out;
}
.detail-sintez .sintez-color-row:first-child {
  transform: translateX(0);
}
.detail-sintez .sintez-color-row:nth-child(2) {
  transform: translateX(29px);
}
.detail-sintez .sintez-color-row:nth-child(3) {
  transform: translateX(0);
}
.detail-sintez .sintez-color-row:last-child {
  transform: translateX(29px);
}
.detail-sintez .sintez-color:hover .sintez-color-row:first-child {
  transform: translateX(29px);
}
.detail-sintez .sintez-color:hover .sintez-color-row:nth-child(2) {
  transform: translateX(0);
}
.detail-sintez .sintez-color:hover .sintez-color-row:nth-child(3) {
  transform: translateX(29px);
}
.detail-sintez .sintez-color:hover .sintez-color-row:last-child {
  transform: translateX(0);
}
.detail-sintez .detail-font-name {
  display: block;
  width: 100%;
  font-family: 'Gotham Pro', sans-serif;
  font-size: 56px;
  line-height: 1.2;
  font-weight: bold;
  padding-bottom: 20px;
}
.detail-sintez .detail-font-example {
  display: block;
  max-width: 700px;
  font-family: 'Gotham Pro', sans-serif;
  font-size: 24px;
  line-height: 40px;
  font-weight: normal;
  white-space: pre-wrap;
}
@media (max-width: 1400px) {
  .detail-sintez {
    padding-left: 60px;
    padding-right: 0;
  }
  .detail-sintez .detail-image-wrap {
    padding-right: 60px;
  }
  .detail-sintez .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-sintez .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-sintez .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-winfood {
  padding-bottom: 150px;
  color: #332c2c;
  background: #fff;
  height: initial;
}
.detail-winfood .detail-image-wrap {
  padding-top: 50px;
  position: relative;
}
.detail-winfood .detail-image-wrap img {
  width: auto;
}
.detail-winfood .tags-item a,
.detail-winfood .tags-item span {
  box-shadow: 0 2px 0 0 #77b23d;
}
.detail-winfood .detail-page-about,
.detail-winfood .detail-page-table td {
  color: #494949;
}
.detail-winfood .detail-page-link {
  background-color: #77b23d;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-winfood .detail-page-link:hover {
  background-color: #7d9d30;
}
.detail-winfood .detail-styles-container {
  width: 100%;
  max-width: 700px;
}
.detail-winfood .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-winfood .detail-styles-colors {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 350px;
  padding-top: 40px;
  margin: 0 -15px;
}
.detail-winfood .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  margin: 0 15px 25px;
}
.detail-winfood .styles-colors-item div {
  width: 84px;
  height: 84px;
  border-radius: 50%;
}
.detail-winfood .styles-colors-item span {
  color: #332c2c;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}
.detail-winfood .detail-styles-ui {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.detail-winfood .detail-ui-font {
  padding-top: 40px;
  padding-left: 40px;
}
.detail-winfood .detail-font-example {
  display: block;
  margin-bottom: 20px;
}
.detail-winfood .detail-font-name {
  display: block;
  font-family: 'Circle', 'Arial', sans-serif;
  font-size: 47px;
  line-height: 1.1;
  font-weight: bold;
  color: #03193e;
}
.detail-winfood .detail-font-name-2 {
  display: block;
  font-size: 30px;
  line-height: 1.1;
  font-weight: bold;
}
.detail-winfood .detail-font-name-3 {
  display: block;
  font-size: 16px;
  line-height: 1.1;
  font-weight: normal;
}
.detail-winfood .detail-ui-kit {
  width: 55%;
  padding-top: 50px;
}
.detail-winfood .detail-ui-buttons,
.detail-winfood .detail-ui-links,
.detail-winfood .detail-ui-checkboxes {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-winfood .detail-ui-buttons {
  min-width: 400px;
}
.detail-winfood .row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.detail-winfood .period-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
}
.detail-winfood .period-column .row {
  justify-content: space-around;
}
.detail-winfood .period-header {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
}
.detail-winfood .period-label {
  cursor: pointer;
  position: relative;
  margin: 10px 20px 10px 0;
  padding-left: 55px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
}
.detail-winfood .period-label:before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #ffe500;
}
.detail-winfood .period-radio {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
.detail-winfood .period-radio:checked + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 9px;
  background: #ff4c00;
  border-radius: 50%;
  width: 7px;
  height: 7px;
}
.detail-winfood .period-price {
  display: block;
  flex-shrink: 0;
  width: 120px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}
.kcal-btn {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 60px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  letter-spacing: 0.035em;
  text-decoration: none;
  color: #03193e;
  border-radius: 30px;
  border: 3px solid #ffc803;
  transition: all 0.2s ease-out;
}
.kcal-btn.active {
  background: #ffc803;
}
.winfood-colors-1 {
  background: #ffc803;
}
.winfood-colors-2 {
  background: #77b23d;
}
.winfood-colors-3 {
  background: #03193e;
}
.winfood-colors-4 {
  background: #ff4c00;
}
.winfood-colors-5 {
  background: #2c6dd0;
}
.winfood-colors-6 {
  background: #05b286;
}
@media (max-width: 1400px) {
  .detail-winfood {
    padding-left: 60px;
    padding-right: 60px;
  }
  .detail-winfood .detail-font-example {
    font-size: 132px;
  }
  .detail-winfood .detail-styles-ui {
    min-width: 520px;
  }
  .detail-winfood .detail-ui-buttons {
    justify-content: center;
    min-width: 100px;
  }
  .detail-winfood .detail-ui-links {
    width: 360px;
    position: absolute;
    top: 105%;
    left: 0;
  }
  .detail-winfood .detail-ui-checkboxes {
    width: 360px;
    position: absolute;
    top: 125%;
    left: 0;
  }
}
.detail-tosno {
  padding-bottom: 150px;
  color: #332c2c;
  background: #fff;
  height: initial;
}
.detail-tosno .detail-image-wrap {
  position: relative;
  text-align: center;
}
.detail-tosno .detail-image-wrap img {
  width: auto;
}
.detail-tosno .tags-item a,
.detail-tosno .tags-item span {
  box-shadow: 0 2px 0 0 #ea392f;
}
.detail-tosno .detail-page-about,
.detail-tosno .detail-page-table td {
  color: #494949;
}
.detail-tosno .detail-page-link {
  background-color: #ea392f;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-tosno .detail-page-link:hover {
  background-color: #da291f;
}
.detail-tosno .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-tosno .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 1400px) {
  .detail-tosno {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.detail-cozythings {
  padding-bottom: 150px;
  color: #fff;
  background: radial-gradient(131.1% 138.21% at 57.23% 31.11%, #c6c2c0 3.66%, #9c8f8a 26.05%, #695e5b 68.05%);
  height: initial;
}
.detail-cozythings .detail-leaf-down {
  color: rgba(255,255,255,0.7);
}
.detail-cozythings .detail-leaf-down::after {
  background: url("../img/leaf-mouse-white.svg") 0 0 no-repeat;
}
.detail-cozythings .detail-image-wrap {
  position: relative;
  text-align: center;
}
.detail-cozythings .detail-image-wrap img {
  width: auto;
}
.detail-cozythings .detail-page-wrap {
  min-height: 78vh;
  grid-gap: 0;
}
.detail-cozythings .detail-content-wrap {
  max-width: 800px;
}
.detail-cozythings .tags-item a,
.detail-cozythings .tags-item span {
  box-shadow: 0 2px 0 0 #b5968c;
}
.detail-cozythings .detail-page-about,
.detail-cozythings .detail-page-table td {
  color: #fff;
}
.detail-cozythings .detail-page-about {
  max-width: 600px;
}
.detail-cozythings .detail-page-link {
  background-color: #b5968c;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-cozythings .detail-page-link:hover {
  background-color: #a5867c;
}
.detail-cozythings .detail-styles {
  padding-left: calc(8.33333% + 40px);
}
.detail-cozythings .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-cozythings .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 1400px) {
  .detail-cozythings {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.detail-butik {
  padding-bottom: 150px;
  color: #fff;
  background: #0f0f0f url("../img/butik/butik-bg-big.png") no-repeat top center/contain;
  height: initial;
}
.detail-butik .detail-image-wrap img {
  width: auto;
}
.detail-butik .tags-item a,
.detail-butik .tags-item span {
  box-shadow: 0 2px 0 0 #707070;
}
.detail-butik .detail-page-about,
.detail-butik .detail-page-table td {
  color: #fff;
}
.detail-butik .detail-page-link {
  background-color: #fff;
  color: #332c2c;
  transition: all 0.2s ease-out;
}
.detail-butik .detail-page-link:hover {
  background-color: rgba(255,255,255,0.8);
}
.detail-butik .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-butik .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-butik .result-block {
  background-color: #fff;
}
.detail-butik .result-header {
  color: #332c2c;
}
.detail-butik .result-text {
  color: #8c8c8c;
}
.detail-butik .result-text strong {
  color: #332c2c;
}
.detail-epiim {
  padding-bottom: 150px;
  color: #332c2c;
  background: #fff;
}
.detail-epiim .detail-image-wrap {
  position: relative;
  text-align: center;
}
.detail-epiim .detail-image-wrap img {
  width: auto;
}
.detail-epiim .tags-item a,
.detail-epiim .tags-item span {
  box-shadow: 0 2px 0 0 #efc149;
}
.detail-epiim .detail-page-wrap {
  padding-top: 110px;
}
.detail-epiim .detail-page-about,
.detail-epiim .detail-page-table td {
  color: #6a6769;
}
.detail-epiim .detail-page-link {
  background-color: #efc149;
  color: #133652;
  transition: all 0.2s ease-out;
}
.detail-epiim .detail-page-link:hover {
  background-color: rgba(239,193,73,0.8);
}
.detail-epiim .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-epiim .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-cct {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-cct .detail-image-wrap {
  padding-top: 50px;
}
.detail-cct .detail-image-wrap img {
  width: auto;
}
.detail-cct .detail-page-wrap {
  padding-top: 50px;
}
.detail-cct .tags-item a,
.detail-cct .tags-item span {
  box-shadow: 0 2px 0 0 #ed008c;
}
.detail-cct .detail-page-about,
.detail-cct .detail-page-table td {
  color: #6a6769;
}
.detail-cct .detail-page-link {
  background-color: #ed008c;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-cct .detail-page-link:hover {
  background-color: rgba(237,0,140,0.8);
}
.detail-cct .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-cct .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-germes {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-germes .detail-image-wrap img {
  width: auto;
}
.detail-germes .tags-item a,
.detail-germes .tags-item span {
  box-shadow: 0 2px 0 0 #a51111;
}
.detail-germes .detail-page-about,
.detail-germes .detail-page-table td {
  color: #6a6769;
}
.detail-germes .detail-page-link {
  background-color: #a51111;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-germes .detail-page-link:hover {
  background-color: rgba(165,17,17,0.8);
}
.detail-germes .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-germes .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-mediflex {
  padding-bottom: 150px;
  color: #fff;
  background: #00549c;
  height: initial;
}
.detail-mediflex .detail-image-wrap img {
  width: auto;
}
.detail-mediflex .tags-item a,
.detail-mediflex .tags-item span {
  box-shadow: 0 2px 0 0 #80a9ce;
}
.detail-mediflex .detail-page-wrap {
  padding-top: 50px;
}
.detail-mediflex .detail-page-about,
.detail-mediflex .detail-page-table td {
  color: #fff;
}
.detail-mediflex .detail-page-link {
  background-color: #80a9ce;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-mediflex .detail-page-link:hover {
  background-color: rgba(128,169,206,0.8);
}
.detail-mediflex .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-mediflex .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-mediflex .result-block {
  background-color: #fff;
}
.detail-mediflex .result-header {
  color: #332c2c;
}
.detail-mediflex .result-text {
  color: #00549c;
}
.detail-mediflex .result-text strong {
  color: #332c2c;
}
.detail-mediflex .seo-block {
  padding-top: 120px;
}
.detail-sloplast {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-sloplast .detail-image-wrap {
  padding-top: 100px;
}
.detail-sloplast .detail-image-wrap img {
  width: auto;
}
.detail-sloplast .tags-item a,
.detail-sloplast .tags-item span {
  box-shadow: 0 2px 0 0 #0054d2;
}
.detail-sloplast .detail-page-about,
.detail-sloplast .detail-page-table td {
  color: #6a6769;
}
.detail-sloplast .detail-page-link {
  background-color: #0054d2;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-sloplast .detail-page-link:hover {
  background-color: rgba(0,84,210,0.8);
}
.detail-sloplast .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-sloplast .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-maria {
  background: radial-gradient(74.21% 63.17% at 50% 36.83%, #fff 0%, #f9f9f9 53.19%, #d5d5d5 100%);
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-maria .detail-image-wrap {
  padding-top: 100px;
}
.detail-maria .detail-image-wrap img {
  width: auto;
}
.detail-maria .tags-item a,
.detail-maria .tags-item span {
  box-shadow: 0 2px 0 0 #880364;
}
.detail-maria .detail-page-about,
.detail-maria .detail-page-table td {
  color: #6a6769;
}
.detail-maria .detail-page-link {
  background-color: #880364;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-maria .detail-page-link:hover {
  background-color: rgba(136,3,100,0.8);
}
.detail-maria .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-maria .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-karton {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-karton .detail-image-wrap {
  background: #f6f6f6;
}
.detail-karton .detail-image-wrap img {
  width: auto;
}
.detail-karton .detail-page-wrap {
  padding-top: 70px;
}
.detail-karton .tags-item a,
.detail-karton .tags-item span {
  box-shadow: 0 2px 0 0 #ff4c00;
}
.detail-karton .detail-page-about,
.detail-karton .detail-page-table td {
  color: #6a6769;
}
.detail-karton .detail-page-link {
  background-color: #ff4c00;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-karton .detail-page-link:hover {
  background-color: rgba(255,76,0,0.8);
}
.detail-karton .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-karton .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-monolit {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(90deg, #ffc91e 0%, #ffc91e 50%, #484848 50%);
}
.detail-monolit .detail-image-wrap {
  padding-top: 50px;
  overflow: visible;
  margin-bottom: -200px;
}
.detail-monolit .detail-image-wrap img {
  width: auto;
}
.detail-monolit .tags-item a,
.detail-monolit .tags-item span {
  box-shadow: 0 2px 0 0 #fff;
}
.detail-monolit .detail-page-wrap {
  position: relative;
}
.detail-monolit .detail-page-about,
.detail-monolit .detail-page-table td {
  color: #332c2c;
}
.detail-monolit .detail-page-link {
  background-color: #484848;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-monolit .detail-page-link:hover {
  background-color: rgba(72,72,72,0.8);
}
.detail-monolit .detail-styles {
  color: #fff;
  padding-left: 50px;
}
.detail-monolit .detail-styles-container {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-monolit .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-aviator {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-aviator .detail-image-wrap {
  padding-bottom: 50px;
}
.detail-aviator .detail-image-wrap img {
  width: auto;
}
.detail-aviator .tags-item a,
.detail-aviator .tags-item span {
  box-shadow: 0 2px 0 0 #dd1f3d;
}
.detail-aviator .detail-page-about,
.detail-aviator .detail-page-table td {
  color: #6a6769;
}
.detail-aviator .detail-page-link {
  background-color: #dd1f3d;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-aviator .detail-page-link:hover {
  background-color: rgba(221,31,61,0.8);
}
.detail-aviator .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-aviator .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-invest {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-invest .detail-image-wrap img {
  width: auto;
}
.detail-invest .tags-item a,
.detail-invest .tags-item span {
  box-shadow: 0 2px 0 0 #fdc530;
}
.detail-invest .detail-page-about,
.detail-invest .detail-page-table td {
  color: #6a6769;
}
.detail-invest .detail-page-link {
  background-color: #fdc530;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-invest .detail-page-link:hover {
  background-color: rgba(253,197,48,0.8);
}
.detail-invest .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-invest .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-rusles {
  padding-bottom: 0;
  color: #332c2c;
  background: #fff;
  height: initial;
}
.detail-rusles .detail-image-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
}
.detail-rusles .detail-image-wrap img {
  width: auto;
}
.detail-rusles .tags-item a,
.detail-rusles .tags-item span {
  box-shadow: 0 2px 0 0 #0c753c;
}
.detail-rusles .detail-page-wrap {
  padding-top: 50px;
}
.detail-rusles .detail-page-about,
.detail-rusles .detail-page-table td {
  color: #6a6769;
}
.detail-rusles .detail-page-link {
  background-color: #0c753c;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-rusles .detail-page-link:hover {
  background-color: rgba(12,117,60,0.8);
}
.detail-rusles .detail-page-wrap {
  padding-top: 100px;
  background: #f8f8f8;
}
.detail-rusles .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-rusles .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-rusles .result-block {
  background-color: #fff;
}
.detail-rusles .result-header {
  color: #332c2c;
}
.detail-rusles .result-text {
  color: #0c753c;
}
.detail-rusles .result-text strong {
  color: #332c2c;
}
.detail-rusles .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-teatr {
  padding-bottom: 150px;
  color: #fff;
  background: #131e30;
}
.detail-teatr .detail-image-wrap {
  padding-top: 0;
  overflow: visible;
}
.detail-teatr .detail-image-wrap img {
  width: 100%;
}
.detail-teatr .tags-item a,
.detail-teatr .tags-item span {
  box-shadow: 0 2px 0 0 #199e7f;
}
.detail-teatr .detail-page-wrap {
  position: relative;
}
.detail-teatr .detail-page-about,
.detail-teatr .detail-page-table td {
  color: #332c2c;
}
.detail-teatr .detail-page-link {
  background-color: #199e7f;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-teatr .detail-page-link:hover {
  background-color: rgba(25,158,127,0.8);
}
.detail-teatr .detail-styles-container {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-teatr .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-astra {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-astra .detail-image-wrap {
  padding-bottom: 100px;
}
.detail-astra .detail-image-wrap img {
  width: auto;
}
.detail-astra .tags-item a,
.detail-astra .tags-item span {
  box-shadow: 0 2px 0 0 #b74600;
}
.detail-astra .detail-page-about,
.detail-astra .detail-page-table td {
  color: #6a6769;
}
.detail-astra .detail-page-link {
  background-color: #b74600;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-astra .detail-page-link:hover {
  background-color: rgba(183,70,0,0.8);
}
.detail-astra .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-astra .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-caleo {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-caleo .detail-image-wrap {
  padding-bottom: 100px;
}
.detail-caleo .detail-image-wrap img {
  width: auto;
}
.detail-caleo .tags-item a,
.detail-caleo .tags-item span {
  box-shadow: 0 2px 0 0 #fceb7e;
}
.detail-caleo .detail-page-about,
.detail-caleo .detail-page-table td {
  color: #6a6769;
}
.detail-caleo .detail-page-link {
  background-color: #fceb7e;
  color: #9f0203;
  transition: all 0.2s ease-out;
}
.detail-caleo .detail-page-link:hover {
  background-color: rgba(252,235,126,0.8);
}
.detail-caleo .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-caleo .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-electric {
  padding-bottom: 150px;
  color: #332c2c;
  background: url("../img/electric/electric-bg-big.png") no-repeat top right;
}
.detail-electric .detail-image-wrap {
  padding-top: 100px;
}
.detail-electric .detail-image-wrap img {
  width: auto;
}
.detail-electric .tags-item a,
.detail-electric .tags-item span {
  box-shadow: 0 2px 0 0 #ff4c00;
}
.detail-electric .detail-page-about,
.detail-electric .detail-page-table td {
  color: #6a6769;
}
.detail-electric .detail-page-link {
  background-color: #ff4c00;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-electric .detail-page-link:hover {
  background-color: rgba(255,76,0,0.8);
}
.detail-electric .detail-styles {
  color: #fff;
}
.detail-electric .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-electric .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-medsnab {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(136.15deg, #cdebff 4.81%, #a3d5f4 24.4%, #64a4c8 53.63%, #2879a3 82.67%);
}
.detail-medsnab .detail-image-wrap img {
  width: auto;
}
.detail-medsnab .tags-item a,
.detail-medsnab .tags-item span {
  box-shadow: 0 2px 0 0 #fff;
}
.detail-medsnab .detail-page-about,
.detail-medsnab .detail-page-table td {
  color: #6a6769;
}
.detail-medsnab .detail-page-link {
  background-color: #fff;
  color: #136690;
  transition: all 0.2s ease-out;
}
.detail-medsnab .detail-page-link:hover {
  background-color: rgba(255,255,255,0.8);
}
.detail-medsnab .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-medsnab .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-terra {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-terra .detail-image-wrap {
  padding-top: 100px;
  padding-bottom: 50px;
}
.detail-terra .detail-image-wrap img {
  width: auto;
}
.detail-terra .tags-item a,
.detail-terra .tags-item span {
  box-shadow: 0 2px 0 0 #a11b1c;
}
.detail-terra .detail-page-about,
.detail-terra .detail-page-table td {
  color: #6a6769;
}
.detail-terra .detail-page-link {
  background-color: #a11b1c;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-terra .detail-page-link:hover {
  background-color: rgba(161,27,28,0.8);
}
.detail-terra .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-terra .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-erasnab {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-erasnab .detail-image-wrap {
  padding-bottom: 50px;
}
.detail-erasnab .detail-image-wrap img {
  width: auto;
}
.detail-erasnab .tags-item a,
.detail-erasnab .tags-item span {
  box-shadow: 0 2px 0 0 #244082;
}
.detail-erasnab .detail-page-about,
.detail-erasnab .detail-page-table td {
  color: #6a6769;
}
.detail-erasnab .detail-page-link {
  background-color: #244082;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-erasnab .detail-page-link:hover {
  background-color: rgba(36,64,130,0.8);
}
.detail-erasnab .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-erasnab .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-zerkala {
  padding-bottom: 0;
  color: #fff;
  background: #191a1a;
  height: initial;
}
.detail-zerkala .detail-image-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
}
.detail-zerkala .detail-image-wrap img {
  width: auto;
}
.detail-zerkala .tags-item a,
.detail-zerkala .tags-item span {
  box-shadow: 0 2px 0 0 #009de0;
}
.detail-zerkala .detail-page-wrap {
  padding-top: 50px;
}
.detail-zerkala .detail-page-about,
.detail-zerkala .detail-page-table td {
  color: #fff;
}
.detail-zerkala .detail-page-link {
  background-color: #009de0;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-zerkala .detail-page-link:hover {
  background-color: rgba(0,157,224,0.8);
}
.detail-zerkala .detail-page-wrap {
  padding-top: 100px;
}
.detail-zerkala .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-zerkala .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-zerkala .result-block {
  background-color: #fff;
}
.detail-zerkala .result-header {
  color: #332c2c;
}
.detail-zerkala .result-text {
  color: #332c2c;
}
.detail-zerkala .result-text strong {
  color: #009de0;
}
.detail-zerkala .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-katris {
  color: #332c2c;
  background: #fff;
  height: initial;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-katris .detail-image-wrap {
  padding: 0;
  padding-bottom: 50px;
}
.detail-katris .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-top: 0px;
}
.detail-katris .tags-item a,
.detail-katris .tags-item span {
  box-shadow: 0 2px 0 0 #92b7c9;
}
.detail-katris .detail-page-about,
.detail-katris .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-katris .detail-page-link {
  background-color: #e9cf92;
  color: #141414;
  transition: all 0.2s ease-out;
}
.detail-katris .detail-page-link:hover {
  background-color: #92b7c9;
}
.detail-katris .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-katris .detail-styles-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-katris .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 385px;
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-katris .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #a4a4a4;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-katris .katris-color {
  width: 75px;
  height: 75px;
  margin-bottom: 33px;
  transition: 0.3s all ease-out;
}
.detail-katris .katris-blue {
  background-color: #92b7c9;
  box-shadow: 9px -9px 0 0 #fff, 9px -9px 0 1px rgba(146,183,201,0.7);
}
.detail-katris .katris-blue:hover {
  box-shadow: 15px -15px 0 0 #fff, 15px -15px 0 1px rgba(146,183,201,0.7);
}
.detail-katris .katris-yellow {
  background-color: #e9cf92;
  box-shadow: 9px -9px 0 0 #fff, 9px -9px 0 1px rgba(233,207,146,0.7);
}
.detail-katris .katris-yellow:hover {
  box-shadow: 15px -15px 0 0 #fff, 15px -15px 0 1px rgba(233,207,146,0.7);
}
.detail-katris .katris-gray {
  background-color: #999;
  box-shadow: 9px -9px 0 0 #fff, 9px -9px 0 1px rgba(153,153,153,0.7);
}
.detail-katris .katris-gray:hover {
  box-shadow: 15px -15px 0 0 #fff, 15px -15px 0 1px rgba(153,153,153,0.7);
}
.detail-katris .detail-ui-kit {
  width: 100%;
}
.detail-katris .ui-kit-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.detail-katris .ui-kit-col {
  margin-right: 5px;
}
.detail-katris .detail-ui-font {
  display: block;
  font-family: 'Open Sans', sans-serif;
}
.detail-katris .detail-font-example {
  position: relative;
  z-index: 1;
  display: block;
  font-size: 136px;
  line-height: 1.1;
  font-weight: bold;
  color: #e9cf92;
  margin-right: 40px;
}
.detail-katris .detail-font-example::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 19px;
  left: 10px;
  width: 168px;
  height: 100px;
  background-color: #fff;
  background: url("../img/katris-font-Aa.png") 0 0 no-repeat;
}
.detail-katris .detail-font-name {
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  color: #23527c;
  letter-spacing: 0.05em;
}
.detail-katris .pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-top: 70px;
  padding: 0;
  list-style: none;
}
.detail-katris .pagination-item {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  margin: 0 4px;
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  background-color: #999;
}
.detail-katris .pagination-item.active {
  background-color: #92b7c9;
}
.detail-katris .site-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 170px;
  height: 35px;
  margin-right: 60px;
  padding: 12px 0 11px 0;
  text-align: center;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 9px;
  line-height: 12px;
  font-weight: 600;
  letter-spacing: -0.08em;
  text-decoration: none;
  text-transform: uppercase;
  background: #e9cf92;
}
.detail-katris .site-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 14px;
  tranform: translateY(-50%);
  display: block;
  width: 142px;
  height: 1px;
  margin-top: -1px;
  background: #fff;
  z-index: 1;
  box-sizing: content-box;
}
.detail-katris .site-button span {
  padding: 0 7px;
  position: relative;
  z-index: 3;
  background: #e9cf92;
}
.detail-katris .site-button-hover {
  border: 1px solid #4d90fe;
}
.detail-katris .site-link {
  color: #678c9e;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
}
.detail-katris .site-link-hover {
  color: #23527c;
}
@media (max-width: 1400px) {
  .detail-katris {
    padding-left: 60px;
    padding-right: 0;
    background: #fff url("../img/katris-big.jpg") no-repeat 0 0/100% auto;
  }
  .detail-katris .detail-image-wrap {
    padding-right: 60px;
  }
  .detail-katris .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-katris .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-katris .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-linero {
  padding-bottom: 150px;
  color: #fff;
  background: #011d31;
}
.detail-linero .detail-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
  padding-bottom: 50px;
}
.detail-linero .detail-image-wrap img {
  width: auto;
}
.detail-linero .tags-item a,
.detail-linero .tags-item span {
  box-shadow: 0 2px 0 0 #e63222;
}
.detail-linero .detail-page-about,
.detail-linero .detail-page-table td {
  color: #fff;
}
.detail-linero .detail-page-link {
  background-color: #e63222;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-linero .detail-page-link:hover {
  background-color: rgba(230,50,34,0.8);
}
.detail-linero .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-linero .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-bus {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(180deg, #29a54d 11%, #fff 11%);
}
.detail-bus .detail-image-wrap {
  padding-top: 100px;
  padding-bottom: 50px;
  align-items: flex-end;
}
.detail-bus .detail-image-wrap img {
  width: auto;
}
.detail-bus .tags-item a,
.detail-bus .tags-item span {
  box-shadow: 0 2px 0 0 #29a54d;
}
.detail-bus .detail-page-about,
.detail-bus .detail-page-table td {
  color: #6a6769;
}
.detail-bus .detail-page-link {
  background-color: #29a54d;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-bus .detail-page-link:hover {
  background-color: rgba(41,165,77,0.8);
}
.detail-bus .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-bus .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-imperator {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-imperator .detail-image-wrap {
  padding-top: 50px;
  background: #efefef;
}
.detail-imperator .detail-image-wrap img {
  width: auto;
}
.detail-imperator .detail-page-wrap {
  padding-top: 50px;
}
.detail-imperator .tags-item a,
.detail-imperator .tags-item span {
  box-shadow: 0 2px 0 0 #9f6f55;
}
.detail-imperator .detail-page-about,
.detail-imperator .detail-page-table td {
  color: #6a6769;
}
.detail-imperator .detail-page-link {
  background-color: #9f6f55;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-imperator .detail-page-link:hover {
  background-color: rgba(159,111,85,0.8);
}
.detail-imperator .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-imperator .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-konstruktiv {
  color: #332c2c;
  background: #fff url("../img/konstruktiv/bg-big.png") no-repeat top center/cover;
  height: initial;
  padding-top: 100px;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-konstruktiv .detail-image-wrap {
  padding-bottom: 50px;
}
.detail-konstruktiv .detail-image-wrap img {
  width: auto;
}
.detail-konstruktiv .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-top: 0px;
  margin-bottom: -40px;
}
.detail-konstruktiv .tags-item a,
.detail-konstruktiv .tags-item span {
  box-shadow: 0 2px 0 0 #154e9f;
}
.detail-konstruktiv .detail-page-about,
.detail-konstruktiv .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-konstruktiv .detail-page-link {
  background-color: #154e9f;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-konstruktiv .detail-page-link:hover {
  background-color: #053e8f;
}
.detail-konstruktiv .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-konstruktiv .detail-styles-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}
.detail-konstruktiv .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 560px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-konstruktiv .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  color: #000;
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
}
.detail-konstruktiv .konstruktiv-color {
  width: 150px;
  height: 95px;
  margin-bottom: 40px;
}
.detail-konstruktiv .konstruktiv-blue {
  background-color: #154e9f;
  box-shadow: 13px 13px 0 0 rgba(21,78,159,0.4);
}
.detail-konstruktiv .konstruktiv-orange {
  background-color: #f60;
  box-shadow: 13px 13px 0 0 rgba(255,102,0,0.4);
}
.detail-konstruktiv .detail-ui-font {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  min-width: 270px;
  font-size: 32px;
  line-height: 41px;
  font-weight: bold;
  margin-left: 80px;
}
.detail-konstruktiv .detail-font-name {
  display: block;
  padding: 10px 0;
}
.detail-konstruktiv .detail-font-name.cera {
  font-family: 'Cera Pro', sans-serif;
}
.detail-konstruktiv .detail-font-name.ptsans {
  font-family: 'PT Sans Caption', sans-serif;
}
.detail-konstruktiv .detail-ui-kit {
  width: 100%;
  max-width: 680px;
  padding-top: 60px;
}
.detail-konstruktiv .detail-ui-input {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 88px;
  padding-left: 20px;
  color: #b3b3b3;
  background: none;
  border: 1px solid #b3b3b3;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.03em;
}
.detail-konstruktiv .detail-ui-input.active {
  color: #154e9f;
  border: 2px solid #154e9f;
  font-weight: 600;
}
.detail-konstruktiv .site-button {
  align-self: center;
  cursor: pointer;
  position: relative;
  display: block;
  width: 197px;
  padding: 10px 0;
  color: #fff;
  font-family: 'Cera Pro', sans-serif;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-align: center;
  text-decoration: none;
  background: #154e9f;
  box-shadow: -8px 7px 0 #ffaa47;
}
.detail-konstruktiv .site-button-hover {
  background-color: #003378;
}
.detail-konstruktiv .detail-ui-caption {
  display: block;
  margin-top: 80px;
  margin-bottom: 15px;
  color: #332c2c;
  font-family: 'Cera Pro';
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.detail-konstruktiv .detail-ui-tabs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  max-width: 660px;
  padding: 0 19px;
  color: #808080;
  font-family: 'Cera Pro';
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: 0.03em;
  border: 1px solid #acacac;
}
.detail-konstruktiv .ui-tabs-button {
  cursor: pointer;
  height: 32px;
  padding: 3px 20px 5px;
}
.detail-konstruktiv .ui-tabs-button.active {
  color: #1e2024;
  background-color: rgba(20,78,158,0.21);
}
@media (max-width: 1400px) {
  .detail-konstruktiv {
    padding-left: 60px;
    padding-right: 0;
  }
  .detail-konstruktiv .detail-image-wrap {
    padding-right: 60px;
  }
  .detail-konstruktiv .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-konstruktiv .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-ttk {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-ttk .detail-image-wrap {
  background: url("../img/ttk/bg-big.png") no-repeat top center/cover;
  padding-top: 150px;
}
.detail-ttk .detail-image-wrap img {
  width: auto;
}
.detail-ttk .detail-page-wrap {
  padding-top: 50px;
}
.detail-ttk .tags-item a,
.detail-ttk .tags-item span {
  box-shadow: 0 2px 0 0 #fe6602;
}
.detail-ttk .detail-page-about,
.detail-ttk .detail-page-table td {
  color: #6a6769;
}
.detail-ttk .detail-page-link {
  background-color: #fe6602;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-ttk .detail-page-link:hover {
  background-color: rgba(254,102,2,0.8);
}
.detail-ttk .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-ttk .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-dent {
  padding-bottom: 0;
  color: #332c2c;
  background: #fff url("../img/dent/bg-big.jpg") no-repeat 0 0/contain;
  height: initial;
}
.detail-dent .detail-image-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
}
.detail-dent .detail-image-wrap img {
  width: auto;
  max-width: 60%;
}
.detail-dent .tags-item a,
.detail-dent .tags-item span {
  box-shadow: 0 2px 0 0 #d61311;
}
.detail-dent .detail-page-about,
.detail-dent .detail-page-table td {
  color: #6a6769;
}
.detail-dent .detail-page-link {
  background-color: #d61311;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-dent .detail-page-link:hover {
  background-color: rgba(214,19,17,0.8);
}
.detail-dent .detail-page-wrap {
  padding-top: 100px;
}
.detail-dent .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-dent .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-dent .result-block {
  background: linear-gradient(76.5deg, #98979c -4.25%, #dbdbdc 95.35%);
}
.detail-dent .result-header {
  color: #fff;
}
.detail-dent .result-text {
  color: #d61311;
}
.detail-dent .result-text strong {
  color: #d61311;
}
.detail-dent .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
@media (max-width: 1440px) {
  .detail-dent .detail-page-wrap {
    padding-top: 0;
  }
}
.detail-davydov {
  padding-bottom: 150px;
  color: #fff;
  background: #000 radial-gradient(64.7% 56.48% at 53.14% 31.89%, #434242 0%, rgba(0,0,0,0) 100%);
}
.detail-davydov .detail-image-wrap {
  padding-bottom: 50px;
}
.detail-davydov .detail-image-wrap img {
  width: auto;
}
.detail-davydov .tags-item a,
.detail-davydov .tags-item span {
  box-shadow: 0 2px 0 0 #f4c142;
}
.detail-davydov .detail-page-about,
.detail-davydov .detail-page-table td {
  color: #fff;
}
.detail-davydov .detail-page-link {
  background-color: #f4c142;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-davydov .detail-page-link:hover {
  background-color: rgba(244,193,66,0.8);
}
.detail-davydov .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-davydov .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-kallista {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(180deg, #e5eeee 8.93%, #88b7bb 100%);
  height: initial;
}
.detail-kallista .detail-image-wrap img {
  width: auto;
}
.detail-kallista .tags-item a,
.detail-kallista .tags-item span {
  box-shadow: 0 2px 0 0 #669092;
}
.detail-kallista .detail-page-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
}
.detail-kallista .detail-page-about,
.detail-kallista .detail-page-table td {
  color: #332c2c;
}
.detail-kallista .detail-page-link {
  background-color: #669092;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-kallista .detail-page-link:hover {
  background-color: rgba(102,144,146,0.8);
}
.detail-kallista .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-kallista .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-kallista .result-block {
  background-color: #fff;
}
.detail-kallista .result-header {
  color: #332c2c;
}
.detail-kallista .result-text {
  color: #669092;
}
.detail-kallista .result-text strong {
  color: #332c2c;
}
.detail-kallista .seo-block {
  padding-top: 120px;
}
.detail-lenklimat {
  padding-bottom: 150px;
  background-color: #edeef2;
  color: #332c2c;
}
.detail-lenklimat .detail-image-wrap {
  padding-top: 50px;
  background: #fff url("../img/lenklimat/bg-big.png") top right/auto 100% no-repeat;
}
.detail-lenklimat .detail-image-wrap img {
  width: auto;
}
.detail-lenklimat .detail-page-wrap {
  padding-top: 100px;
}
.detail-lenklimat .detail-content-wrap {
  max-width: 100%;
}
.detail-lenklimat .tags-item a,
.detail-lenklimat .tags-item span {
  box-shadow: 0 2px 0 0 #c56a4e;
}
.detail-lenklimat .detail-page-about,
.detail-lenklimat .detail-page-table td {
  color: #6a6769;
}
.detail-lenklimat .detail-page-link {
  background-color: #c56a4e;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-lenklimat .detail-page-link:hover {
  background-color: rgba(197,106,78,0.8);
}
.detail-lenklimat .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-lenklimat .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-flotilion {
  padding-bottom: 150px;
  color: #fff;
  background: #7e8c93;
}
.detail-flotilion .detail-image-wrap {
  padding-top: 100px;
}
.detail-flotilion .detail-image-wrap img {
  width: auto;
}
.detail-flotilion .detail-page-wrap {
  padding-top: 50px;
}
.detail-flotilion .tags-item a,
.detail-flotilion .tags-item span {
  box-shadow: 0 2px 0 0 #ffd301;
}
.detail-flotilion .detail-page-about,
.detail-flotilion .detail-page-table td {
  color: #fff;
}
.detail-flotilion .detail-page-link {
  background-color: #ffd301;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-flotilion .detail-page-link:hover {
  background-color: rgba(255,211,1,0.8);
}
.detail-flotilion .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-flotilion .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-baltrem {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-baltrem .detail-image-wrap img {
  width: 100%;
}
.detail-baltrem .detail-page-wrap {
  padding-top: 50px;
}
.detail-baltrem .tags-item a,
.detail-baltrem .tags-item span {
  box-shadow: 0 2px 0 0 #9f3d0c;
}
.detail-baltrem .detail-page-about,
.detail-baltrem .detail-page-table td {
  color: #6a6769;
}
.detail-baltrem .detail-page-link {
  background-color: #9f3d0c;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-baltrem .detail-page-link:hover {
  background-color: rgba(159,61,12,0.8);
}
.detail-baltrem .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-baltrem .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-setinik {
  padding-bottom: 150px;
  color: #332c2c;
  background: url("../img/setinik/bg-big.png") no-repeat top center;
}
.detail-setinik .detail-image-wrap img {
  width: auto;
}
.detail-setinik .detail-page-wrap {
  padding-top: 50px;
}
.detail-setinik .tags-item a,
.detail-setinik .tags-item span {
  box-shadow: 0 2px 0 0 #d61903;
}
.detail-setinik .detail-page-about,
.detail-setinik .detail-page-table td {
  color: #6a6769;
}
.detail-setinik .detail-page-link {
  background-color: #d61903;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-setinik .detail-page-link:hover {
  background-color: rgba(214,25,3,0.8);
}
.detail-setinik .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-setinik .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-rniz {
  padding-bottom: 150px;
  background: url("../img/rniz/bg-big.png") no-repeat top center/contain;
  color: #332c2c;
}
.detail-rniz .detail-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.detail-rniz .detail-image-wrap img {
  width: auto;
}
.detail-rniz .detail-page-wrap {
  padding-top: 100px;
}
.detail-rniz .tags-item a,
.detail-rniz .tags-item span {
  box-shadow: 0 2px 0 0 #cc2f80;
}
.detail-rniz .detail-page-about,
.detail-rniz .detail-page-table td {
  color: #6a6769;
}
.detail-rniz .detail-page-link {
  background-color: #cc2f80;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-rniz .detail-page-link:hover {
  background-color: rgba(204,47,128,0.8);
}
.detail-rniz .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-rniz .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-sertek {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-sertek .detail-image-wrap {
  padding-top: 100px;
}
.detail-sertek .detail-image-wrap img {
  width: auto;
}
.detail-sertek .detail-page-wrap {
  padding-top: 50px;
}
.detail-sertek .tags-item a,
.detail-sertek .tags-item span {
  box-shadow: 0 2px 0 0 #2b8fc2;
}
.detail-sertek .detail-page-about,
.detail-sertek .detail-page-table td {
  color: #6a6769;
}
.detail-sertek .detail-page-link {
  background-color: #2b8fc2;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-sertek .detail-page-link:hover {
  background-color: rgba(43,143,194,0.8);
}
.detail-sertek .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-sertek .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-chiphunt {
  padding-bottom: 150px;
  color: #fff;
  background: #444;
}
.detail-chiphunt .detail-image-wrap {
  padding-top: 80px;
}
.detail-chiphunt .detail-image-wrap img {
  width: auto;
}
.detail-chiphunt .detail-page-wrap {
  padding-top: 50px;
}
.detail-chiphunt .tags-item a,
.detail-chiphunt .tags-item span {
  box-shadow: 0 2px 0 0 #b04c2a;
}
.detail-chiphunt .detail-page-about,
.detail-chiphunt .detail-page-table td {
  color: #fff;
}
.detail-chiphunt .detail-page-link {
  background-color: #b04c2a;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-chiphunt .detail-page-link:hover {
  background-color: rgba(176,76,42,0.8);
}
.detail-chiphunt .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-chiphunt .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-oxyco {
  padding-bottom: 150px;
  color: #332c2c;
  background: url("../img/oxyco/bg-big.png") no-repeat top center/100% auto, linear-gradient(180.15deg, rgba(196,196,196,0) 31.27%, #ddd 100%);
}
.detail-oxyco .detail-image-wrap {
  padding-top: 150px;
}
.detail-oxyco .detail-image-wrap img {
  width: auto;
}
.detail-oxyco .detail-page-wrap {
  padding-top: 50px;
}
.detail-oxyco .tags-item a,
.detail-oxyco .tags-item span {
  box-shadow: 0 2px 0 0 #4179dd;
}
.detail-oxyco .detail-page-about,
.detail-oxyco .detail-page-table td {
  color: #6a6769;
}
.detail-oxyco .detail-page-link {
  background-color: #4179dd;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-oxyco .detail-page-link:hover {
  background-color: rgba(65,121,221,0.8);
}
.detail-oxyco .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-oxyco .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-dsk {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-dsk .detail-image-wrap {
  padding-top: 0;
}
.detail-dsk .detail-image-wrap img {
  width: auto;
}
.detail-dsk .detail-page-wrap {
  padding-top: 50px;
}
.detail-dsk .tags-item a,
.detail-dsk .tags-item span {
  box-shadow: 0 2px 0 0 #ff0006;
}
.detail-dsk .detail-page-about,
.detail-dsk .detail-page-table td {
  color: #6a6769;
}
.detail-dsk .detail-page-link {
  background-color: #ff0006;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-dsk .detail-page-link:hover {
  background-color: rgba(255,0,6,0.8);
}
.detail-dsk .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-dsk .detail-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail-autojack {
  padding-top: 100px;
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(122.65deg, #fff 28.79%, #ddd 51.17%, #a3a3a3 99.24%);
}
.detail-autojack .detail-image-wrap {
  text-align: center;
}
.detail-autojack .detail-image-wrap img {
  width: auto;
}
.detail-autojack .detail-page-wrap {
  padding-top: 0;
}
.detail-autojack .tags-item a,
.detail-autojack .tags-item span {
  box-shadow: 0 2px 0 0 #1a61ac;
}
.detail-autojack .detail-page-about,
.detail-autojack .detail-page-table td {
  color: #6a6769;
}
.detail-autojack .detail-page-link {
  background-color: #1a61ac;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-autojack .detail-page-link:hover {
  background-color: rgba(26,97,172,0.8);
}
.detail-autojack .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-awm {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-awm .detail-image-wrap {
  text-align: center;
}
.detail-awm .detail-image-wrap img {
  width: auto;
}
.detail-awm .detail-page-wrap {
  padding-top: 50px;
}
.detail-awm .tags-item a,
.detail-awm .tags-item span {
  box-shadow: 0 2px 0 0 #d5192a;
}
.detail-awm .detail-page-about,
.detail-awm .detail-page-table td {
  color: #6a6769;
}
.detail-awm .detail-page-link {
  background-color: #d5192a;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-awm .detail-page-link:hover {
  background-color: rgba(213,25,42,0.8);
}
.detail-awm .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-bioforest {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(1deg, #fff 0%, #e6e6e6 100%);
}
.detail-bioforest .detail-image-wrap {
  text-align: center;
}
.detail-bioforest .detail-image-wrap img {
  width: auto;
}
.detail-bioforest .detail-page-wrap {
  padding-top: 50px;
}
.detail-bioforest .tags-item a,
.detail-bioforest .tags-item span {
  box-shadow: 0 2px 0 0 #1aa21a;
}
.detail-bioforest .detail-page-about,
.detail-bioforest .detail-page-table td {
  color: #6a6769;
}
.detail-bioforest .detail-page-link {
  background-color: #1aa21a;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-bioforest .detail-page-link:hover {
  background-color: rgba(26,162,26,0.8);
}
.detail-bioforest .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-pitergran {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-pitergran .detail-image-wrap {
  text-align: center;
}
.detail-pitergran .detail-image-wrap img {
  width: auto;
}
.detail-pitergran .detail-page-wrap {
  padding-top: 0;
}
.detail-pitergran .tags-item a,
.detail-pitergran .tags-item span {
  box-shadow: 0 2px 0 0 #019cff;
}
.detail-pitergran .detail-page-about,
.detail-pitergran .detail-page-table td {
  color: #6a6769;
}
.detail-pitergran .detail-page-link {
  background-color: #019cff;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-pitergran .detail-page-link:hover {
  background-color: rgba(1,156,255,0.8);
}
.detail-pitergran .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-tarasov {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(180deg, rgba(196,196,196,0) 21.3%, #ddd 70.48%);
}
.detail-tarasov .detail-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.detail-tarasov .detail-image-wrap img {
  width: auto;
}
.detail-tarasov .detail-page-wrap {
  padding-top: 0;
}
.detail-tarasov .tags-item a,
.detail-tarasov .tags-item span {
  box-shadow: 0 2px 0 0 #db3c13;
}
.detail-tarasov .detail-page-about,
.detail-tarasov .detail-page-table td {
  color: #6a6769;
}
.detail-tarasov .detail-page-link {
  background-color: #db3c13;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-tarasov .detail-page-link:hover {
  background-color: rgba(219,60,19,0.8);
}
.detail-tarasov .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-medel {
  padding-bottom: 150px;
  color: #332c2c;
  background: #efefef;
}
.detail-medel .detail-image-wrap {
  background: #93a7aa;
  text-align: center;
}
.detail-medel .detail-image-wrap img {
  width: auto;
}
.detail-medel .detail-page-wrap {
  padding-top: 100px;
}
.detail-medel .tags-item a,
.detail-medel .tags-item span {
  box-shadow: 0 2px 0 0 #1c766e;
}
.detail-medel .detail-page-about,
.detail-medel .detail-page-table td {
  color: #6a6769;
}
.detail-medel .detail-page-link {
  background-color: #1c766e;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-medel .detail-page-link:hover {
  background-color: rgba(28,118,110,0.8);
}
.detail-medel .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-massa {
  padding-bottom: 0;
  color: #332c2c;
  background: linear-gradient(282.07deg, rgba(0,0,0,0.22) 9.17%, rgba(255,255,255,0) 95.7%);
}
.detail-massa .detail-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: center;
}
.detail-massa .detail-image-wrap img {
  width: auto;
}
.detail-massa .detail-page-wrap {
  padding-top: 100px;
}
.detail-massa .tags-item a,
.detail-massa .tags-item span {
  box-shadow: 0 2px 0 0 #1a61ac;
}
.detail-massa .detail-page-about,
.detail-massa .detail-page-table td {
  color: #6a6769;
}
.detail-massa .detail-page-link {
  background-color: #1a61ac;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-massa .detail-page-link:hover {
  background-color: rgba(26,97,172,0.8);
}
.detail-massa .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-massa .result-block {
  background: #fff;
}
.detail-massa .result-header {
  color: #332c2c;
}
.detail-massa .result-text strong {
  color: #1a61ac;
}
.detail-massa .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-eleveolt {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-eleveolt .detail-image-wrap {
  text-align: center;
}
.detail-eleveolt .detail-image-wrap img {
  width: auto;
}
.detail-eleveolt .detail-page-wrap {
  padding-top: 70px;
}
.detail-eleveolt .tags-item a,
.detail-eleveolt .tags-item span {
  box-shadow: 0 2px 0 0 #2f99c9;
}
.detail-eleveolt .detail-page-about,
.detail-eleveolt .detail-page-table td {
  color: #6a6769;
}
.detail-eleveolt .detail-page-link {
  background-color: #2f99c9;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-eleveolt .detail-page-link:hover {
  background-color: rgba(47,153,201,0.8);
}
.detail-eleveolt .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-atrium {
  padding-bottom: 0;
  color: #332c2c;
}
.detail-atrium .detail-image-wrap {
  min-height: 95vh;
}
.detail-atrium .detail-image-wrap img {
  width: auto;
  display: block;
  height: 100%;
  object-fit: cover;
}
.detail-atrium .detail-page-wrap {
  padding-bottom: 150px;
  padding-top: 70px;
  background: linear-gradient(300deg, #d5d5d5 10%, rgba(215,215,215,0.79) 43.04%, rgba(255,255,255,0) 80%);
}
.detail-atrium .tags-item a,
.detail-atrium .tags-item span {
  box-shadow: 0 2px 0 0 #ee362a;
}
.detail-atrium .detail-page-about,
.detail-atrium .detail-page-table td {
  color: #6a6769;
}
.detail-atrium .detail-page-link {
  background-color: #ee362a;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-atrium .detail-page-link:hover {
  background-color: rgba(238,54,42,0.8);
}
.detail-atrium .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-greenmay {
  padding-bottom: 150px;
  color: #332c2c;
  background: linear-gradient(277.22deg, #f9f8f5 -20.22%, #f9f3de -20.21%, #fcfaf2 28.14%, #fff 91.74%);
}
.detail-greenmay .detail-image-wrap {
  text-align: center;
}
.detail-greenmay .detail-image-wrap img {
  width: auto;
}
.detail-greenmay .detail-page-wrap {
  padding-top: 0;
}
.detail-greenmay .tags-item a,
.detail-greenmay .tags-item span {
  box-shadow: 0 2px 0 0 #8a0c34;
}
.detail-greenmay .detail-page-about,
.detail-greenmay .detail-page-table td {
  color: #6a6769;
}
.detail-greenmay .detail-page-link {
  background-color: #8a0c34;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-greenmay .detail-page-link:hover {
  background-color: rgba(138,12,52,0.8);
}
.detail-greenmay .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-baltstroy {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-baltstroy .detail-image-wrap {
  text-align: center;
}
.detail-baltstroy .detail-image-wrap img {
  width: auto;
}
.detail-baltstroy .detail-page-wrap {
  padding-top: 70px;
}
.detail-baltstroy .tags-item a,
.detail-baltstroy .tags-item span {
  box-shadow: 0 2px 0 0 #f1c450;
}
.detail-baltstroy .detail-page-about,
.detail-baltstroy .detail-page-table td {
  color: #6a6769;
}
.detail-baltstroy .detail-page-link {
  background-color: #f1c450;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-baltstroy .detail-page-link:hover {
  background-color: rgba(241,196,80,0.8);
}
.detail-baltstroy .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-hartdiesel {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-hartdiesel .detail-image-wrap {
  padding-top: 50px;
  text-align: center;
}
.detail-hartdiesel .detail-image-wrap img {
  width: auto;
}
.detail-hartdiesel .detail-page-wrap {
  padding-top: 70px;
}
.detail-hartdiesel .tags-item a,
.detail-hartdiesel .tags-item span {
  box-shadow: 0 2px 0 0 #2f99c9;
}
.detail-hartdiesel .detail-page-about,
.detail-hartdiesel .detail-page-table td {
  color: #6a6769;
}
.detail-hartdiesel .detail-page-link {
  background-color: #2f99c9;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-hartdiesel .detail-page-link:hover {
  background-color: rgba(47,153,201,0.8);
}
.detail-hartdiesel .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-womendress {
  padding-bottom: 150px;
  color: #fff;
  background: #e8a0a3;
}
.detail-womendress .detail-image-wrap {
  padding-top: 100px;
  text-align: center;
}
.detail-womendress .detail-image-wrap img {
  width: auto;
}
.detail-womendress .detail-page-wrap {
  padding-top: 70px;
}
.detail-womendress .tags-item a,
.detail-womendress .tags-item span {
  box-shadow: 0 2px 0 0 #c12a7b;
}
.detail-womendress .detail-page-about,
.detail-womendress .detail-page-table td {
  color: #fff;
}
.detail-womendress .detail-page-link {
  background-color: #c12a7b;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-womendress .detail-page-link:hover {
  background-color: rgba(193,42,123,0.8);
}
.detail-womendress .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-symphony {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-symphony .detail-image-wrap {
  text-align: center;
}
.detail-symphony .detail-image-wrap img {
  width: auto;
}
.detail-symphony .detail-page-wrap {
  padding-top: 50px;
}
.detail-symphony .tags-item a,
.detail-symphony .tags-item span {
  box-shadow: 0 2px 0 0 #0c1d3b;
}
.detail-symphony .detail-page-about,
.detail-symphony .detail-page-table td {
  color: #6a6769;
}
.detail-symphony .detail-page-link {
  background-color: #0c1d3b;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-symphony .detail-page-link:hover {
  background-color: rgba(12,29,59,0.8);
}
.detail-symphony .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-nasledie {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-nasledie .detail-image-wrap {
  text-align: center;
}
.detail-nasledie .detail-image-wrap img {
  width: auto;
}
.detail-nasledie .detail-page-wrap {
  padding-top: 70px;
}
.detail-nasledie .tags-item a,
.detail-nasledie .tags-item span {
  box-shadow: 0 2px 0 0 #ee2c35;
}
.detail-nasledie .detail-page-about,
.detail-nasledie .detail-page-table td {
  color: #6a6769;
}
.detail-nasledie .detail-page-link {
  background-color: #ee2c35;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-nasledie .detail-page-link:hover {
  background-color: rgba(238,44,53,0.8);
}
.detail-nasledie .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-souz {
  color: #332c2c;
  background: #fff;
  height: initial;
  padding-top: 100px;
  padding-bottom: 160px;
  padding-right: 0;
}
.detail-souz .detail-leaf-down {
  padding-top: 40px;
  color: #332c2c;
}
.detail-souz .detail-leaf-down::after {
  background: url("../img/leaf-mouse.svg") 0 0 no-repeat;
}
.detail-souz .detail-image-wrap {
  margin: 0 auto;
  margin-top: -50px;
  padding-left: 180px;
  padding-right: 180px;
  padding-bottom: 30px;
}
.detail-souz .detail-page-wrap {
  grid-template-columns: repeat(auto-fit, minmax(450px, auto));
  grid-gap: 0px;
  padding-top: 0px;
}
.detail-souz .tags-item a,
.detail-souz .tags-item span {
  box-shadow: 0 2px 0 0 #0083de;
}
.detail-souz .detail-page-about,
.detail-souz .detail-page-table td {
  max-width: 650px;
  color: #5a5a5a;
}
.detail-souz .detail-page-link {
  background-color: #0083de;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-souz .detail-page-link:hover {
  background-color: #0073ce;
}
.detail-souz .detail-styles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 780px;
  padding-right: 80px;
  padding-top: 35px;
}
.detail-souz .detail-styles-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-souz .detail-styles-colors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 80px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.detail-souz .styles-colors-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #332c2c;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.detail-souz .souz-color {
  margin-bottom: 28px;
}
.detail-souz .detail-ui-font {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.detail-souz .detail-font-name {
  padding-right: 70px;
  font-size: 24px;
  line-height: 31px;
  font-weight: normal;
}
.detail-souz .detail-font-name img {
  display: block;
  margin-bottom: 20px;
}
.detail-souz .detail-font-example {
  display: block;
  max-width: 450px;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  line-height: 1.67;
  font-weight: normal;
  color: #332c2c;
  white-space: pre-wrap;
}
@media (max-width: 1400px) {
  .detail-souz {
    padding-left: 60px;
    padding-right: 0;
  }
  .detail-souz .detail-image-wrap {
    padding-right: 60px;
    padding-left: 60px;
  }
  .detail-souz .detail-page-wrap {
    grid-gap: 50px;
  }
  .detail-souz .detail-ui-kit {
    padding-left: 20px;
  }
  .detail-souz .detail-ui-buttons {
    min-width: 100px;
  }
}
.detail-panoil {
  padding-bottom: 150px;
  color: #fff;
  background: #000;
}
.detail-panoil .detail-image-wrap {
  padding-top: 100px;
  text-align: center;
}
.detail-panoil .detail-image-wrap img {
  width: auto;
}
.detail-panoil .detail-page-wrap {
  padding-top: 70px;
}
.detail-panoil .tags-item a,
.detail-panoil .tags-item span {
  box-shadow: 0 2px 0 0 #3b4b9b;
}
.detail-panoil .detail-page-about,
.detail-panoil .detail-page-table td {
  color: #fff;
}
.detail-panoil .detail-page-link {
  background-color: #3b4b9b;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-panoil .detail-page-link:hover {
  background-color: rgba(59,75,155,0.8);
}
.detail-panoil .detail-styles-container {
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
}
.detail-azbuka {
  color: #332c2c;
  padding-bottom: 150px;
}
.detail-azbuka .detail-image-wrap {
  text-align: center;
}
.detail-azbuka .detail-image-wrap img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.detail-azbuka .detail-page-wrap {
  padding-top: 70px;
}
.detail-azbuka .tags-item a,
.detail-azbuka .tags-item span {
  box-shadow: 0 2px 0 0 #da8339;
}
.detail-azbuka .detail-page-about,
.detail-azbuka .detail-page-table td {
  color: #6a6769;
}
.detail-azbuka .detail-page-link {
  background-color: #da8339;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-azbuka .detail-page-link:hover {
  background-color: rgba(218,131,57,0.8);
}
.detail-azbuka .result-block {
  padding-top: 120px;
  background: linear-gradient(180deg, #fcf7f0 0%, #fff 100%);
}
.detail-azbuka .result-block .col {
  display: block;
}
.detail-azbuka .result-header {
  color: #332c2c;
}
.detail-azbuka .result-text {
  color: #da8339;
}
.detail-azbuka .result-text strong {
  color: #332c2c;
}
.detail-azbuka .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-spectr {
  color: #332c2c;
  padding-bottom: 150px;
}
.detail-spectr .detail-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  background: linear-gradient(116.88deg, #c45663 1.95%, #8f58a9 101.35%);
}
.detail-spectr .detail-image-wrap img {
  width: auto;
  display: block;
}
.detail-spectr .detail-page-wrap {
  padding-top: 70px;
}
.detail-spectr .tags-item a,
.detail-spectr .tags-item span {
  box-shadow: 0 2px 0 0 #66429e;
}
.detail-spectr .detail-page-about,
.detail-spectr .detail-page-table td {
  color: #6a6769;
}
.detail-spectr .detail-page-link {
  background-color: #66429e;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-spectr .detail-page-link:hover {
  background-color: rgba(102,66,158,0.8);
}
.detail-spectr .result-block {
  padding-top: 120px;
  background: linear-gradient(154.43deg, #bb5670 27.39%, #9258a9 108.88%);
  margin-bottom: -150px;
}
.detail-spectr .result-block .col {
  display: block;
}
.detail-spectr .result-header {
  color: #fff;
}
.detail-spectr .result-text {
  color: #66429e;
}
.detail-spectr .result-text strong {
  color: #fff;
}
.detail-spectr .seo-block {
  padding-top: 120px;
  padding-bottom: 70px;
}
.detail-interior {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-interior .detail-image-wrap {
  text-align: center;
  height: 100vh;
}
.detail-interior .detail-image-wrap img {
  width: auto;
  height: 100vh;
  object-fit: cover;
}
.detail-interior .detail-page-wrap {
  padding-top: 70px;
}
.detail-interior .tags-item a,
.detail-interior .tags-item span {
  box-shadow: 0 2px 0 0 #da4c24;
}
.detail-interior .detail-page-about,
.detail-interior .detail-page-table td {
  color: #6a6769;
}
.detail-interior .detail-page-link {
  background-color: #da4c24;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-interior .detail-page-link:hover {
  background-color: rgba(218,76,36,0.8);
}
.detail-interior .result-block {
  margin-bottom: -150px;
  padding-bottom: 150px;
  padding-top: 120px;
  background: linear-gradient(146.05deg, #b6b6b6 -4.8%, rgba(150,150,150,0) 81.95%);
}
.detail-interior .result-block .col {
  display: block;
}
.detail-interior .result-header {
  color: #332c2c;
}
.detail-interior .result-text {
  color: #da4c24;
}
.detail-interior .result-text strong {
  color: #332c2c;
}
.detail-interior .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-webdush {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-webdush .detail-image-wrap {
  text-align: center;
}
.detail-webdush .detail-image-wrap img {
  width: auto;
}
.detail-webdush .detail-content-wrap {
  max-width: 100%;
}
.detail-webdush .detail-page-wrap {
  padding-top: 70px;
}
.detail-webdush .tags-item a,
.detail-webdush .tags-item span {
  box-shadow: 0 2px 0 0 #0065b3;
}
.detail-webdush .detail-page-about,
.detail-webdush .detail-page-table td {
  color: #6a6769;
}
.detail-webdush .detail-page-link {
  background-color: #0065b3;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-webdush .detail-page-link:hover {
  background-color: rgba(0,101,179,0.8);
}
.detail-webdush .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: linear-gradient(180deg, #e8f4ff 0%, #fcfeff 100%);
}
.detail-webdush .result-header {
  color: #332c2c;
}
.detail-webdush .result-text {
  color: #0065b3;
}
.detail-webdush .result-text strong {
  color: #332c2c;
}
.detail-webdush .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-motodor {
  padding-bottom: 150px;
  color: #332c2c;
  background: #e4e4e4;
}
.detail-motodor .detail-image-wrap {
  text-align: center;
}
.detail-motodor .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-motodor .detail-content-wrap {
  max-width: 100%;
}
.detail-motodor .detail-page-wrap {
  padding-top: 70px;
}
.detail-motodor .tags-item a,
.detail-motodor .tags-item span {
  box-shadow: 0 2px 0 0 #ecc765;
}
.detail-motodor .detail-page-about,
.detail-motodor .detail-page-table td {
  color: #6a6769;
}
.detail-motodor .detail-page-link {
  background-color: #ecc765;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-motodor .detail-page-link:hover {
  background-color: rgba(236,199,101,0.8);
}
.detail-motodor .result-block {
  margin-bottom: -150px;
  padding-bottom: 150px;
  padding-top: 120px;
  background: linear-gradient(180deg, #1a1a1a 0%, #656565 100%);
}
.detail-motodor .result-block .col {
  display: block;
}
.detail-motodor .result-header {
  color: #fff;
}
.detail-motodor .result-text {
  color: #ecc765;
}
.detail-motodor .result-text strong {
  color: #fff;
}
.detail-motodor .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-physio {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-physio .detail-image-wrap {
  text-align: center;
}
.detail-physio .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-physio .detail-page-wrap {
  padding-top: 70px;
}
.detail-physio .tags-item a,
.detail-physio .tags-item span {
  box-shadow: 0 2px 0 0 #497fb4;
}
.detail-physio .detail-page-about,
.detail-physio .detail-page-table td {
  color: #6a6769;
}
.detail-physio .detail-page-link {
  background-color: #497fb4;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-physio .detail-page-link:hover {
  background-color: rgba(73,127,180,0.8);
}
.detail-physio .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: linear-gradient(146.05deg, #b6b6b6 -4.8%, rgba(150,150,150,0) 81.95%);
}
.detail-physio .result-block .col {
  display: block;
}
.detail-physio .result-header {
  color: #332c2c;
}
.detail-physio .result-text {
  color: #497fb4;
}
.detail-physio .result-text strong {
  color: #332c2c;
}
.detail-physio .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-roig {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-roig .detail-image-wrap {
  text-align: center;
}
.detail-roig .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-roig .detail-page-wrap {
  padding-top: 70px;
}
.detail-roig .tags-item a,
.detail-roig .tags-item span {
  box-shadow: 0 2px 0 0 #b90d00;
}
.detail-roig .detail-page-about,
.detail-roig .detail-page-table td {
  color: #6a6769;
}
.detail-roig .detail-page-link {
  background-color: #b90d00;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-roig .detail-page-link:hover {
  background-color: rgba(185,13,0,0.8);
}
.detail-roig .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: #071e51;
}
.detail-roig .result-block .col {
  display: block;
}
.detail-roig .result-header {
  color: #fff;
}
.detail-roig .result-text {
  color: #b90d00;
}
.detail-roig .result-text strong {
  color: #fff;
}
.detail-roig .seo-block {
  padding-top: 120px;
  padding-bottom: 120px;
}
.detail-zauber {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-zauber .detail-image-wrap {
  text-align: center;
}
.detail-zauber .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-zauber .detail-page-wrap {
  padding-top: 70px;
}
.detail-zauber .tags-item a,
.detail-zauber .tags-item span {
  box-shadow: 0 2px 0 0 #e89947;
}
.detail-zauber .detail-page-about,
.detail-zauber .detail-page-table td {
  color: #6a6769;
}
.detail-zauber .detail-page-link {
  background-color: #e89947;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-zauber .detail-page-link:hover {
  background-color: rgba(232,153,71,0.8);
}
.detail-zauber .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: linear-gradient(172.14deg, rgba(246,220,121,0.2) 18.43%, rgba(255,255,255,0.2) 87.66%);
}
.detail-zauber .result-block .col {
  display: block;
}
.detail-zauber .result-header {
  color: #332c2c;
}
.detail-zauber .result-text {
  color: #e89947;
}
.detail-zauber .result-text strong {
  color: #332c2c;
}
.detail-zauber .seo-block {
  padding-top: 120px;
  padding-bottom: 180px;
}
.detail-domdacha {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-domdacha .detail-image-wrap {
  text-align: center;
}
.detail-domdacha .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-domdacha .detail-page-wrap {
  padding-top: 70px;
}
.detail-domdacha .tags-item a,
.detail-domdacha .tags-item span {
  box-shadow: 0 2px 0 0 #e2ba85;
}
.detail-domdacha .detail-page-about,
.detail-domdacha .detail-page-table td {
  color: #6a6769;
}
.detail-domdacha .detail-page-link {
  background-color: #e2ba85;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-domdacha .detail-page-link:hover {
  background-color: rgba(226,186,133,0.8);
}
.detail-domdacha .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: linear-gradient(180deg, #fcf7f0 0%, #fff 100%);
}
.detail-domdacha .result-block .col {
  display: block;
}
.detail-domdacha .result-header {
  color: #332c2c;
}
.detail-domdacha .result-text {
  color: #e2ba85;
}
.detail-domdacha .result-text strong {
  color: #332c2c;
}
.detail-domdacha .seo-block {
  padding-top: 120px;
  padding-bottom: 120px;
}
.detail-apteka {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-apteka .detail-image-wrap {
  padding-top: 95px;
  text-align: center;
  background: linear-gradient(167.29deg, #eaeaea -4.52%, rgba(196,196,196,0) 73.4%, #fff 73.4%);
}
.detail-apteka .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-apteka .detail-page-wrap {
  padding-top: 70px;
}
.detail-apteka .tags-item a,
.detail-apteka .tags-item span {
  box-shadow: 0 2px 0 0 #ac0202;
}
.detail-apteka .detail-page-about,
.detail-apteka .detail-page-table td {
  color: #6a6769;
}
.detail-apteka .detail-page-link {
  background-color: #ac0202;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-apteka .detail-page-link:hover {
  background-color: rgba(172,2,2,0.8);
}
.detail-apteka .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: linear-gradient(149.16deg, #ececec -4.26%, #fff 70.29%);
}
.detail-apteka .result-block .col {
  display: block;
}
.detail-apteka .result-header {
  color: #332c2c;
}
.detail-apteka .result-text {
  color: #ac0202;
}
.detail-apteka .result-text strong {
  color: #332c2c;
}
.detail-apteka .seo-block {
  padding-top: 120px;
  padding-bottom: 120px;
}
.detail-skmera {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-skmera .detail-image-wrap {
  text-align: center;
}
.detail-skmera .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-skmera .detail-content-wrap {
  max-width: 100%;
}
.detail-skmera .detail-page-wrap {
  padding-top: 70px;
}
.detail-skmera .tags-item a,
.detail-skmera .tags-item span {
  box-shadow: 0 2px 0 0 #0065b3;
}
.detail-skmera .detail-page-about,
.detail-skmera .detail-page-table td {
  color: #6a6769;
}
.detail-skmera .detail-page-link {
  background-color: #0065b3;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-skmera .detail-page-link:hover {
  background-color: rgba(0,101,179,0.8);
}
.detail-skmera .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: linear-gradient(146.05deg, #b6b6b6 -4.8%, rgba(150,150,150,0) 81.95%);
}
.detail-skmera .result-block .col {
  display: block;
}
.detail-skmera .result-header {
  color: #332c2c;
}
.detail-skmera .result-text {
  color: #0065b3;
}
.detail-skmera .result-text strong {
  color: #332c2c;
}
.detail-skmera .seo-block {
  padding-top: 120px;
  padding-bottom: 120px;
}
.detail-pnsk {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-pnsk .detail-image-wrap {
  background-color: #525459;
  min-height: auto;
}
.detail-pnsk .detail-image-wrap img {
  width: auto;
  display: block;
  object-fit: cover;
}
.detail-pnsk .detail-page-wrap {
  padding-top: 70px;
}
.detail-pnsk .tags-item a,
.detail-pnsk .tags-item span {
  box-shadow: 0 2px 0 0 #58c5fa;
}
.detail-pnsk .detail-page-about,
.detail-pnsk .detail-page-table td {
  color: #6a6769;
}
.detail-pnsk .detail-page-link {
  background-color: #58c5fa;
  color: #fff;
  transition: all 0.2s ease-out;
}
.detail-pnsk .detail-page-link:hover {
  background-color: rgba(88,197,250,0.8);
}
.detail-pnsk .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: #525459;
}
.detail-pnsk .result-block .col {
  display: block;
}
.detail-pnsk .result-header {
  color: #fff;
}
.detail-pnsk .result-text {
  color: #58c5fa;
}
.detail-pnsk .result-text strong {
  color: #fff;
}
.detail-pnsk .seo-block {
  padding-top: 120px;
  padding-bottom: 120px;
}
.detail-ksmoto {
  padding-bottom: 150px;
  color: #332c2c;
}
.detail-ksmoto .detail-image-wrap {
  text-align: center;
}
.detail-ksmoto .detail-image-wrap img {
  width: auto;
  object-fit: cover;
}
.detail-ksmoto .detail-content-wrap {
  max-width: 100%;
}
.detail-ksmoto .detail-page-wrap {
  padding-top: 70px;
}
.detail-ksmoto .tags-item a,
.detail-ksmoto .tags-item span {
  box-shadow: 0 2px 0 0 #fecc08;
}
.detail-ksmoto .detail-page-about,
.detail-ksmoto .detail-page-table td {
  color: #6a6769;
}
.detail-ksmoto .detail-page-link {
  background-color: #fecc08;
  color: #332c2c;
  transition: all 0.2s ease-out;
}
.detail-ksmoto .detail-page-link:hover {
  background-color: rgba(254,204,8,0.8);
}
.detail-ksmoto .result-block {
  margin-bottom: -150px;
  padding-bottom: 200px;
  padding-top: 120px;
  background: radial-gradient(100% 100% at 33.54% 0%, #ececec 0%, #fff 100%);
}
.detail-ksmoto .result-block .col {
  display: block;
}
.detail-ksmoto .result-header {
  color: #332c2c;
}
.detail-ksmoto .result-text {
  color: #fecc08;
}
.detail-ksmoto .result-text strong {
  color: #332c2c;
}
.detail-ksmoto .seo-block {
  padding-top: 120px;
  padding-bottom: 120px;
}
.butik {
  padding-right: 0;
  color: #fff;
  background: #332c2c url("../img/butik/butik-bg.jpg") no-repeat 0 0/cover;
}
.butik h2:hover {
  cursor: pointer;
  color: #707070;
}
.butik .tags-item a,
.butik .tags-item span {
  box-shadow: 0 2px 0 0px #707070;
}
.butik .portfolio-page-about,
.butik .portfolio-page-table td {
  color: #fff;
}
.butik .portfolio-page-link {
  background-color: #707070;
}
.butik .portfolio-case-link {
  color: #707070;
  stroke: #707070;
}
.butik .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  max-height: 650px;
}
.butik .portfolio-image-wrap img {
  object-fit: contain;
  max-width: none;
  width: auto;
  height: auto;
}
@media (max-width: 550px) {
  .butik {
    background: #332c2c url("../img/butik/butik-bg.jpg") no-repeat center center/cover;
  }
  .butik .portfolio-image-wrap img {
    max-width: 100%;
    height: auto;
    margin-right: -350px;
  }
}
.epiim {
  color: #fff;
  background: url("../img/epiim/epiim-bg-m.png") top right/contain no-repeat, linear-gradient(293.2deg, #143d5e 12.56%, #01223d 86.84%);
}
.epiim h2:hover {
  cursor: pointer;
  color: #efc149;
}
.epiim .tags-item a,
.epiim .tags-item span {
  box-shadow: 0 2px 0 0px #efc149;
}
.epiim .portfolio-page-about,
.epiim .portfolio-page-table td {
  color: #fff;
}
.epiim .portfolio-page-link {
  background-color: #efc149;
}
.epiim .portfolio-case-link {
  color: #efc149;
  stroke: #efc149;
}
.epiim .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.epiim .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .epiim {
    background: url("../img/epiim/epiim-bg.png") top center/cover no-repeat, linear-gradient(293.2deg, #143d5e 12.56%, #01223d 86.84%);
  }
}
.cct {
  color: #332c2c;
  background: #fff;
}
.cct h2:hover {
  cursor: pointer;
  color: #ed008c;
}
.cct .tags-item a,
.cct .tags-item span {
  box-shadow: 0 2px 0 0px #ed008c;
}
.cct .portfolio-page-about,
.cct .portfolio-page-table td {
  color: #494949;
}
.cct .portfolio-page-link {
  background-color: #ed008c;
}
.cct .portfolio-case-link {
  color: #ed008c;
  stroke: #ed008c;
}
.cct .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.cct .portfolio-image-wrap img {
  width: auto;
}
.germes {
  color: #332c2c;
  background: #fff url("../img/germes/germes-bg.png") top center/cover no-repeat;
  padding-right: 50px;
}
.germes h2:hover {
  cursor: pointer;
  color: #a51111;
}
.germes .tags-item a,
.germes .tags-item span {
  box-shadow: 0 2px 0 0px #a51111;
}
.germes .portfolio-page-about,
.germes .portfolio-page-table td {
  color: #494949;
}
.germes .portfolio-page-link {
  background-color: #a51111;
}
.germes .portfolio-case-link {
  color: #a51111;
  stroke: #a51111;
}
.germes .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.germes .portfolio-image-wrap img {
  width: auto;
}
.mediflex {
  color: #fff;
  background: #00549c url("../img/mediflex/mediflex-bg.png") no-repeat top right;
}
.mediflex h2:hover {
  cursor: pointer;
  color: #80a9ce;
}
.mediflex .tags-item a,
.mediflex .tags-item span {
  box-shadow: 0 2px 0 0px #80a9ce;
}
.mediflex .portfolio-page-about,
.mediflex .portfolio-page-table td {
  color: #fff;
}
.mediflex .portfolio-page-link {
  background-color: #80a9ce;
}
.mediflex .portfolio-case-link {
  color: #80a9ce;
  stroke: #80a9ce;
}
.mediflex .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.mediflex .portfolio-image-wrap img {
  width: auto;
}
.sloplast {
  padding-right: 0;
  color: #332c2c;
  background: #fff url("../img/sloplast/sloplast-bg-m.png") no-repeat top left;
}
.sloplast h2:hover {
  cursor: pointer;
  color: #0054d2;
}
.sloplast .tags-item a,
.sloplast .tags-item span {
  box-shadow: 0 2px 0 0px #0054d2;
}
.sloplast .portfolio-page-about,
.sloplast .portfolio-page-table td {
  color: #494949;
}
.sloplast .portfolio-page-link {
  background-color: #0054d2;
}
.sloplast .portfolio-case-link {
  color: #0054d2;
  stroke: #0054d2;
}
.sloplast .portfolio-image-wrap {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  overflow: hidden;
}
.sloplast .portfolio-image-wrap img {
  width: 100%;
  margin-bottom: 0;
  margin-right: 0;
}
@media (min-width: 1024px) {
  .sloplast {
    background: #fff url("../img/sloplast/sloplast-bg.png") no-repeat bottom right;
  }
  .sloplast .portfolio-image-wrap {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    max-height: 750px;
    overflow: hidden;
  }
  .sloplast .portfolio-image-wrap img {
    width: 100%;
    margin-bottom: -300px;
  }
}
.maria {
  color: #332c2c;
  background: #d4d5d5 url("../img/maria/maria-bg-m.png") top right/contain no-repeat;
}
.maria h2:hover {
  cursor: pointer;
  color: #880364;
}
.maria .tags-item a,
.maria .tags-item span {
  box-shadow: 0 2px 0 0px #880364;
}
.maria .portfolio-page-about,
.maria .portfolio-page-table td {
  color: #494949;
}
.maria .portfolio-page-link {
  background-color: #880364;
}
.maria .portfolio-case-link {
  color: #880364;
  stroke: #880364;
}
.maria .portfolio-content-wrap {
  height: auto;
}
.maria .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.maria .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .maria {
    background: #e0e0e2 url("../img/maria/maria-bg.png") top right no-repeat;
  }
  .maria .portfolio-content-wrap {
    height: 100%;
    max-width: 550px;
    min-width: auto;
    background-color: #e0e0e2;
    box-shadow: 95px 0 50px #e0e0e2, -155px 0 0 #e0e0e2;
  }
}
.winfood {
  color: #332c2c;
  background: #fff;
}
.winfood h2:hover {
  cursor: pointer;
  color: #77b23d;
}
.winfood .tags-item a,
.winfood .tags-item span {
  box-shadow: 0 2px 0 0px #77b23d;
}
.winfood .portfolio-page-about,
.winfood .portfolio-page-table td {
  color: #494949;
}
.winfood .portfolio-page-link {
  background-color: #77b23d;
}
.winfood .portfolio-case-link {
  color: #77b23d;
  stroke: #77b23d;
}
.winfood .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.winfood .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 960px) {
  .winfood {
    background: #fff url("../img/winfood/winfood-bg.png") center center/cover no-repeat;
  }
  .winfood .portfolio-image-wrap {
    top: 0;
    margin-bottom: -100px;
  }
}
.tosno {
  color: #332c2c;
  background: #fff;
}
.tosno h2:hover {
  cursor: pointer;
  color: #ea392f;
}
.tosno .tags-item a,
.tosno .tags-item span {
  box-shadow: 0 2px 0 0px #ea392f;
}
.tosno .portfolio-page-about,
.tosno .portfolio-page-table td {
  color: #494949;
}
.tosno .portfolio-page-link {
  background-color: #ea392f;
}
.tosno .portfolio-case-link {
  color: #ea392f;
  stroke: #ea392f;
}
.tosno .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.tosno .portfolio-image-wrap img {
  width: auto;
}
.cozythings {
  color: #fff;
  background: radial-gradient(70.58% 203.41% at 68.54% 42.83%, #c6c2c0 7.33%, #9c8f8a 28.52%, #695e5b 68.05%);
}
.cozythings h2:hover {
  cursor: pointer;
  color: #b5968c;
}
.cozythings .tags-item a,
.cozythings .tags-item span {
  box-shadow: 0 2px 0 0px #b5968c;
}
.cozythings .portfolio-page-about,
.cozythings .portfolio-page-table td {
  color: #fff;
}
.cozythings .portfolio-page-link {
  background-color: #b5968c;
}
.cozythings .portfolio-case-link {
  color: #b5968c;
  stroke: #b5968c;
}
.cozythings .portfolio-image-wrap {
  position: relative;
  align-items: flex-end;
  padding-top: 50px;
}
.cozythings .portfolio-image-wrap img {
  display: block;
  width: auto;
}
.cozythings .portfolio-image-decor {
  position: absolute;
  left: -33%;
  bottom: 0;
  width: 630px;
}
@media (min-width: 960px) {
  .cozythings {
    background: radial-gradient(70.58% 203.41% at 68.54% 42.83%, #c6c2c0 7.33%, #9c8f8a 28.52%, #695e5b 68.05%);
  }
}
.nabiss {
  color: #332c2c;
  background: #fff;
}
.nabiss h2:hover {
  cursor: pointer;
  color: #8caf3b;
}
.nabiss .tags-item a,
.nabiss .tags-item span {
  box-shadow: 0 2px 0 0px #8caf3b;
}
.nabiss .portfolio-page-about,
.nabiss .portfolio-page-table td {
  color: #494949;
}
.nabiss .portfolio-page-link {
  background-color: #8caf3b;
}
.nabiss .portfolio-case-link {
  color: #8caf3b;
  stroke: #8caf3b;
}
.nabiss .portfolio-image-wrap {
  top: 10%;
  left: -7%;
  width: 100%;
}
@media (min-width: 960px) {
  .nabiss {
    background: #fff url("../img/nabiss.png") right 0 no-repeat;
  }
}
.restochef {
  color: #fff;
  background: #332c2c;
}
.restochef h2:hover {
  cursor: pointer;
  color: #ffe500;
}
.restochef .tags-item a,
.restochef .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #ffe500;
}
.restochef .portfolio-page-link {
  background: #ffe500;
  color: #000;
}
.restochef .portfolio-case-link {
  color: #ffe500;
  stroke: #ffe500;
}
@media (min-width: 960px) {
  .restochef {
    background: #332c2c url("../img/restochef.png") 150% 0/auto 100% no-repeat;
  }
  .restochef .portfolio-content-wrap {
    width: 95%;
  }
  .restochef .portfolio-image-wrap {
    align-items: center;
  }
  .restochef .portfolio-image-wrap img {
    margin-bottom: -100px;
  }
}
@media (min-width: 1100px) {
  .restochef {
    background: #332c2c url("../img/restochef.png") 150% 0/auto 100% no-repeat;
  }
}
@media (min-width: 1200px) {
  .restochef {
    background: #332c2c url("../img/restochef.png") 130% 0/auto 100% no-repeat;
  }
}
@media (min-width: 1400px) {
  .restochef {
    background: #332c2c url("../img/restochef.png") 125% 0/auto 100% no-repeat;
  }
}
@media (min-width: 1555px) {
  .restochef {
    background: #332c2c url("../img/restochef.png") 100% 0/auto 100% no-repeat;
  }
  .restochef .portfolio-image-wrap {
    margin-right: 0;
  }
  .restochef .portfolio-image-wrap img {
    margin-bottom: -270px;
  }
}
.tourskazka {
  color: #332c2c;
  background: url("../img/tourskazka-bg-big.png") 100% 0/auto 100% no-repeat;
}
.tourskazka h2:hover {
  cursor: pointer;
  color: #8fc029;
}
.tourskazka .tags-item a,
.tourskazka .tags-item span {
  color: #332c2c;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #8fc029;
}
.tourskazka .portfolio-page-about,
.tourskazka .portfolio-page-table td {
  color: #332c2c;
}
.tourskazka .portfolio-page-link {
  background-color: #8fc029;
}
.tourskazka .portfolio-case-link {
  color: #8fc029;
  stroke: #8fc029;
}
@media (min-width: 550px) {
  .tourskazka {
    color: #332c2c;
  }
  .tourskazka .portfolio-image-wrap {
    margin-left: 40px;
    padding: 0;
  }
}
@media (max-width: 550px) {
  .tourskazka .portfolio-image-wrap {
    position: relative;
    align-items: center;
    height: 100%;
  }
}
.lamp {
  color: #232323;
  background: #eaf1f9 url("../img/lamplandia-lamp-2.png") 45px 0/50px auto no-repeat;
}
.lamp h2:hover {
  cursor: pointer;
  color: #7c79e5;
}
.lamp .tags-item a,
.lamp .tags-item span {
  box-shadow: 0 2px 0 0px #7c79e5;
}
.lamp .portfolio-page-about,
.lamp .portfolio-page-table td {
  color: #494949;
}
.lamp .portfolio-page-link {
  background-color: #7c79e5;
}
.lamp .portfolio-case-link {
  color: #7c79e5;
  stroke: #7c79e5;
}
.lamp .portfolio-image-wrap img {
  transform: scale(1.2);
}
@media (min-width: 960px) {
  .lamp {
    background: url("../img/lamplandia.png") right bottom no-repeat, url("../img/lamplandia-lamp.png") 60% -100px no-repeat, #eaf1f9;
  }
  .lamp .portfolio-image-wrap {
    align-items: center;
  }
  .lamp .portfolio-image-wrap img {
    transform: scale(1);
  }
}
.alfaplan {
  color: #332c2c;
  background: url("../img/alfaplan-bg.png") no-repeat 0 0/auto 100%;
}
.alfaplan h2:hover {
  cursor: pointer;
  color: #c70202;
}
.alfaplan .tags-item a,
.alfaplan .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #c70202;
}
.alfaplan .portfolio-page-about {
  max-width: 530px;
}
.alfaplan .portfolio-page-table {
  max-width: 700px;
}
.alfaplan .portfolio-page-about,
.alfaplan .portfolio-page-table td {
  color: #6a6769;
}
.alfaplan .portfolio-page-link {
  background-color: #c70202;
}
.alfaplan .portfolio-case-link {
  color: #c70202;
  stroke: #c70202;
}
.alfaplan .portfolio-image-wrap {
  top: 5%;
}
@media (min-width: 550px) {
  .alfaplan {
    background: url("../img/alfaplan-bg.png") no-repeat -50px 0/auto 100%;
  }
}
@media (min-width: 960px) {
  .alfaplan {
    background: url("../img/alfaplan-bg.png") no-repeat 0 0/auto 100%;
  }
}
@media (min-width: 1100px) {
  .alfaplan {
    background: url("../img/alfaplan-bg.png") no-repeat 0 0/100% 100%;
  }
}
.zosterin {
  color: #fff;
  background: url("../img/zosterin-bubbles-4.png") no-repeat 10% -23px, url("../img/zosterin-bubbles-5.png") no-repeat 100% -1px, url("../img/zosterin-bubbles-6.png") no-repeat 0 40%, url("../img/zosterin-bubbles-7.png") no-repeat 50% 35%, url("../img/zosterin-bubbles-8.png") no-repeat 15% 90%, url("../img/zosterin-bubbles-9.png") no-repeat 30% 119%, url("../img/zosterin-bubbles-10.png") no-repeat 100% 101%, linear-gradient(256.09deg, #83dacf 24%, #3bb0a2 57.2%, #0aa28f 93%);
}
.zosterin h2:hover {
  color: #e2ff00;
}
.zosterin .tags-item a,
.zosterin .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #e2ff00;
}
.zosterin .portfolio-page-about,
.zosterin .portfolio-page-table td {
  color: #fff;
}
.zosterin .portfolio-page-link {
  color: #25aa99;
  background-color: #e2ff00;
}
.zosterin .portfolio-case-link {
  color: #e2ff00;
  stroke: #e2ff00;
}
.zosterin .portfolio-image-wrap {
  top: 93px;
  left: -1%;
  transform: scale(1.15);
}
@media (max-width: 550px) {
  .zosterin {
    background: url("../img/zosterin-m-bubbles-1.png") no-repeat 0 0, url("../img/zosterin-m-bubbles-2.png") no-repeat 100% 60%, linear-gradient(255.08deg, #83dacf 6.07%, #3bb0a2 60.53%, #0aa28f 119.27%);
  }
  .zosterin .tags-item a,
  .zosterin .tags-item span {
    box-shadow: 0 2px 0 0px #5a0089;
  }
}
.royal {
  color: #332c2c;
  background: #fff url("../img/royal-tracery-1.png") no-repeat 0 0/100% 100%;
}
.royal h2:hover {
  color: #1f4e8d;
}
.royal .tags-item a,
.royal .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #00b8ca;
}
.royal .portfolio-page-about {
  max-width: 750px;
}
.royal .portfolio-page-table {
  max-width: 700px;
}
.royal .portfolio-page-about,
.royal .portfolio-page-table td {
  color: #5a5a5a;
}
.royal .portfolio-page-link {
  background-color: #00b8ca;
}
.royal .portfolio-case-link {
  color: #00b8ca;
  stroke: #00b8ca;
}
.royal .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 700px;
}
.royal .portfolio-image-decor {
  position: absolute;
  width: 450px;
  height: auto;
  z-index: -1;
  right: -150px;
  top: 100px;
}
@media (max-width: 550px) {
  .royal {
    background: #fff url("../img/royal-tracery-1.png") no-repeat -950px -50px;
  }
  .royal .portfolio-image-wrap {
    padding-top: 50px;
  }
  .royal .portfolio-image-decor {
    display: none;
  }
}
.bureau {
  color: #fff;
  background: url("../img/bureau-bg-small.jpg") no-repeat 0 0/cover;
}
.bureau h2:hover {
  cursor: pointer;
  color: #2c5aff;
}
.bureau .tags-item a,
.bureau .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #2c5aff;
}
.bureau .portfolio-page-about {
  max-width: 750px;
}
.bureau .portfolio-page-table {
  max-width: 700px;
}
.bureau .portfolio-page-about,
.bureau .portfolio-page-table td {
  color: #b8b8b8;
}
.bureau .portfolio-page-link {
  background-color: #2c5aff;
}
.bureau .portfolio-case-link {
  color: #2c5aff;
  stroke: #2c5aff;
}
.bureau .portfolio-image-wrap {
  justify-content: center;
}
@media (min-width: 550px) {
  .bureau {
    background: url("../img/bureau-bg.png") no-repeat 0 0/auto 100%;
  }
}
.fly {
  color: #332c2c;
  background: url("../img/fly-bg-small.png") no-repeat 0 0;
  background-size: contain;
}
.fly h2:hover {
  cursor: pointer;
  color: #a9d1ff;
}
.fly .tags-item a,
.fly .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #a9d1ff;
}
.fly .portfolio-page-about {
  max-width: 530px;
}
.fly .portfolio-page-table {
  max-width: 700px;
}
.fly .portfolio-page-about,
.fly .portfolio-page-table td {
  color: #5a5a5a;
}
.fly .portfolio-page-link {
  background-color: #a9d1ff;
}
.fly .portfolio-case-link {
  color: #a9d1ff;
  stroke: #a9d1ff;
}
.fly .portfolio-image-wrap {
  top: 10%;
  left: 0;
  width: 100%;
}
@media (min-width: 550px) {
  .fly {
    background: url("../img/fly-bg.jpg") no-repeat 0 0;
    background-size: cover;
  }
}
.sintez {
  color: #332c2c;
  background: #ddd url("../img/sintez-bg.png") no-repeat -80px 0/contain;
}
.sintez h2:hover {
  cursor: pointer;
  color: #e36d27;
}
.sintez .tags-item a,
.sintez .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #e36d27;
}
.sintez .portfolio-page-about {
  max-width: 750px;
}
.sintez .portfolio-page-table {
  max-width: 700px;
}
.sintez .portfolio-page-about,
.sintez .portfolio-page-table td {
  color: #626262;
}
.sintez .portfolio-page-link {
  background-color: #e36d27;
}
.sintez .portfolio-case-link {
  color: #e36d27;
  stroke: #e36d27;
}
.sintez .portfolio-image-wrap {
  justify-content: center;
  width: 70%;
  top: 10%;
  left: auto;
  right: 0;
}
.sintez .portfolio-image-wrap img {
  max-height: 550px;
}
@media (min-width: 550px) {
  .sintez {
    background-position: 60% 70%;
  }
  .sintez .portfolio-image-wrap {
    justify-content: flex-end;
    margin-bottom: -50px;
  }
}
@media (min-width: 960px) {
  .sintez {
    background-position: 100% 0;
    background-size: 50%;
  }
}
.karton {
  color: #332c2c;
  background: #fff;
}
.karton h2:hover {
  cursor: pointer;
  color: #ff4c00;
}
.karton .tags-item a,
.karton .tags-item span {
  box-shadow: 0 2px 0 0px #ff4c00;
}
.karton .portfolio-page-about,
.karton .portfolio-page-table td {
  color: #494949;
}
.karton .portfolio-page-link {
  background-color: #ff4c00;
}
.karton .portfolio-case-link {
  color: #ff4c00;
  stroke: #ff4c00;
}
.karton .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.karton .portfolio-image-wrap img {
  width: auto;
}
.monolit {
  color: #332c2c;
  background: #ffc91e;
}
.monolit h2:hover {
  cursor: pointer;
  color: #484848;
}
.monolit .tags-item a,
.monolit .tags-item span {
  box-shadow: 0 2px 0 0px #fff;
}
.monolit .portfolio-page-about,
.monolit .portfolio-page-table td {
  color: #494949;
}
.monolit .portfolio-page-link {
  background-color: #484848;
}
.monolit .portfolio-case-link {
  color: #484848;
  stroke: #484848;
}
.monolit .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.monolit .portfolio-image-wrap img {
  width: auto;
  margin-bottom: -50px;
}
@media (max-width: 550px) {
  .monolit {
    padding: 0;
  }
  .monolit .portfolio-image-wrap {
    padding: 70px 15px 0;
  }
  .monolit .portfolio-content-wrap {
    background: #484848;
    color: #fff;
    padding: 0 24px 90px;
  }
  .monolit .portfolio-content-wrap .tags-item a,
  .monolit .portfolio-content-wrap .tags-item span {
    box-shadow: 0 2px 0 0px #ffd140;
  }
}
@media (min-width: 550px) {
  .monolit {
    background: linear-gradient(90deg, #ffc91e 0%, #ffc91e 72%, #484848 72%);
  }
}
.aviator {
  color: #332c2c;
  background: #fff url("../img/aviator/aviator-bg.jpg") top center/cover no-repeat;
}
.aviator h2:hover {
  cursor: pointer;
  color: #dd1f3d;
}
.aviator .tags-item a,
.aviator .tags-item span {
  box-shadow: 0 2px 0 0px #dd1f3d;
}
.aviator .portfolio-page-about,
.aviator .portfolio-page-table td {
  color: #494949;
}
.aviator .portfolio-page-link {
  background-color: #dd1f3d;
}
.aviator .portfolio-case-link {
  color: #dd1f3d;
  stroke: #dd1f3d;
}
.aviator .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.aviator .portfolio-image-wrap img {
  width: auto;
}
@media (max-width: 550px) {
  .aviator {
    background: #fff url("../img/aviator/aviator-bg-m.jpg") top center/cover no-repeat;
  }
}
.invest {
  color: #fff;
  background: #fff url("../img/invest/invest-bg.jpg") top center/cover no-repeat;
}
.invest h2:hover {
  cursor: pointer;
  color: #fdc530;
}
.invest .tags-item a,
.invest .tags-item span {
  box-shadow: 0 2px 0 0px #fdc530;
}
.invest .portfolio-page-about,
.invest .portfolio-page-table td {
  color: #fff;
}
.invest .portfolio-page-link {
  background-color: #fdc530;
}
.invest .portfolio-case-link {
  color: #fdc530;
  stroke: #fdc530;
}
.invest .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  padding-top: 50px;
}
.invest .portfolio-image-wrap img {
  width: auto;
}
.rusles {
  color: #332c2c;
  background: #fff;
  padding-right: 0;
}
.rusles h2:hover {
  cursor: pointer;
  color: #0c753c;
}
.rusles .tags-item a,
.rusles .tags-item span {
  box-shadow: 0 2px 0 0px #0c753c;
}
.rusles .portfolio-page-about,
.rusles .portfolio-page-table td {
  color: #494949;
}
.rusles .portfolio-page-link {
  background-color: #0c753c;
}
.rusles .portfolio-case-link {
  color: #0c753c;
  stroke: #0c753c;
}
.rusles .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  padding-top: 50px;
}
.rusles .portfolio-image-wrap img {
  width: auto;
  margin-left: -150px;
}
@media (max-width: 550px) {
  .rusles {
    padding: 0;
  }
  .rusles .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .rusles .portfolio-image-wrap picture {
    width: 100%;
  }
  .rusles .portfolio-image-wrap img {
    margin: 0;
    display: block;
    width: 100%;
  }
  .rusles .portfolio-content-wrap {
    padding: 0 24px 90px;
    background: #f0f0f0;
  }
}
.teatr {
  color: #fff;
  background: #141f31 url("../img/teatr/teatr-bg.jpg") top center/cover no-repeat;
}
.teatr h2:hover {
  cursor: pointer;
  color: #199e7f;
}
.teatr .tags-item a,
.teatr .tags-item span {
  box-shadow: 0 2px 0 0px #199e7f;
}
.teatr .portfolio-page-about,
.teatr .portfolio-page-table td {
  color: #fff;
}
.teatr .portfolio-page-link {
  background-color: #199e7f;
}
.teatr .portfolio-case-link {
  color: #199e7f;
  stroke: #199e7f;
}
.teatr .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.teatr .portfolio-image-wrap img {
  width: auto;
}
@media (max-width: 550px) {
  .teatr .portfolio-image-wrap {
    position: relative;
    height: 100%;
  }
}
.astra {
  color: #332c2c;
  background: #f6f2ef url("../img/astra/astra-bg.png") top center/contain no-repeat;
}
.astra h2:hover {
  cursor: pointer;
  color: #b74600;
}
.astra .tags-item a,
.astra .tags-item span {
  box-shadow: 0 2px 0 0px #b74600;
}
.astra .portfolio-page-about,
.astra .portfolio-page-table td {
  color: #494949;
}
.astra .portfolio-page-link {
  background-color: #b74600;
}
.astra .portfolio-case-link {
  color: #b74600;
  stroke: #b74600;
}
.astra .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.astra .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .astra {
    background: #f6f2ef url("../img/astra/astra-bg.png") top right/auto 100% no-repeat;
  }
  .astra .portfolio-content-wrap {
    position: relative;
    background: #f6f2ef;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }
  .astra .portfolio-content-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 280px;
    height: 100%;
    background: linear-gradient(90deg, #f6f2ef 8.49%, rgba(246,242,239,0) 96.74%);
  }
}
@media (max-width: 1024px) {
  .astra {
    padding-left: 0;
  }
  .astra .portfolio-content-wrap {
    padding-left: 20px;
  }
}
.caleo {
  color: #fff;
  background: #9f0203;
}
.caleo h2:hover {
  cursor: pointer;
  color: #f9ea87;
}
.caleo .tags-item a,
.caleo .tags-item span {
  box-shadow: 0 2px 0 0px #f9ea87;
}
.caleo .portfolio-page-about,
.caleo .portfolio-page-table td {
  color: #fff;
}
.caleo .portfolio-page-link {
  background-color: #f9ea87;
  color: #9f0203;
}
.caleo .portfolio-case-link {
  color: #f9ea87;
  stroke: #f9ea87;
}
.caleo .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.caleo .portfolio-image-wrap img {
  width: auto;
  object-fit: cover;
  height: 100%;
}
@media (min-width: 550px) {
  .caleo {
    padding-right: 0;
  }
  .caleo .portfolio-image-wrap {
    padding-top: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
  }
}
@media (max-width: 550px) {
  .caleo .portfolio-image-wrap {
    position: relative;
    height: 100%;
    align-items: center;
    padding: 60px 0 0;
  }
  .caleo .portfolio-image-wrap picture {
    width: 100%;
  }
  .caleo .portfolio-image-wrap img {
    width: 100%;
  }
}
.electric {
  color: #332c2c;
  background: #fff url("../img/electric/electric-bg-m.png") top center/contain no-repeat;
}
.electric h2:hover {
  cursor: pointer;
  color: #ff4c00;
}
.electric .tags-item a,
.electric .tags-item span {
  box-shadow: 0 2px 0 0px #ff4c00;
}
.electric .portfolio-page-about,
.electric .portfolio-page-table td {
  color: #494949;
}
.electric .portfolio-page-link {
  background-color: #ff4c00;
}
.electric .portfolio-case-link {
  color: #ff4c00;
  stroke: #ff4c00;
}
.electric .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.electric .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .electric {
    padding-right: 100px;
    background: #fff url("../img/electric/electric-bg.png") top right no-repeat;
  }
}
.medsnab {
  color: #332c2c;
  background: url("../img/medsnab/medsnab-bg.png") top center/cover no-repeat, linear-gradient(298.88deg, #176992 5.78%, #b3e1ff 87.98%);
}
.medsnab h2:hover {
  cursor: pointer;
  color: #004699;
}
.medsnab .tags-item a,
.medsnab .tags-item span {
  box-shadow: 0 2px 0 0px #004699;
}
.medsnab .portfolio-page-about,
.medsnab .portfolio-page-table td {
  color: #494949;
}
.medsnab .portfolio-page-link {
  background-color: #004699;
}
.medsnab .portfolio-case-link {
  color: #004699;
  stroke: #004699;
}
.medsnab .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.medsnab .portfolio-image-wrap img {
  width: auto;
}
@media (max-width: 550px) {
  .medsnab {
    background: url("../img/medsnab/medsnab-bg-m.png") top center/contain no-repeat, linear-gradient(143.4deg, #cdebff 5.43%, #2879a3 75.11%);
  }
}
.terra {
  color: #332c2c;
  background: #fff;
  padding-right: 44px;
}
.terra h2:hover {
  cursor: pointer;
  color: #a11b1c;
}
.terra .tags-item a,
.terra .tags-item span {
  box-shadow: 0 2px 0 0px #a11b1c;
}
.terra .portfolio-page-about,
.terra .portfolio-page-table td {
  color: #494949;
}
.terra .portfolio-page-link {
  background-color: #a11b1c;
}
.terra .portfolio-case-link {
  color: #a11b1c;
  stroke: #a11b1c;
}
.terra .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.terra .portfolio-image-wrap img {
  width: auto;
}
@media (max-width: 550px) {
  .terra .portfolio-image-wrap {
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0;
  }
}
.erasnab {
  color: #332c2c;
  background: url("../img/erasnab/bg-m.png") top center/cover no-repeat;
}
.erasnab h2:hover {
  cursor: pointer;
  color: #244082;
}
.erasnab .tags-item a,
.erasnab .tags-item span {
  box-shadow: 0 2px 0 0px #244082;
}
.erasnab .portfolio-page-about,
.erasnab .portfolio-page-table td {
  color: #494949;
}
.erasnab .portfolio-page-link {
  background-color: #244082;
}
.erasnab .portfolio-case-link {
  color: #244082;
  stroke: #244082;
}
.erasnab .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.erasnab .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .erasnab {
    background: url("../img/erasnab/bg.png") top right/cover no-repeat;
  }
}
.zerkala {
  padding-right: 0;
  color: #fff;
  background: #020202;
  grid-template-columns: 690px 1fr;
}
.zerkala h2:hover {
  cursor: pointer;
  color: #009de0;
}
.zerkala .tags-item a,
.zerkala .tags-item span {
  box-shadow: 0 2px 0 0px #009de0;
}
.zerkala .portfolio-page-about,
.zerkala .portfolio-page-table td {
  color: #fff;
}
.zerkala .portfolio-page-link {
  background-color: #009de0;
}
.zerkala .portfolio-case-link {
  color: #009de0;
  stroke: #009de0;
}
.zerkala .portfolio-content-wrap {
  max-width: 695px;
}
.zerkala .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding-top: 0;
}
.zerkala .portfolio-image-wrap img {
  display: block;
  width: 100%;
}
.katris {
  color: #232323;
  background: #fff9f1 url("../img/katris/bg.png") no-repeat top center/cover;
}
.katris h2:hover {
  cursor: pointer;
  color: #92b7c9;
}
.katris .tags-item a,
.katris .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #92b7c9;
}
.katris .portfolio-page-about {
  max-width: 700px;
}
.katris .portfolio-page-table {
  max-width: 700px;
}
.katris .portfolio-page-about,
.katris .portfolio-page-table td {
  color: #5a5a5a;
}
.katris .portfolio-page-link {
  background-color: #92b7c9;
}
.katris .portfolio-case-link {
  color: #92b7c9;
  stroke: #92b7c9;
}
.katris .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
}
.katris .portfolio-image-wrap img {
  display: block;
  width: 100%;
}
@media (max-width: 550px) {
  .katris {
    background-color: #fff9f1;
  }
  .katris .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
  }
  .katris .portfolio-image-wrap img {
    display: block;
    width: 100%;
    box-shadow: 32px 32px 47px rgba(0,0,0,0.25);
  }
}
.linero {
  padding-right: 0;
  color: #fff;
  background: url("../img/linero/bg-m.png") top center/cover no-repeat;
}
.linero h2:hover {
  cursor: pointer;
  color: #c70202;
}
.linero .tags-item a,
.linero .tags-item span {
  box-shadow: 0 2px 0 0px #c70202;
}
.linero .portfolio-page-about,
.linero .portfolio-page-table td {
  color: #fff;
}
.linero .portfolio-page-link {
  background-color: #c70202;
}
.linero .portfolio-case-link {
  color: #c70202;
  stroke: #c70202;
}
@media (min-width: 550px) {
  .linero {
    background: url("../img/linero/bg.png") top right/cover no-repeat;
  }
  .linero .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 50px;
  }
  .linero .portfolio-image-wrap img {
    display: block;
    width: auto;
  }
}
.bus {
  background: #29a54d;
  background: linear-gradient(180deg, #29a54d 29.9%, #fff 30.1%);
  color: #332c2c;
}
.bus h2:hover {
  cursor: pointer;
  color: #f5c017;
}
.bus .tags-item a,
.bus .tags-item span {
  box-shadow: 0 2px 0 0px #f5c017;
}
.bus .portfolio-page-about,
.bus .portfolio-page-table td {
  color: #fff;
}
.bus .portfolio-page-link {
  background-color: #f5c017;
}
.bus .portfolio-case-link {
  color: #f5c017;
  stroke: #f5c017;
}
.bus .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0;
}
.bus .portfolio-image-wrap img {
  width: auto;
}
@media (max-width: 550px) {
  .bus .portfolio-content-wrap {
    padding: 0 24px;
  }
}
@media (min-width: 550px) {
  .bus {
    color: #fff;
    background: url("../img/bus/bg.jpg") no-repeat top center/cover;
  }
}
.konstruktiv {
  padding-right: 24px;
  color: #332c2c;
  background: #fff url("../img/konstruktiv/bg.png") top right/contain no-repeat;
}
.konstruktiv h2:hover {
  cursor: pointer;
  color: #f60;
}
.konstruktiv .tags-item a,
.konstruktiv .tags-item span {
  box-shadow: 0 2px 0 0px #f60;
}
.konstruktiv .portfolio-page-about {
  max-width: 750px;
}
.konstruktiv .portfolio-page-table {
  max-width: 700px;
}
.konstruktiv .portfolio-page-about,
.konstruktiv .portfolio-page-table td {
  color: #626262;
}
.konstruktiv .portfolio-page-link {
  background-color: #f60;
}
.konstruktiv .portfolio-case-link {
  color: #f60;
  stroke: #f60;
}
.konstruktiv .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
}
.konstruktiv .portfolio-image-wrap img {
  display: block;
  width: auto;
}
@media (min-width: 550px) {
  .konstruktiv .portfolio-content-wrap {
    height: 100%;
    background: transparent linear-gradient(90deg, #fff 0%, #fff 55%, rgba(255,255,255,0) 100%);
  }
}
@media (max-width: 550px) {
  .konstruktiv {
    background: #fff url("../img/konstruktiv/bg-m.png") top center/cover no-repeat;
  }
  .konstruktiv .portfolio-content-wrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    background: transparent;
  }
  .konstruktiv .portfolio-image-wrap {
    height: 100%;
    position: static;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}
.imperator {
  color: #332c2c;
  background: #fff;
}
.imperator h2:hover {
  cursor: pointer;
  color: #9f6f55;
}
.imperator .tags-item a,
.imperator .tags-item span {
  box-shadow: 0 2px 0 0px #9f6f55;
}
.imperator .portfolio-page-about,
.imperator .portfolio-page-table td {
  color: #494949;
}
.imperator .portfolio-page-link {
  background-color: #9f6f55;
}
.imperator .portfolio-case-link {
  color: #9f6f55;
  stroke: #9f6f55;
}
.imperator .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 0;
}
.imperator .portfolio-image-wrap img {
  margin-top: -200px;
  display: block;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 550px) {
  .imperator {
    padding: 0;
    background: #efefef;
  }
  .imperator .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .imperator .portfolio-image-wrap picture {
    width: 100%;
  }
  .imperator .portfolio-image-wrap img {
    margin: 0;
    display: block;
    width: 100%;
  }
  .imperator .portfolio-content-wrap {
    padding: 0 24px 90px;
    background: #fff;
  }
}
.ttk {
  color: #fff;
  background: #16488a;
}
.ttk h2:hover {
  cursor: pointer;
  color: #fe6602;
}
.ttk .tags-item a,
.ttk .tags-item span {
  box-shadow: 0 2px 0 0px #fe6602;
}
.ttk .portfolio-page-about,
.ttk .portfolio-page-table td {
  color: #fff;
}
.ttk .portfolio-page-link {
  background-color: #fe6602;
}
.ttk .portfolio-case-link {
  color: #fe6602;
  stroke: #fe6602;
}
.ttk .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.ttk .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .ttk {
    padding-right: 50px;
    background: #16488a url("../img/ttk/bg.png") top right/contain no-repeat;
  }
}
.dent {
  color: #fff;
  background: url("../img/dent/bg-m.jpg") top center/cover no-repeat;
}
.dent h2:hover {
  cursor: pointer;
  color: #d61311;
}
.dent .tags-item a,
.dent .tags-item span {
  box-shadow: 0 2px 0 0px #d61311;
}
.dent .portfolio-page-about,
.dent .portfolio-page-table td {
  color: #fff;
}
.dent .portfolio-page-link {
  background-color: #d61311;
}
.dent .portfolio-case-link {
  color: #d61311;
  stroke: #d61311;
}
.dent .portfolio-image-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.dent .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .dent {
    background: url("../img/dent/bg.png") top center/cover no-repeat;
  }
}
.davydov {
  color: #fff;
  background: #000 radial-gradient(64.7% 56.48% at 53.14% 31.89%, #434242 0%, rgba(0,0,0,0) 100%);
}
.davydov h2:hover {
  cursor: pointer;
  color: #f4c142;
}
.davydov .tags-item a,
.davydov .tags-item span {
  box-shadow: 0 2px 0 0px #f4c142;
}
.davydov .portfolio-page-about,
.davydov .portfolio-page-table td {
  color: #fff;
}
.davydov .portfolio-page-link {
  background-color: #f4c142;
}
.davydov .portfolio-case-link {
  color: #f4c142;
  stroke: #f4c142;
}
.davydov .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.davydov .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .davydov {
    background: radial-gradient(49.93% 121.42% at 85.21% 73.66%, #4d4d4d 0%, #000 100%);
  }
}
.kallista {
  color: #332c2c;
  grid-template-columns: 700px 1fr;
  background: linear-gradient(359.72deg, #9abec0 0.52%, rgba(210,224,225,0) 156.76%);
}
.kallista h2:hover {
  cursor: pointer;
  color: #3e7d80;
}
.kallista .tags-item a,
.kallista .tags-item span {
  box-shadow: 0 2px 0 0px #3e7d80;
}
.kallista .portfolio-page-about,
.kallista .portfolio-page-table td {
  color: #464646;
}
.kallista .portfolio-page-link {
  background-color: #3e7d80;
}
.kallista .portfolio-case-link {
  color: #3e7d80;
  stroke: #3e7d80;
}
.kallista .portfolio-content-wrap {
  max-width: 700px;
}
.kallista .portfolio-image-wrap {
  padding: 0 24px;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.kallista .portfolio-image-wrap img {
  display: block;
  width: auto;
  height: auto;
  filter: drop-shadow(0px 4px 76px rgba(0,0,0,0.15));
}
@media (min-width: 550px) {
  .kallista {
    padding-right: 0;
  }
  .kallista .portfolio-image-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .kallista .portfolio-image-wrap img {
    display: block;
    width: auto;
    height: auto;
  }
}
.lenklimat {
  color: #332c2c;
  background: #fff;
}
.lenklimat h2:hover {
  cursor: pointer;
  color: #c56a4e;
}
.lenklimat .tags-item a,
.lenklimat .tags-item span {
  box-shadow: 0 2px 0 0px #c56a4e;
}
.lenklimat .portfolio-page-about,
.lenklimat .portfolio-page-table td {
  color: #464646;
}
.lenklimat .portfolio-page-link {
  background-color: #c56a4e;
}
.lenklimat .portfolio-case-link {
  color: #c56a4e;
  stroke: #c56a4e;
}
.lenklimat .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.lenklimat .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .lenklimat {
    padding-right: 150px;
    background: #fff url("../img/lenklimat/bg.png") top right/contain no-repeat;
  }
  .lenklimat .portfolio-image-wrap {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .lenklimat .portfolio-image-wrap img {
    width: auto;
  }
}
.flotilion {
  color: #fff;
  background: #7e8c93 url("../img/flotilion/bg-m.png") top center/cover no-repeat;
}
.flotilion h2:hover {
  cursor: pointer;
  color: #fed301;
}
.flotilion .tags-item a,
.flotilion .tags-item span {
  box-shadow: 0 2px 0 0px #fed301;
}
.flotilion .portfolio-page-about,
.flotilion .portfolio-page-table td {
  color: #fff;
}
.flotilion .portfolio-page-link {
  background-color: #fed301;
}
.flotilion .portfolio-case-link {
  color: #fed301;
  stroke: #fed301;
}
.flotilion .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.flotilion .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .flotilion {
    padding-right: 50px;
    background: #7e8c93 url("../img/flotilion/bg.png") top right/contain no-repeat;
  }
}
.baltrem {
  color: #fff;
  grid-template-columns: 750px 1fr;
  background: #1c1c1c;
}
.baltrem h2:hover {
  cursor: pointer;
  color: #9f3d0c;
}
.baltrem .tags-item a,
.baltrem .tags-item span {
  box-shadow: 0 2px 0 0px #9f3d0c;
}
.baltrem .portfolio-page-about,
.baltrem .portfolio-page-table td {
  color: #fff;
}
.baltrem .portfolio-page-link {
  background-color: #9f3d0c;
}
.baltrem .portfolio-case-link {
  color: #9f3d0c;
  stroke: #9f3d0c;
}
.baltrem .portfolio-image-wrap {
  position: relative;
  height: 100%;
  max-height: 750px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
}
.baltrem .portfolio-image-wrap img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 550px) {
  .baltrem {
    padding-right: 0;
  }
}
.setinik {
  color: #332c2c;
  background: #fff;
}
.setinik h2:hover {
  cursor: pointer;
  color: #d61903;
}
.setinik .tags-item a,
.setinik .tags-item span {
  box-shadow: 0 2px 0 0px #d61903;
}
.setinik .portfolio-page-about,
.setinik .portfolio-page-table td {
  color: #464646;
}
.setinik .portfolio-page-link {
  background-color: #d61903;
}
.setinik .portfolio-case-link {
  color: #d61903;
  stroke: #d61903;
}
.setinik .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.setinik .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .setinik {
    padding-right: 100px;
    background: #fff url("../img/setinik/bg.png") top center/cover no-repeat;
  }
}
.rniz {
  color: #fff;
  background: #1e232d url("../img/rniz/bg-m.png") top center/cover no-repeat;
}
.rniz h2:hover {
  cursor: pointer;
  color: #cc2f80;
}
.rniz .tags-item a,
.rniz .tags-item span {
  box-shadow: 0 2px 0 0px #cc2f80;
}
.rniz .portfolio-page-about,
.rniz .portfolio-page-table td {
  color: #fff;
}
.rniz .portfolio-page-link {
  background-color: #cc2f80;
}
.rniz .portfolio-case-link {
  color: #cc2f80;
  stroke: #cc2f80;
}
.rniz .portfolio-image-wrap {
  padding: 0 20px;
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.rniz .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .rniz {
    padding-right: 50px;
    background: #1e232d url("../img/rniz/bg.png") top center/cover no-repeat;
  }
  .rniz .portfolio-image-wrap {
    padding: 0;
  }
  .rniz .portfolio-image-wrap img {
    display: none;
  }
}
.sertek {
  color: #332c2c;
  background: #fff;
  max-height: none;
}
.sertek h2:hover {
  cursor: pointer;
  color: #2b8fc2;
}
.sertek .tags-item a,
.sertek .tags-item span {
  box-shadow: 0 2px 0 0px #2b8fc2;
}
.sertek .portfolio-page-about,
.sertek .portfolio-page-table td {
  color: #464646;
}
.sertek .portfolio-page-link {
  background-color: #2b8fc2;
}
.sertek .portfolio-case-link {
  color: #2b8fc2;
  stroke: #2b8fc2;
}
.sertek .portfolio-image-wrap {
  position: static;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.sertek .portfolio-image-wrap picture {
  height: 100%;
  max-height: 100%;
}
.sertek .portfolio-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (min-width: 550px) {
  .sertek {
    padding-right: 50px;
  }
  .sertek .portfolio-image-wrap {
    max-height: none;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
  }
  .sertek .portfolio-image-wrap picture {
    height: 100%;
    max-height: 100%;
  }
  .sertek .portfolio-image-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.chiphunt {
  color: #fff;
  background: #444;
}
.chiphunt h2:hover {
  cursor: pointer;
  color: #b04d33;
}
.chiphunt .tags-item a,
.chiphunt .tags-item span {
  box-shadow: 0 2px 0 0px #b04d33;
}
.chiphunt .portfolio-page-about,
.chiphunt .portfolio-page-table td {
  color: #fff;
}
.chiphunt .portfolio-page-link {
  background-color: #b04d33;
}
.chiphunt .portfolio-case-link {
  color: #b04d33;
  stroke: #b04d33;
}
.chiphunt .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
}
.chiphunt .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .chiphunt {
    padding-right: 0;
    background: #444 url("../img/chiphunt/bg.png") 100% 45px no-repeat;
  }
}
.oxyco {
  color: #332c2c;
  background: #fff url("../img/oxyco/bg-m.png") top center/contain no-repeat;
}
.oxyco h2:hover {
  cursor: pointer;
  color: #4179dd;
}
.oxyco .tags-item a,
.oxyco .tags-item span {
  box-shadow: 0 2px 0 0px #4179dd;
}
.oxyco .portfolio-page-about,
.oxyco .portfolio-page-table td {
  color: #464646;
}
.oxyco .portfolio-page-link {
  background-color: #4179dd;
}
.oxyco .portfolio-case-link {
  color: #4179dd;
  stroke: #4179dd;
}
.oxyco .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.oxyco .portfolio-image-wrap picture {
  width: 100%;
}
.oxyco .portfolio-image-wrap img {
  width: 100%;
}
@media (min-width: 550px) {
  .oxyco {
    padding-right: 80px;
    background: url("../img/oxyco/bg.png") top right/contain no-repeat, linear-gradient(252.9deg, rgba(255,253,253,0) 3.55%, #dbdbdb 100.9%);
  }
}
.dsk {
  color: #332c2c;
  background: #fcffea;
}
.dsk h2:hover {
  cursor: pointer;
  color: #ff0006;
}
.dsk .tags-item a,
.dsk .tags-item span {
  box-shadow: 0 2px 0 0px #ff0006;
}
.dsk .portfolio-page-about,
.dsk .portfolio-page-table td {
  color: #464646;
}
.dsk .portfolio-page-link {
  background-color: #ff0006;
}
.dsk .portfolio-case-link {
  color: #ff0006;
  stroke: #ff0006;
}
.dsk .portfolio-image-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: cneter;
}
.dsk .portfolio-image-wrap picture {
  width: 100%;
}
.dsk .portfolio-image-wrap img {
  display: block;
  width: 100%;
}
@media (min-width: 550px) {
  .dsk {
    padding-right: 50px;
    background: #fcffea url("../img/dsk/bg.png") top center/cover no-repeat;
  }
  .dsk .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .dsk .portfolio-image-wrap img {
    width: auto;
  }
}
.autojack {
  color: #332c2c;
  background: linear-gradient(109.44deg, #fff 28.79%, #ddd 51.17%, #a3a3a3 99.24%);
}
.autojack h2:hover {
  cursor: pointer;
  color: #1a61ac;
}
.autojack .tags-item a,
.autojack .tags-item span {
  box-shadow: 0 2px 0 0px #1a61ac;
}
.autojack .portfolio-page-about,
.autojack .portfolio-page-table td {
  color: #464646;
}
.autojack .portfolio-page-link {
  background-color: #1a61ac;
}
.autojack .portfolio-case-link {
  color: #1a61ac;
  stroke: #1a61ac;
}
.autojack .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.autojack .portfolio-image-wrap picture {
  width: 100%;
}
.autojack .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .autojack {
    padding-right: 0;
    background: linear-gradient(147.9deg, #fff 40.29%, #ddd 62.65%, #8f8f8f 82.35%);
  }
  .autojack .portfolio-image-wrap {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.awm {
  color: #fff;
  background: #404040 url("../img/awm/bg-m.png") top center/cover no-repeat;
}
.awm h2:hover {
  cursor: pointer;
  color: #d9172a;
}
.awm .tags-item a,
.awm .tags-item span {
  box-shadow: 0 2px 0 0px #d9172a;
}
.awm .portfolio-page-about,
.awm .portfolio-page-table td {
  color: #fff;
}
.awm .portfolio-page-link {
  background-color: #d9172a;
}
.awm .portfolio-case-link {
  color: #d9172a;
  stroke: #d9172a;
}
.awm .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.awm .portfolio-image-wrap picture {
  width: 100%;
}
.awm .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .awm {
    padding-right: 20px;
    background: #404040 url("../img/awm/bg.png") top center/cover no-repeat;
  }
}
.bioforest {
  color: #332c2c;
  background: #fff url("../img/bioforest/bg-m.png") top center/contain no-repeat;
}
.bioforest h2:hover {
  cursor: pointer;
  color: #1aa21a;
}
.bioforest .tags-item a,
.bioforest .tags-item span {
  box-shadow: 0 2px 0 0px #1aa21a;
}
.bioforest .portfolio-page-about,
.bioforest .portfolio-page-table td {
  color: #464646;
}
.bioforest .portfolio-page-link {
  background-color: #1aa21a;
}
.bioforest .portfolio-case-link {
  color: #1aa21a;
  stroke: #1aa21a;
}
.bioforest .portfolio-image-wrap {
  padding: 0 36px;
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
.bioforest .portfolio-image-wrap img {
  width: auto;
  filter: drop-shadow(20px 12px 40px rgba(0,0,0,0.34));
}
@media (min-width: 550px) {
  .bioforest {
    padding-right: 100px;
    background: url("../img/bioforest/bg.png") top right/auto 100% no-repeat;
  }
  .bioforest .portfolio-image-wrap {
    padding: 0;
  }
  .bioforest .portfolio-image-wrap img {
    filter: none;
  }
}
.pitergran {
  color: #fff;
  background: #fff url("../img/pitergran/bg-m.png") top center/cover no-repeat;
}
.pitergran h2:hover {
  cursor: pointer;
  color: #019cff;
}
.pitergran .tags-item a,
.pitergran .tags-item span {
  box-shadow: 0 2px 0 0px #019cff;
}
.pitergran .portfolio-page-about,
.pitergran .portfolio-page-table td {
  color: #fff;
}
.pitergran .portfolio-page-link {
  background-color: #019cff;
}
.pitergran .portfolio-case-link {
  color: #019cff;
  stroke: #019cff;
}
.pitergran .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.pitergran .portfolio-image-wrap picture {
  width: 100%;
}
.pitergran .portfolio-image-wrap img {
  width: 100%;
}
@media (min-width: 550px) {
  .pitergran {
    background: url("../img/pitergran/bg.png") top center/cover no-repeat;
  }
}
.tarasov {
  color: #332c2c;
  background: #fff;
  max-height: none;
  height: auto;
}
.tarasov h2:hover {
  cursor: pointer;
  color: #db3c13;
}
.tarasov .tags-item a,
.tarasov .tags-item span {
  box-shadow: 0 2px 0 0px #db3c13;
}
.tarasov .portfolio-page-about,
.tarasov .portfolio-page-table td {
  color: #464646;
}
.tarasov .portfolio-page-link {
  background-color: #db3c13;
}
.tarasov .portfolio-case-link {
  color: #db3c13;
  stroke: #db3c13;
}
.tarasov .portfolio-image-wrap {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.tarasov .portfolio-image-wrap picture {
  width: 100%;
}
.tarasov .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .tarasov {
    padding-right: 10px;
  }
  .tarasov .portfolio-image-wrap {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;
  }
  .tarasov .portfolio-image-wrap picture {
    width: auto;
  }
  .tarasov .portfolio-image-wrap img {
    width: auto;
  }
}
.medel {
  color: #fff;
  background: #93a7aa;
}
.medel h2:hover {
  cursor: pointer;
  color: #1c766e;
}
.medel .tags-item a,
.medel .tags-item span {
  box-shadow: 0 2px 0 0px #1c766e;
}
.medel .portfolio-page-about,
.medel .portfolio-page-table td {
  color: #fff;
}
.medel .portfolio-page-link {
  background-color: #1c766e;
}
.medel .portfolio-case-link {
  color: #1c766e;
  stroke: #1c766e;
}
.medel .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.medel .portfolio-image-wrap picture {
  width: 100%;
}
.medel .portfolio-image-wrap img {
  width: 100%;
}
@media (min-width: 550px) {
  .medel {
    background: linear-gradient(242.85deg, #a5b5b3 -32.28%, #8ba1a6 92.89%);
    padding-right: 0;
  }
  .medel .portfolio-image-wrap {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.massa {
  color: #332c2c;
  background: linear-gradient(280.46deg, rgba(0,0,0,0.22) 9.17%, rgba(255,255,255,0) 95.7%);
  height: auto;
  max-height: none;
}
.massa h2:hover {
  cursor: pointer;
  color: #0065b3;
}
.massa .tags-item a,
.massa .tags-item span {
  box-shadow: 0 2px 0 0px #0065b3;
}
.massa .portfolio-page-about,
.massa .portfolio-page-table td {
  color: #464646;
}
.massa .portfolio-page-link {
  background-color: #0065b3;
}
.massa .portfolio-case-link {
  color: #0065b3;
  stroke: #0065b3;
}
.massa .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.massa .portfolio-image-wrap picture {
  width: 100%;
}
.massa .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .massa {
    padding-right: 80px;
    background: url("../img/massa/bg.png") top right/contain no-repeat, linear-gradient(280deg, rgba(0,0,0,0.22) 9.17%, rgba(255,255,255,0) 95%);
  }
}
.eleveolt {
  color: #332c2c;
  background: #fff url("../img/eleveolt/bg-m.png") top center/cover no-repeat;
}
.eleveolt h2:hover {
  cursor: pointer;
  color: #2f99c9;
}
.eleveolt .tags-item a,
.eleveolt .tags-item span {
  box-shadow: 0 2px 0 0px #2f99c9;
}
.eleveolt .portfolio-page-about,
.eleveolt .portfolio-page-table td {
  color: #464646;
}
.eleveolt .portfolio-page-link {
  background-color: #2f99c9;
}
.eleveolt .portfolio-case-link {
  color: #2f99c9;
  stroke: #2f99c9;
}
.eleveolt .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.eleveolt .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .eleveolt {
    padding-right: 75px;
    background: url("../img/eleveolt/bg.png") top right/cover no-repeat;
  }
}
.atrium {
  color: #332c2c;
  background: url("../img/atrium/bg-m.png") top center/contain no-repeat, linear-gradient(280.46deg, rgba(0,0,0,0.22) 9.17%, rgba(255,255,255,0) 95.7%);
}
.atrium h2:hover {
  cursor: pointer;
  color: #ee362a;
}
.atrium .tags-item a,
.atrium .tags-item span {
  box-shadow: 0 2px 0 0px #ee362a;
}
.atrium .portfolio-page-about,
.atrium .portfolio-page-table td {
  color: #464646;
}
.atrium .portfolio-page-link {
  background-color: #ee362a;
}
.atrium .portfolio-case-link {
  color: #ee362a;
  stroke: #ee362a;
}
.atrium .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.atrium .portfolio-image-wrap picture {
  width: 100%;
}
.atrium .portfolio-image-wrap img {
  width: 100%;
}
@media (min-width: 550px) {
  .atrium {
    padding-right: 80px;
    background: #c4c4c4 url("../img/atrium/bg.png") top center/cover no-repeat;
  }
}
.greenmay {
  color: #332c2c;
  background: linear-gradient(180deg, #fffefd 0%, #fbf7e9 100%);
  height: auto;
  max-height: none;
}
.greenmay h2:hover {
  cursor: pointer;
  color: #8a0c34;
}
.greenmay .tags-item a,
.greenmay .tags-item span {
  box-shadow: 0 2px 0 0px #8a0c34;
}
.greenmay .portfolio-page-about,
.greenmay .portfolio-page-table td {
  color: #464646;
}
.greenmay .portfolio-page-link {
  background-color: #8a0c34;
}
.greenmay .portfolio-case-link {
  color: #8a0c34;
  stroke: #8a0c34;
}
.greenmay .portfolio-image-wrap {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.greenmay .portfolio-image-wrap picture {
  width: 100%;
}
.greenmay .portfolio-image-wrap img {
  width: 100%;
}
@media (min-width: 550px) {
  .greenmay {
    background: url("../img/greenmay/bg.png") no-repeat top center/cover;
    padding-right: 0;
  }
  .greenmay .portfolio-image-wrap img {
    display: none;
  }
}
.baltstroy {
  color: #fff;
  background: linear-gradient(2.7deg, #386f83 -7.61%, #56bbe1 98.16%);
}
.baltstroy h2:hover {
  cursor: pointer;
  color: #f1c450;
}
.baltstroy .tags-item a,
.baltstroy .tags-item span {
  box-shadow: 0 2px 0 0px #f1c450;
}
.baltstroy .portfolio-page-about,
.baltstroy .portfolio-page-table td {
  color: #fff;
}
.baltstroy .portfolio-page-link {
  background-color: #f1c450;
}
.baltstroy .portfolio-case-link {
  color: #f1c450;
  stroke: #f1c450;
}
.baltstroy .portfolio-image-wrap {
  position: relative;
  height: 100%;
  padding-left: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.baltstroy .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .baltstroy {
    padding-right: 0;
    background: url("../img/baltstroy/bg.png") top right no-repeat, linear-gradient(2.7deg, #386f83 -7.61%, #56bbe1 98.16%);
  }
  .baltstroy .portfolio-image-wrap {
    padding-left: 0;
  }
}
.hartdiesel {
  color: #332c2c;
  background: #ededed;
}
.hartdiesel h2:hover {
  cursor: pointer;
  color: #005dad;
}
.hartdiesel .tags-item a,
.hartdiesel .tags-item span {
  box-shadow: 0 2px 0 0px #005dad;
}
.hartdiesel .portfolio-page-about,
.hartdiesel .portfolio-page-table td {
  color: #464646;
}
.hartdiesel .portfolio-page-link {
  background-color: #005dad;
}
.hartdiesel .portfolio-case-link {
  color: #005dad;
  stroke: #005dad;
}
.hartdiesel .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.hartdiesel .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .hartdiesel {
    padding-right: 75px;
  }
}
.womendress {
  color: #fff;
  background: #eaa2a5 url("../img/womendress/bg-m.png") no-repeat bottom center/contain;
}
.womendress h2:hover {
  cursor: pointer;
  color: #c12a7b;
}
.womendress .tags-item a,
.womendress .tags-item span {
  box-shadow: 0 2px 0 0px #c12a7b;
}
.womendress .portfolio-page-about,
.womendress .portfolio-page-table td {
  color: #fff;
}
.womendress .portfolio-page-link {
  background-color: #c12a7b;
}
.womendress .portfolio-case-link {
  color: #c12a7b;
  stroke: #c12a7b;
}
.womendress .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
}
.womendress .portfolio-image-wrap img {
  width: auto;
  display: none;
}
@media (min-width: 550px) {
  .womendress {
    padding-right: 50px;
    background: #eaa2a5;
  }
  .womendress .portfolio-image-wrap img {
    display: block;
  }
}
.symphony {
  color: #332c2c;
  background: linear-gradient(180deg, #ebebeb 2.44%, #f0f0f0 100%);
}
.symphony h2:hover {
  cursor: pointer;
  color: #0d1d3b;
}
.symphony .tags-item a,
.symphony .tags-item span {
  box-shadow: 0 2px 0 0px #0d1d3b;
}
.symphony .portfolio-page-about,
.symphony .portfolio-page-table td {
  color: #464646;
}
.symphony .portfolio-page-link {
  background-color: #0d1d3b;
}
.symphony .portfolio-case-link {
  color: #0d1d3b;
  stroke: #0d1d3b;
}
.symphony .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.symphony .portfolio-image-wrap img {
  width: auto;
  mix-blend-mode: darken;
}
@media (min-width: 550px) {
  .symphony {
    background: #fff url("../img/symphony/bg.png") top center/cover no-repeat;
  }
  .symphony .portfolio-image-wrap img {
    display: none;
  }
}
.nasledie {
  color: #332c2c;
  background: #f6fdff;
}
.nasledie h2:hover {
  cursor: pointer;
  color: #ee2c35;
}
.nasledie .tags-item a,
.nasledie .tags-item span {
  box-shadow: 0 2px 0 0px #ee2c35;
}
.nasledie .portfolio-page-about,
.nasledie .portfolio-page-table td {
  color: #464646;
}
.nasledie .portfolio-page-link {
  background-color: #ee2c35;
}
.nasledie .portfolio-case-link {
  color: #ee2c35;
  stroke: #ee2c35;
}
.nasledie .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.nasledie .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .nasledie {
    padding-right: 75px;
    background: url("../img/nasledie/bg.png") top right no-repeat;
  }
}
.souz {
  color: #332c2c;
  background: linear-gradient(239.68deg, #fff 0%, #d3d3d3 72.97%);
}
.souz h2:hover {
  cursor: pointer;
  color: #0083de;
}
.souz .tags-item a,
.souz .tags-item span {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 2px 0 0px #0083de;
}
.souz .portfolio-page-about {
  max-width: 750px;
}
.souz .portfolio-page-table {
  max-width: 700px;
}
.souz .portfolio-page-about,
.souz .portfolio-page-table td {
  color: #626262;
}
.souz .portfolio-page-link {
  background-color: #0083de;
}
.souz .portfolio-case-link {
  color: #0083de;
  stroke: #0083de;
}
.souz .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.souz .portfolio-image-wrap img {
  width: auto;
}
.panoil {
  color: #fff;
  background: #000;
}
.panoil h2:hover {
  cursor: pointer;
  color: #3b4b9b;
}
.panoil .tags-item a,
.panoil .tags-item span {
  box-shadow: 0 2px 0 0px #3b4b9b;
}
.panoil .portfolio-page-about,
.panoil .portfolio-page-table td {
  color: #fff;
}
.panoil .portfolio-page-link {
  background-color: #3b4b9b;
}
.panoil .portfolio-case-link {
  color: #3b4b9b;
  stroke: #3b4b9b;
}
.panoil .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
.panoil .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .panoil {
    padding-right: 20px;
  }
}
.azbuka {
  color: #332c2c;
  background: #fef8ee;
}
.azbuka h2:hover {
  cursor: pointer;
  color: #da8339;
}
.azbuka .tags-item a,
.azbuka .tags-item span {
  box-shadow: 0 2px 0 0px #da8339;
}
.azbuka .portfolio-page-about,
.azbuka .portfolio-page-table td {
  color: #464646;
}
.azbuka .portfolio-page-link {
  background-color: #da8339;
}
.azbuka .portfolio-case-link {
  color: #da8339;
  stroke: #da8339;
}
.azbuka .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}
.azbuka .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .azbuka {
    padding-right: 340px;
    background: url("../img/azbuka/bg.png") top center/cover no-repeat;
  }
  .azbuka .portfolio-image-wrap {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
  .azbuka .portfolio-image-wrap img {
    width: auto;
  }
}
.spectr {
  color: #fff;
  background: linear-gradient(180deg, #c25665 0%, #9058a8 100%);
}
.spectr h2:hover {
  cursor: pointer;
  color: #66429e;
}
.spectr .tags-item a,
.spectr .tags-item span {
  box-shadow: 0 2px 0 0px #66429e;
}
.spectr .portfolio-page-about,
.spectr .portfolio-page-table td {
  color: #fff;
}
.spectr .portfolio-page-link {
  background-color: #66429e;
}
.spectr .portfolio-case-link {
  color: #66429e;
  stroke: #66429e;
}
.spectr .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.spectr .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .spectr {
    padding-right: 70px;
    background: linear-gradient(90deg, #c45663 0%, #8f58a9 97.97%);
  }
}
.interior {
  color: #fff;
  background: #fff url("../img/interior/bg-m.png") top center/cover no-repeat;
}
.interior h2:hover {
  cursor: pointer;
  color: #da4c24;
}
.interior .tags-item a,
.interior .tags-item span {
  box-shadow: 0 2px 0 0px #da4c24;
}
.interior .portfolio-page-about,
.interior .portfolio-page-table td {
  color: #fff;
}
.interior .portfolio-page-link {
  background-color: #da4c24;
}
.interior .portfolio-case-link {
  color: #da4c24;
  stroke: #da4c24;
}
.interior .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.interior .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .interior {
    padding-right: 75px;
    background: url("../img/interior/bg.png") top center/cover no-repeat;
  }
}
.webdush {
  color: #332c2c;
  background: #eef7ff url("../img/webdush/bg-m.png") top center/cover no-repeat;
}
.webdush h2:hover {
  cursor: pointer;
  color: #fe7132;
}
.webdush .tags-item a,
.webdush .tags-item span {
  box-shadow: 0 2px 0 0px #fe7132;
}
.webdush .portfolio-page-about,
.webdush .portfolio-page-table td {
  color: #464646;
}
.webdush .portfolio-page-link {
  background-color: #fe7132;
}
.webdush .portfolio-case-link {
  color: #fe7132;
  stroke: #fe7132;
}
.webdush .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.webdush .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .webdush {
    padding-right: 75px;
    background: #eef7ff url("../img/webdush/bg.png") top center/cover no-repeat;
  }
}
.motodor {
  color: #fff;
  background: url("../img/motodor/bg-m.png") top center/cover no-repeat, linear-gradient(180deg, #6c6c6c -10.69%, rgba(103,103,103,0) 162.47%);
}
.motodor h2:hover {
  cursor: pointer;
  color: #ecc765;
}
.motodor .tags-item a,
.motodor .tags-item span {
  box-shadow: 0 2px 0 0px #ecc765;
}
.motodor .portfolio-page-about,
.motodor .portfolio-page-table td {
  color: #fff;
}
.motodor .portfolio-page-link {
  background-color: #ecc765;
}
.motodor .portfolio-case-link {
  color: #ecc765;
  stroke: #ecc765;
}
.motodor .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.motodor .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .motodor {
    padding-right: 75px;
    background: url("../img/motodor/bg.png") top center/cover no-repeat, linear-gradient(180deg, #33302e 0%, #555451 51.07%, #464645 94.79%);
  }
}
.physio {
  color: #332c2c;
  background: #fff url("../img/physio/bg-m.png") top center/cover no-repeat;
}
.physio h2:hover {
  cursor: pointer;
  color: #497fb4;
}
.physio .tags-item a,
.physio .tags-item span {
  box-shadow: 0 2px 0 0px #497fb4;
}
.physio .portfolio-page-about,
.physio .portfolio-page-table td {
  color: #464646;
}
.physio .portfolio-page-link {
  background-color: #497fb4;
}
.physio .portfolio-case-link {
  color: #497fb4;
  stroke: #497fb4;
}
.physio .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.physio .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .physio {
    padding-right: 75px;
    background: url("../img/physio/bg.png") top center/cover no-repeat;
  }
}
.roig {
  color: #fff;
  background: #08215a url("../img/roig/bg-m.png") top center/cover no-repeat;
}
.roig h2:hover {
  cursor: pointer;
  color: #b90d00;
}
.roig .tags-item a,
.roig .tags-item span {
  box-shadow: 0 2px 0 0px #b90d00;
}
.roig .portfolio-page-about,
.roig .portfolio-page-table td {
  color: #fff;
}
.roig .portfolio-page-link {
  background-color: #b90d00;
}
.roig .portfolio-case-link {
  color: #b90d00;
  stroke: #b90d00;
}
.roig .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 100px;
}
.roig .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .roig {
    padding-right: 75px;
    background: #08215a url("../img/roig/bg.png") top center/cover no-repeat;
  }
}
.zauber {
  color: #332c2c;
  background: #f6dc79 url("../img/zauber/bg-m.png") top center/cover no-repeat;
}
.zauber h2:hover {
  cursor: pointer;
  color: #e89947;
}
.zauber .tags-item a,
.zauber .tags-item span {
  box-shadow: 0 2px 0 0px #e89947;
}
.zauber .portfolio-page-about,
.zauber .portfolio-page-table td {
  color: #464646;
}
.zauber .portfolio-page-link {
  background-color: #e89947;
}
.zauber .portfolio-case-link {
  color: #e89947;
  stroke: #e89947;
}
.zauber .portfolio-image-wrap {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 24px;
}
.zauber .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .zauber {
    padding-right: 75px;
    background: #fff url("../img/zauber/bg.png") top center/cover no-repeat;
  }
  .zauber .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.domdacha {
  color: #332c2c;
  background: #fdf9f3;
}
.domdacha h2:hover {
  cursor: pointer;
  color: #e2ba85;
}
.domdacha .tags-item a,
.domdacha .tags-item span {
  box-shadow: 0 2px 0 0px #e2ba85;
}
.domdacha .portfolio-page-about,
.domdacha .portfolio-page-table td {
  color: #464646;
}
.domdacha .portfolio-page-link {
  background-color: #e2ba85;
}
.domdacha .portfolio-case-link {
  color: #e2ba85;
  stroke: #e2ba85;
}
.domdacha .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.domdacha .portfolio-image-wrap picture {
  width: 100%;
}
.domdacha .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .domdacha {
    color: #fff;
    padding-left: 0;
    padding-right: 75px;
    background: url("../img/domdacha/bg.png") top center/cover no-repeat;
  }
  .domdacha .portfolio-content-wrap {
    padding-left: 20px;
  }
  .domdacha .portfolio-page-about,
  .domdacha .portfolio-page-table td {
    color: #fff;
  }
  .domdacha .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .domdacha .portfolio-image-wrap picture {
    width: auto;
  }
}
@media (min-width: 1023px) {
  .domdacha .portfolio-content-wrap {
    background: rgba(0,0,0,0.2);
  }
}
@media (min-width: 1050px) {
  .domdacha {
    padding-left: 0;
  }
  .domdacha .portfolio-content-wrap {
    padding-left: 60px;
  }
}
@media (min-width: 1300px) {
  .domdacha {
    padding-left: 0;
  }
  .domdacha .portfolio-content-wrap {
    padding-left: 100px;
  }
}
@media (min-width: 1800px) {
  .domdacha {
    padding-left: 0;
  }
  .domdacha .portfolio-content-wrap {
    padding-left: 145px;
  }
}
.apteka {
  color: #332c2c;
  background: linear-gradient(180deg, #e9e9e9 -4.75%, #fefefe 60.18%);
}
.apteka h2:hover {
  cursor: pointer;
  color: #ac0202;
}
.apteka .tags-item a,
.apteka .tags-item span {
  box-shadow: 0 2px 0 0px #ac0202;
}
.apteka .portfolio-page-about,
.apteka .portfolio-page-table td {
  color: #464646;
}
.apteka .portfolio-page-link {
  background-color: #ac0202;
}
.apteka .portfolio-case-link {
  color: #ac0202;
  stroke: #ac0202;
}
.apteka .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.apteka .portfolio-image-wrap picture {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.apteka .portfolio-image-wrap img {
  width: 100%;
}
@media (min-width: 550px) {
  .apteka {
    padding-right: 75px;
    background: linear-gradient(119.84deg, #f5f5f5 36.89%, #eee 83.01%);
  }
}
.skmera {
  color: #332c2c;
  background: #fff url("../img/skmera/bg-m.png") top left/100% auto no-repeat;
}
.skmera h2:hover {
  cursor: pointer;
  color: #005294;
}
.skmera .tags-item a,
.skmera .tags-item span {
  box-shadow: 0 2px 0 0px #005294;
}
.skmera .portfolio-page-about,
.skmera .portfolio-page-table td {
  color: #464646;
}
.skmera .portfolio-page-link {
  background-color: #005294;
}
.skmera .portfolio-case-link {
  color: #005294;
  stroke: #005294;
}
.skmera .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.skmera .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .skmera {
    padding-right: 75px;
    background: #fff url("../img/skmera/bg.png") top left/cover no-repeat;
  }
  .skmera .portfolio-image-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
  }
}
.pnsk {
  color: #fff;
  background: #55575c url("../img/pnsk/bg-m.png") top left/contain no-repeat;
}
.pnsk h2:hover {
  cursor: pointer;
  color: #58c5fa;
}
.pnsk .tags-item a,
.pnsk .tags-item span {
  box-shadow: 0 2px 0 0px #58c5fa;
}
.pnsk .portfolio-page-about,
.pnsk .portfolio-page-table td {
  color: #fff;
}
.pnsk .portfolio-page-link {
  background-color: #58c5fa;
}
.pnsk .portfolio-case-link {
  color: #58c5fa;
  stroke: #58c5fa;
}
.pnsk .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.pnsk .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .pnsk {
    padding-right: 75px;
    background: #55575c url("../img/pnsk/bg.png") top center/cover no-repeat;
  }
}
.ksmoto {
  color: #332c2c;
  background: #f0f0f0 url("../img/ksmoto/bg-m.png") top center/contain no-repeat;
}
.ksmoto h2:hover {
  cursor: pointer;
  color: #fecc08;
}
.ksmoto .tags-item a,
.ksmoto .tags-item span {
  box-shadow: 0 2px 0 0px #fecc08;
}
.ksmoto .portfolio-page-about,
.ksmoto .portfolio-page-table td {
  color: #464646;
}
.ksmoto .portfolio-page-link {
  background-color: #fecc08;
  color: #332c2c;
}
.ksmoto .portfolio-case-link {
  color: #332c2c;
  stroke: #332c2c;
}
.ksmoto .portfolio-image-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.ksmoto .portfolio-image-wrap img {
  width: auto;
}
@media (min-width: 550px) {
  .ksmoto {
    padding-right: 75px;
    background: url("../img/ksmoto/bg.png") top center/cover no-repeat;
  }
}
.slogan {
  position: relative;
  padding-top: 0;
  padding-bottom: 195px;
  margin-top: 15px;
  overflow: hidden;
  min-height: calc(100vh - 58px);
}
.slogan-name {
  color: #fe0000;
  font-size: 18px;
  line-height: 27px;
  font-weight: 800;
}
.slogan-title {
  max-width: 260px;
  margin: 20px 0 50px;
  color: #332c2c;
  font-size: 42px;
  line-height: 1.02;
  font-weight: 800;
  letter-spacing: 0.01em;
}
.slogan-link {
  color: #332c2c;
  font-size: 14px;
  line-height: 21px;
  font-weight: 800;
  text-decoration: none;
}
.slogan-link:hover {
  color: #fe0000;
}
.slogan-image-wrap {
  position: absolute;
  bottom: 320px;
  right: 0;
  z-index: 1;
}
.slogan-image-wrap img {
  vertical-align: bottom;
}
.slogan-text {
  position: relative;
  z-index: 2;
}
@media (min-width: 550px) {
  .slogan {
    margin-top: 0;
    padding-bottom: 40px;
  }
  .slogan-title {
    max-width: 260px;
    font-size: 32px;
  }
  .slogan-image-wrap {
    right: 0;
    bottom: 3px;
  }
}
@media (min-width: 750px) {
  .slogan {
    overflow: visible;
    min-height: initial;
  }
  .slogan-title {
    max-width: 600px;
  }
}
@media (min-width: 800px) {
  .slogan-image-wrap {
    z-index: 3;
  }
}
@media (min-width: 1150px) {
  .slogan-title {
    font-size: 53px;
    line-height: 1.02;
    font-weight: 800;
    max-width: 100%;
  }
  .slogan-link-container {
    position: absolute;
    top: 25px;
    right: 320px;
    z-index: 99;
  }
  .slogan-text {
    margin-top: -30px;
    z-index: 4;
  }
  .slogan-image-wrap {
    right: -60px;
    bottom: -34px;
    z-index: 3;
  }
  .slogan-link {
    display: block;
    transform-origin: 50% 150px;
    animation: circle 2s linear infinite;
  }
  .slogan-link span {
    animation: inner-circle 2s linear infinite;
    transform-origin: 50% 153px;
    display: block;
  }
}
@media (min-width: 1650px) {
  .slogan-link-container {
    top: 70%;
    right: 505px;
  }
  .slogan-link {
    font-size: 24px;
  }
  .slogan-image-wrap {
    right: 0;
    bottom: -50px;
  }
}
@media (min-height: 750px) {
  .slogan-text {
    margin-top: 30px;
  }
}
@-moz-keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes inner-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes inner-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-o-keyframes inner-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes inner-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.pros {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 46px;
  z-index: 4;
  background-color: #fff;
}
.pros-item {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 16px;
}
.pros-item:nth-of-type(n+2) {
  margin-top: 46px;
}
.pros-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 34px;
  height: 3px;
  background: #fe0000;
}
.pros-name {
  font-size: 14px;
  line-height: 21px;
  font-weight: 800;
}
.pros-value {
  color: #fe0000;
  font-size: 53px;
  line-height: 1;
  font-weight: 800;
}
.pros-projects {
  display: inline-block;
  width: 82px;
}
@media (min-width: 550px) {
  .pros {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 30px;
    padding-bottom: 0;
    box-shadow: inset 0 -3px 0 #d4d4d4;
  }
  .pros-item:nth-of-type(n+2) {
    margin-top: 0;
  }
}
@media (min-width: 800px) {
  .pros {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    background: none;
  }
  .pros-item:not(:last-child) {
    margin-right: 50px;
  }
}
@media (min-width: 1050px) {
  .pros-item:not(:last-child) {
    margin-right: 120px;
  }
}
@media (min-width: 1800px) {
  .pros-item:not(:last-child) {
    margin-right: 285px;
  }
}
.popup-is-active > .menu {
  display: block;
  overflow-x: hidden;
}
.menu {
  display: none;
  width: 100%;
  max-height: 100%;
  padding: 80px 32px 0;
  color: #d4d4d4;
  overflow: auto;
}
.menu-title {
  margin: 0 0 47px;
  font-size: 42px;
  line-height: 43px;
  font-weight: 800;
}
.menu-wrap {
  margin-bottom: 70px;
}
.menu-item {
  padding: 11px 0;
  font-size: 24px;
  line-height: 25px;
  font-weight: 400;
}
.menu-item a {
  color: #d4d4d4;
  text-decoration: none;
}
.menu-item a:hover {
  text-decoration: underline;
}
.menu-item .bold-item {
  font-weight: 700;
}
.header-contacts {
  padding-bottom: 80px;
  color: #332c2c;
}
.header-contacts a {
  color: #332c2c;
}
.menu-callback-form {
  position: relative;
  color: #332c2c;
  width: 100vw;
  padding-top: 80px;
  padding-left: 32px;
  padding: 80px 32px 100px;
  max-width: 820px;
  margin: 90px 0 0;
  margin-left: -32px;
  margin-bottom: 20px;
  background-color: #d4d4d4;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-callback-form form .site-input {
  width: 80%;
}
.menu-callback-form form .callback-form-label {
  display: inline-block;
  color: #332c2c;
  width: 100%;
  margin-bottom: 38px;
  font-size: 24px;
  line-height: 35px;
  font-weight: 800;
}
@media (min-width: 550px) {
  .menu {
    padding-top: 50px;
  }
  .menu-wrap {
    justify-content: space-evenly;
    column-gap: 50px;
  }
}
@media (min-width: 1050px) {
  .menu {
    padding-left: 60px;
  }
  .popup-is-active .menu {
    padding-top: 0;
    order: 3;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .menu-callback-form {
    width: auto;
    position: absolute;
    padding: 80px 45px;
    padding-top: 110px;
    height: calc(100% + 20px);
    top: -100px;
    right: 0;
    overflow-y: auto;
  }
}
@media (min-width: 1300px) {
  .menu-wrap {
    padding-right: 820px;
  }
  .menu-callback-form {
    display: flex;
  }
}
