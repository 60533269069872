.cozythings
  color $white
  background: radial-gradient(70.58% 203.41% at 68.54% 42.83%, #C6C2C0 7.33%, #9C8F8A 28.52%, #695E5B 68.05%)
  h2:hover
    cursor pointer
    color #B5968C
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #B5968C
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #B5968C
  .portfolio-case-link
    color: #B5968C
    stroke: #B5968C
  .portfolio-image-wrap
    position relative
    align-items flex-end
    padding-top 50px
    img
      display block
      width auto
  .portfolio-image-decor
    position absolute
    left -33%
    bottom 0
    width 630px
@media (min-width 960px)
  .cozythings
    background: radial-gradient(70.58% 203.41% at 68.54% 42.83%, #C6C2C0 7.33%, #9C8F8A 28.52%, #695E5B 68.05%)
