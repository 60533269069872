.detail-katris
  color $black
  background $white
  height initial
  padding-bottom 160px
  padding-right 0
  .detail-image-wrap
    padding 0
    padding-bottom 50px
  .detail-page-wrap
    grid-template-columns repeat(auto-fit, minmax(450px, auto))
    grid-gap 0px
    padding-top 0px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #92B7C9
  .detail-page-about,
  .detail-page-table td
    max-width 650px
    color #5A5A5A
  .detail-page-link
    background-color #E9CF92
    color #141414
    transition all .2s ease-out
    &:hover
      background-color: #92B7C9
  .detail-styles-container
    flex(row, wrap, space-between, center)
    width 100%
    max-width 500px
    padding-right 80px
    padding-top 35px
  .detail-styles-row
    flex(row, nowrap, space-between)
  .detail-styles-colors
    flex(row, nowrap, space-between)
    width 100%
    max-width: 385px
    padding-bottom 40px
    fonts(14px, 21px)
  .styles-colors-item
    flex(column, nowrap, center, center)
    color #A4A4A4
    fonts(14px, 16px, normal)
  .katris-color
    width 75px
    height 75px
    margin-bottom 33px
    transition: .3s all ease-out
  .katris-blue
    background-color: #92b7c9
    box-shadow 9px -9px 0 0 $white,
               9px -9px 0 1px rgba(#92b7c9, 0.7)
    &:hover
      box-shadow 15px -15px 0 0 $white,
                 15px -15px 0 1px rgba(#92b7c9, 0.7)
  .katris-yellow
    background-color: #e9cf92
    box-shadow 9px -9px 0 0 $white,
               9px -9px 0 1px rgba(#e9cf92, 0.7)
    &:hover
      box-shadow 15px -15px 0 0 $white,
                 15px -15px 0 1px rgba(#e9cf92, 0.7)
  .katris-gray
    background-color: #999999
    box-shadow 9px -9px 0 0 $white,
               9px -9px 0 1px rgba(#999999, 0.7)
    &:hover
      box-shadow 15px -15px 0 0 $white,
                 15px -15px 0 1px rgba(#999999, 0.7)
  .detail-ui-kit
    // padding-top 50px
    width 100%
  .ui-kit-row
    flex(row, nowrap, flex-start, center)
    width 100%
    margin-bottom 30px
  .ui-kit-col
    margin-right 5px
  .detail-ui-font
    display block
    font-family 'Open Sans', sans-serif
  .detail-font-example
    position relative
    z-index: 1
    display block
    fonts(136px, 1.1, bold)
    color #E9CF92
    margin-right 40px
    &::before
      content ''
      position absolute
      z-index -1
      top 19px
      left 10px
      width 168px
      height 100px
      background-color $white
      background url('../img/katris-font-Aa.png') 0 0 no-repeat
  .detail-font-name
    fonts(14px, 19px, normal)
    color #23527C
    letter-spacing: 0.05em
  .pagination
    flex(row, nowrap, space-between, center)
    margin 0
    margin-top 70px
    padding 0
    list-style none
  .pagination-item
    cursor pointer
    flex(row, nowrap, center, center)
    width 29px
    height 29px
    margin 0 4px
    color $white
    font-size 14px
    border-radius 50%
    background-color #999999
  .pagination-item.active
    background-color #92B7C9
  .site-button
    flex(row, nowrap, center, center)
    cursor pointer
    position relative
    z-index: 1
    width 170px
    height 35px
    margin-right 60px
    padding 12px 0 11px 0
    text-align center
    color $white
    font-family 'Open Sans', sans-serif
    fonts(9px, 12px, 600)
    letter-spacing -0.08em
    text-decoration none
    text-transform uppercase
    background: #E9CF92;
    &::before
      content: ''
      position: absolute
      top: 50%
      left: 14px
      tranform translateY(-50%)
      display: block
      width: 142px
      height: 1px
      margin-top: -1px
      background: $white
      z-index: 1
      box-sizing content-box
    span
      padding 0 7px
      position relative
      z-index: 3
      background #E9CF92
  .site-button-hover
    border: 1px solid #4D90FE
  .site-link
    color #678C9E
    fonts(18px, 25px)
  .site-link-hover
    color #23527C
@media (max-width 1400px)
  .detail-katris
    padding-left 60px
    padding-right 0
    background $white url('../img/katris-big.jpg') no-repeat 0 0\/100% auto
    .detail-image-wrap
      padding-right 60px
    .detail-page-wrap
      grid-gap 50px
    .detail-ui-kit
      padding-left 20px
    .detail-ui-buttons
      min-width 100px
