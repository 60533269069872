.tourskazka
  color #332C2C
  background url('../img/tourskazka-bg-big.png') 100% 0\/auto 100% no-repeat
  h2:hover
    cursor pointer
    color #8fc029
  .tags-item
    a, span
      color #332C2C
      text-decoration none
      box-shadow 0 2px 0 0px #8fc029
  .portfolio-page-about,
  .portfolio-page-table td
    color #332C2C
  .portfolio-page-link
    background-color #8fc029
  .portfolio-case-link
    color: #8fc029
    stroke: #8fc029
@media (min-width 550px)
  .tourskazka
    color #332C2C
    linear-gradient(135deg, #2C2D37 41.61%, #E37816 103.67%)
    .portfolio-image-wrap
      margin-left 40px
      padding 0
@media (max-width: 550px)
  .tourskazka
    .portfolio-image-wrap
      position relative
      align-items center
      height 100%
