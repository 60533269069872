.detail-chiphunt
  padding-bottom 150px
  color $white
  background: #444444
  .detail-image-wrap
    padding-top 80px
    img
      width auto
  .detail-page-wrap
    padding-top 50px
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #B04C2A
  .detail-page-about,
  .detail-page-table td
    color $white
  .detail-page-link
    background-color #B04C2A
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#B04C2A, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
