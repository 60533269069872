.detail-tosno
  padding-bottom 150px
  color #332C2C
  background $white
  height initial
  .detail-image-wrap
    position relative
    text-align center
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #EA392F
  .detail-page-about,
  .detail-page-table td
    color #494949
  .detail-page-link
    background-color #EA392F
    color $white
    transition all .2s ease-out
    &:hover
      background-color: #dA291F
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
@media (max-width 1400px)
  .detail-tosno
    padding-left 60px
    padding-right 60px
