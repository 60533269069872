.medel
  color $white
  background #93A7AA
  h2:hover
    cursor pointer
    color #1C766E
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #1C766E
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #1C766E
  .portfolio-case-link
    color: #1C766E
    stroke: #1C766E
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    picture
      width 100%
    img
      width 100%
@media (min-width: 550px)
  .medel
    background linear-gradient(242.85deg, #A5B5B3 -32.28%, #8BA1A6 92.89%);
    padding-right 0
    .portfolio-image-wrap
      position relative
      height 100%
      flex(row, nowrap, flex-end, flex-end)
