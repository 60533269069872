.symphony
  color $black
  background linear-gradient(180deg, #EBEBEB 2.44%, #F0F0F0 100%);
  h2:hover
    cursor pointer
    color #0D1D3B
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #0D1D3B
  .portfolio-page-about,
  .portfolio-page-table td
    color #464646
  .portfolio-page-link
    background-color #0D1D3B
  .portfolio-case-link
    color: #0D1D3B
    stroke: #0D1D3B
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
      mix-blend-mode: darken
@media (min-width: 550px)
  .symphony
    background $white url('../img/symphony/bg.png') top center\/cover no-repeat
    .portfolio-image-wrap
      img
        display none
