.bureau
  color $white
  background url('../img/bureau-bg-small.jpg') no-repeat 0 0\/cover
  h2:hover
    cursor pointer
    color #2C5AFF
  .tags-item
    a, span
      color inherit
      text-decoration none
      box-shadow 0 2px 0 0px #2C5AFF
  .portfolio-page-about
    max-width 750px
  .portfolio-page-table
    max-width 700px
  .portfolio-page-about,
  .portfolio-page-table td
    color #B8B8B8
  .portfolio-page-link
    background-color #2C5AFF
  .portfolio-case-link
    color: #2C5AFF
    stroke: #2C5AFF
  .portfolio-image-wrap
    justify-content center
@media (min-width 550px)
  .bureau
    background url('../img/bureau-bg.png') no-repeat 0 0\/auto 100%
