.pnsk
  color $white
  background #55575C url('../img/pnsk/bg-m.png') top left\/contain no-repeat
  h2:hover
    cursor pointer
    color #58C5FA
  .tags-item
    a, span
      box-shadow 0 2px 0 0px #58C5FA
  .portfolio-page-about,
  .portfolio-page-table td
    color $white
  .portfolio-page-link
    background-color #58C5FA
  .portfolio-case-link
    color: #58C5FA
    stroke: #58C5FA
  .portfolio-image-wrap
    position relative
    height 100%
    flex(row, nowrap, center, center)
    img
      width auto
@media (min-width: 550px)
  .pnsk
    padding-right 75px
    background #55575C url('../img/pnsk/bg.png') top center\/cover no-repeat
