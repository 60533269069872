.detail-linero
  padding-bottom 150px
  color $white
  background #011D31
  .detail-image-wrap
    flex(row, nowrap, flex-end, stretch)
    padding-bottom 50px
    img
      width auto
  .tags-item
    a, span
      box-shadow 0 2px 0 0 #E63222
  .detail-page-about,
  .detail-page-table td
    color $white
  .detail-page-link
    background-color #E63222
    color $white
    transition all .2s ease-out
    &:hover
      background-color: rgba(#E63222, 0.8)
  .detail-styles-container
    width 100%
    max-width 700px
    padding-top 20px
  .detail-row
    flex()
